import { Box, Stack } from '@mui/material';
import * as React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { useSelector, useDispatch } from 'react-redux'
import { heroActions, heroObj } from '../../store/reducers/HeroSlice';
import { EffectFade, Controller } from 'swiper/modules';

export default function MobileHero() {

    const dispatch = useDispatch();
    const heroData = useSelector(state => state.heroData);
    const [swp, setSwp] = React.useState(null);
    const [swp2, setSwp2] = React.useState(null);

    const [swpWidth, setSwpWidth] = React.useState(window.innerWidth * 0.85);

    function heroTap(i) {
        dispatch(heroActions.selectHeroIndex(i));

        swp.slideTo(i-2);
        swp2.slideTo(i);
    }

    function leftTap() {
        swp2.slidePrev();
    }

    function rightTap() {
        swp2.slideNext();
    }

    return (
        <Stack sx={{
            position: 'relative',
            height: window.innerHeight,
        }}>
            {/* 背景 */}
            <Box component={'img'}
                src={window.CDNHost+'/img/mobile/m_bg4.jpg'}
                sx={{
                    position: 'absolute',
                    width: 1,
                    height: 1,
                    objectFit: 'cover',
                }} />
            {/* 三角背景 */}
            <Box
                key={`${heroData.name}_m_bg`}
                component={'img'}
                src={window.CDNHost+`/img/mobile/mp/${heroData.name}_bg.png`}
                sx={{
                    position: 'absolute',
                    objectFit: 'cover',
                    width: 1,
                    overflow: 'hidden',
                }} />

            {/* 英雄 */}
            <Swiper
                id='swp2'
                style={{
                    position: 'absolute',
                    width: window.innerWidth,
                    height: window.innerHeight,
                }}

                onSwiper={(swiper) => {
                    setSwp2(swiper);
                    swiper.activeIndex = heroData.index - 2;
                }}
                onSlideChange={(s) => {
                    heroTap(swp2.activeIndex)
                }}
                effect={'fade'}
                modules={[EffectFade]}
                fadeEffect={{
                    crossFade: true,
                }}
                slidesPerView={1}>

                {heroData.heros.map((value, i) => {
                    return (
                        <SwiperSlide style={{
                        }} key={i}>
                            <HeroFull value={value} index={i} activeIndex={heroData.index} />
                        </SwiperSlide>
                    );
                })}
            </Swiper>


            {/* 1. Swiper Banner */}
            <Swiper
                id='swp1'
                style={{
                    position: 'absolute',
                    bottom: '50px',
                    width: swpWidth,
                    height: swpWidth * 0.22,
                    left: window.innerWidth * 0.075,
                    maxHeight: '120px',
                    transform: 'skew(-29deg)',
                }}

                onSwiper={(swiper) => {
                    setSwp(swiper);
                    swiper.activeIndex = heroData.index - 2;
                }}
                spaceBetween={7}
                slidesPerView={5}>

                {heroData.heros.map((value, i) => {
                    return (
                        <SwiperSlide key={i}>
                            <HeroItem value={value} index={i} activeIndex={heroData.index} heroTap={heroTap} />
                        </SwiperSlide>
                    );
                })}
            </Swiper>

             {/* 铭牌 */}
             <Box
                key={`${heroData.name}_mp`}
                component={'img'}
                src={window.CDNHost+`/img/hero_mp/${heroData.name}_icon.png`}
                sx={{
                    zIndex: 1001,
                    position: 'absolute',
                    height: '70px',
                    right: '-5px',
                    bottom: '150px',
                }} />

            {/* 左右按钮 */}
            <Box component={'img'}
                onClick={leftTap}
                src={window.CDNHost+"/img/left_bt.png"}
                sx={{
                    zIndex: 1000,
                    position: 'absolute',
                    width: '20px',
                    bottom: '50%',
                    left: '10px',
                }} />

            <Box component={'img'}
                onClick={rightTap}
                src={window.CDNHost+"/img/right_bt.png"}
                sx={{
                    zIndex: 1000,
                    position: 'absolute',
                    width: '20px',
                    bottom: '50%',
                    right: '10px',
                }} />
        </Stack>
    );
}

function HeroFull(props) {
    return (
        <Stack
            sx={{
                width: 1,
                height: 1,
                overflow: 'hidden',
            }}>

            <Box component={'img'}
                src={window.CDNHost+`/img/mobile/full/${props.value}_full.png`}
                sx={{
                    width: '200%',
                    left: '-50%',
                    top: '-300px',
                    position: 'absolute',
                    objectFit: 'cover',
                }} />
        </Stack>
    );
}

function HeroItem(props) {
    return (
        <Stack
            onClick={() => props.heroTap(props.index)}
            sx={{
                width: 1,
                height: 1,
                overflow: 'visible',
            }}>
            <Box component={'img'}
                src={window.CDNHost+`/img/mobile/small/${props.value}_small.png`}
                sx={{
                    pointerEvents: 'none',
                    height: 1,
                    left: '-55%',
                    WebkitFilter: props.index == props.activeIndex ? 'brightness(100%)' : 'brightness(50%)',
                    filter: props.index == props.activeIndex ? 'brightness(100%)' : 'brightness(50%)',
                    transform: 'skew(29deg)',
                    position: 'absolute',
                    overflow: 'visible',
                    objectFit: 'cover',
                }} />
        </Stack>
    );
}