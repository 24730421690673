import { Stack, Box, Typography } from '@mui/material';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation, useSearchParams } from 'react-router-dom';
import * as StringTool from '../../js/StringTool';
import { TWSid_CR, TWRid_CR } from '../../js/StringTool';
import { settingActions, settingObj } from '../../store/reducers/SettingSlice';
import RoleAlertCR from './RoleAlertCR';
import BindTwitchLoginAlertCR from './BindTwitchLoginAlertCR';
import TextView from './TextView';
import RuleAlert from './RuleAlert';


export default function Page1CR(props) {

    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);
    const [searchParams, setSearchParams] = useSearchParams();
    const [showRule, setShowRule] = React.useState(false);

    React.useEffect(() => {
        //1.获取code
        let code = null;
        let state = searchParams.get('state');
        if (localStorage.getItem('_tw_state') == state && state) {
            code = searchParams.get('code');
            window.updateTwitchState();
        }
        dispatch(settingActions.setTwitchCode(code));

        //3. 初始化User
        setTimeout(() => {
            startInit();
        }, 500);

    }, []);

    function startInit() {
        window.initCrunchyrollGame((data) => {
            showLoading(false);
            if (data.state) {
                dispatch(settingActions.setOpmData(data));
                dispatch(settingActions.showTwitchLogin(false)); //关闭登录框

                //服务列表
                window.requestRoleList(false, data.websdk.uid, data.websdk.token, (sData) => {
                    if (sData.success) {
                        dispatch(settingActions.setServiceData(sData));
                        props.checkRoleAlert(true);//检查角色选择

                        //检查绑定user
                        checkTwitchData();

                    } else {
                        console.log('service list is empty')
                    }
                });
            }
        }, (errorMsg) => {
            showLoading(false);
            if (errorMsg) {
                showToast(errorMsg);
            }
        });
    }

    //检查结果
    function checkTwitchData() {
        const s = StringTool.getLocalString(StringTool.TWData_CR);
        if (!StringTool.isEmpty(s)) {
            const d = JSON.parse(s);
            if (d && d.code == 0) {
                dispatch(settingActions.setTwitchDataCR(d));
            }
        }
    }

    //登录游戏弹框
    function loginOPMTap() {
        dispatch(settingActions.showTwitchLogin(true));
    }

    //twitch登录
    function loginTwitchTap() {
        //先登录游戏
        if (!settingData.opmData) {
            dispatch(settingActions.showToast('Please login OPMW Account'));
            return;
        }

        //再选角色
        if (StringTool.isEmpty(settingData.roleId)) {
            dispatch(settingActions.showToast('Please Select Character'));
            return;
        }

        //再登录twitch
        window.openTwitchCR();
    }

    function logoutAll() {
        window.logoutGameCR();

        StringTool.removeLocalString(TWSid_CR);
        StringTool.removeLocalString(TWRid_CR);

        dispatch(settingActions.setServiceData(null));
        dispatch(settingActions.setOpmData(null)); //game user
        dispatch(settingActions.setServiceId(null));
        dispatch(settingActions.setRoleId(null));

        dispatch(settingActions.setTwitchDataCR(null));
        dispatch(settingActions.setTwitchCode(null));

        if (window.location.href.indexOf('thirdAuthToken') != -1) {
            window.location.href = window.location.origin + window.location.pathname;
        }
    }

    function changeRoleTap() {
        dispatch(settingActions.showRoleAlert(true));
    }

    //绑定或者去主页
    function bindTap() {
        if (settingObj.twitchData) { //去主页
            window.open('https://www.twitch.tv/directory/category/one-punch-man-world');

        } else { //绑定
            showLoading(true);
            window.bindRole(
                false,
                settingObj.opmData.websdk.uid,
                settingObj.opmData.websdk.token,
                settingObj.roleId,
                settingObj.serviceId,
                settingObj.twitchCode, (bdata) => {

                    if (bdata.code == 0 || bdata.code == -24015) { //成功，或者 此角色已绑定其他用户
                        //登录twitch请求
                        window.loginTwitch(
                            false,
                            settingObj.opmData.websdk.token,
                            settingObj.twitchCode,
                            (tdata) => {
                                showLoading(false);
                                if (tdata.code == 0) {
                                    if (tdata.result.twitchUser) { //有绑定用户信息
                                        dispatch(settingActions.setTwitchDataCR(tdata));
                                        dispatch(settingActions.setTwitchCode(null));

                                    } else { //已绑定其他用户，所以获取不到用户信息
                                        showToast(getMsg(bdata.code));
                                    }
                                } else {
                                    showToast(getMsg(tdata.code));
                                }
                            });
                    } else {
                        showLoading(false);
                        showToast(getMsg(bdata.code));
                    }
                })
        }
    }

    function getMsg(code) {
        if (code == -24004) {
            return 'The role information is incomplete';

        } else if (code == -24005) {
            return 'Role verification failure';

        } else if (code == -24015) {
            return 'This role has been bound to another twitch';

        } else if (code == -24002) {
            return 'The event does not support this game';
        }

        return 'Bind Fail';
    }

    function showLoading(show) {
        dispatch(settingActions.showLoading(show));
    }

    function showToast(msg) {
        dispatch(settingActions.showToast(msg));
    }

    function getRoleName(rid) {
        if (!StringTool.isEmpty(rid) && settingObj.serviceData) {
            for (const md of settingObj.serviceData.roleList) {
                if (md.roleId == rid) {
                    return md.roleName;
                }
            }
        }
        return 'Select Character'
    }

    function ruleTaped() {
        setShowRule(true);
    }

    function closeTap() {
        setShowRule(false);
    }

    return (
        <Stack
            sx={{
                width: 1,
                height: 1,
                overflow: 'hidden',
            }}>
            <Box
                component={'img'}
                src={window.CDNHost + '/img/bg2.png'}
                sx={{
                    position: 'absolute',
                    width: 1,
                    height: 1,
                }} />

            {/* 1. PC 版本 */}
            <Stack sx={{
                width: 1,
                height: 1,
                position: 'absolute',
                zIndex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                display: props.isMobile ? 'none' : 'flex'
            }}>

                <TextView title='PLEASE LOG IN TO YOUR ACCOUNT' />

                <Stack sx={{
                    position: 'relative',
                }}>
                    <Box component={'img'} src={window.CDNHost + '/img/alert/bg.png'} sx={{
                        mr: 2,
                    }} />

                    {/* 容器 */}
                    <Stack sx={{
                        width: 1,
                        height: 1,
                        position: 'absolute',
                        color: 'white',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                    }}>

                        {/* 左边 */}
                        <Stack sx={{
                            height: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                            mr: 10,
                        }}>

                            <Typography sx={{
                                fontFamily: 'us',
                                fontSize: 30,
                                mt: -3,
                            }}>
                                1. OPMW Account
                            </Typography>

                            {/* 登录信息 */}
                            <Stack sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                mt: 5,

                            }}>
                                <Box
                                    component={'img'}
                                    src={window.CDNHost + '/img/twitch/opm_icon.jpg'}
                                    sx={{
                                        width: '80px',
                                        height: '80px',
                                        borderRadius: '40px',
                                        border: 2,
                                        borderColor: '#679FC6'
                                    }} />
                                <Stack>

                                    {/* 用户信息 */}
                                    <Stack sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        ml: 2,
                                    }}>
                                        <Typography>
                                            {settingData.opmData && settingData.opmData.websdk.username}
                                        </Typography>
                                    </Stack>

                                    {/* 角色信息 */}
                                    <Stack sx={{
                                        display: settingData.opmData ? 'flex' : 'none',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        ml: 2,
                                        mt: 2,
                                    }}>

                                        <Typography
                                            onClick={changeRoleTap}
                                            sx={{
                                                borderBottom: 1,
                                                cursor: 'pointer',
                                                display: settingData.roleId || settingData.opmData ? 'flex' : 'none',
                                            }}>
                                            {getRoleName(settingData.roleId)}
                                        </Typography>

                                    </Stack>

                                </Stack>

                            </Stack>


                            <Box
                                onClick={loginOPMTap}
                                component={'span'}
                                sx={{
                                    visibility: settingData.opmData ? 'hidden' : 'flex',
                                    cursor: 'pointer',
                                    background: `url(${window.CDNHost}/img/twitch/blue_bt.png) no-repeat`,
                                    backgroundSize: '100% 100%',
                                    color: 'white',
                                    height: '44px',
                                    lineHeight: '44px',
                                    fontSize: 18,
                                    justifyContent: 'center',
                                    minWidth: '138px',
                                    borderRadius: 1.5,
                                    px: 2,
                                    mt: 6,
                                    textAlign: 'center',
                                }}>
                                Log In
                            </Box>

                        </Stack>

                        {/* 右边 */}
                        <Stack sx={{
                            height: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>

                            <Typography sx={{
                                fontFamily: 'us',
                                fontSize: 30,
                                mt: -3,
                            }}>
                                2. Twitch Account
                            </Typography>


                            {/* Twitch信息 */}
                            <Stack sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                mt: 5,
                            }}>
                                <Box
                                    component={'img'}
                                    src={window.CDNHost + '/img/twitch/twitch.png'}
                                    sx={{
                                        width: '80px',
                                        height: '80px',
                                        borderRadius: '40px',
                                        border: 2,
                                        borderColor: '#679FC6'
                                    }} />

                                <Stack>
                                    <Stack sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        ml: 2,
                                    }}>

                                        <Typography>
                                            {settingData.twitchData && settingData.twitchData.result.twitchUser.twitchName}
                                        </Typography>

                                    </Stack>

                                </Stack>

                            </Stack>

                            <Box
                                onClick={loginTwitchTap}
                                component={'span'}
                                sx={{
                                    visibility: (!StringTool.isEmpty(settingData.twitchCode) || settingData.twitchData) ? 'hidden' : 'flex',
                                    cursor: 'pointer',
                                    background: `url(${window.CDNHost}/img/twitch/purple_bt.png) no-repeat`,
                                    backgroundSize: '100% 100%',
                                    color: 'white',
                                    height: '44px',
                                    lineHeight: '44px',
                                    fontSize: 18,
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    minWidth: '138px',
                                    borderRadius: 1.5,
                                    px: 2,
                                    mt: 6,
                                }}>
                                Log In
                            </Box>
                        </Stack>

                        <Typography
                            onClick={bindTap}
                            sx={{
                                display: ((!StringTool.isEmpty(settingData.roleId) && !StringTool.isEmpty(settingData.twitchCode)) || settingData.twitchData) ? 'block' : 'none',
                                position: 'absolute',
                                bottom: '130px',
                                cursor: 'pointer',
                                background: `url(${window.CDNHost}/img/twitch/blue_bt.png) no-repeat`,
                                backgroundSize: '100% 100%',
                                color: 'white',
                                height: '50px',
                                lineHeight: '50px',
                                fontSize: 16,
                                fontWeight: 'bold',
                                borderRadius: 1.5,
                                width: '280px',
                                textAlign: 'center',
                            }}>
                            {settingData.twitchData ? 'Open Twitch Page' : 'Bind Twitch Account'}
                        </Typography>

                        <Typography
                            onClick={logoutAll}
                            sx={{
                                display: settingData.opmData ? 'flex' : 'none',
                                position: 'absolute',
                                left: '140px',
                                bottom: '110px',
                                ml: 2,
                                borderBottom: 1,
                                cursor: 'pointer'
                            }}>
                            Log Out All
                        </Typography>

                        <Stack sx={{
                            position: 'absolute',
                            bottom: '80px',
                            color: 'white',
                            fontSize: 14,
                            textAlign: 'center',
                            fontFamily: 'us',
                            flexDirection: 'row'
                        }}>

                            <Typography sx={{
                                color: 'white',
                                fontSize: 14,
                                textAlign: 'center',
                                fontFamily: 'us',

                            }}>
                                Tips: Please log in and create a character in the game before you can bind to Twitch.
                            </Typography>

                            <Typography
                                onClick={ruleTaped}
                                sx={{
                                    color: 'white',
                                    fontSize: 14,
                                    textAlign: 'center',
                                    fontFamily: 'us',
                                    ml: 2,
                                    borderBottom: 1,
                                    cursor: 'pointer',
                                }}>
                                View Rules
                            </Typography>

                        </Stack>



                    </Stack>

                </Stack>

            </Stack>


            {/* 2. 手机版 */}
            <Stack sx={{
                width: 1,
                height: 1,
                position: 'absolute',
                zIndex: 1,
                flexDirection: 'column',
                alignItems: 'center',
                display: !props.isMobile ? 'none' : 'flex',
                color: 'white',
            }}>

                <Typography sx={{
                    fontFamily: 'us',
                    fontSize: 23,
                    mt: 9,
                    mb: 2,
                }}>
                    1. OPMW Account
                </Typography>

                <Stack sx={{
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>

                    <Box
                        component={'img'}
                        src={window.CDNHost + '/img/twitch/opm_icon.jpg'}
                        sx={{
                            width: '75px',
                            height: '75px',
                            borderRadius: '40px',
                            border: 2,
                            borderColor: '#679FC6'
                        }} />

                    <Stack sx={{
                        ml: 2,
                    }}>

                        <Typography sx={{
                            maxWidth: '160px',
                            display: 'inline-block',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}>
                            {settingData.opmData && settingData.opmData.websdk.username}
                        </Typography>

                        <Typography
                            onClick={changeRoleTap}
                            sx={{
                                borderBottom: 1,
                                mt: 1,
                                cursor: 'pointer',
                                display: settingData.roleId || settingData.opmData ? 'flex' : 'none',
                            }}>
                            {getRoleName(settingData.roleId)}
                        </Typography>

                    </Stack>

                </Stack>

                <Box
                    onClick={loginOPMTap}
                    component={'span'}
                    sx={{
                        display: settingData.opmData ? 'none' : 'block',
                        cursor: 'pointer',
                        background: `url(${window.CDNHost}/img/twitch/blue_bt.png) no-repeat`,
                        backgroundSize: '100% 100%',
                        color: 'white',
                        height: '40px',
                        lineHeight: '40px',
                        fontSize: 15,
                        minWidth: '100px',
                        borderRadius: 1.5,
                        px: 2,
                        mt: 3,
                        textAlign: 'center',
                    }}>
                    Log In
                </Box>

                <Typography sx={{
                    fontFamily: 'us',
                    fontSize: 23,
                    mt: 8,
                    mb: 2,
                }}>
                    2. Twithc Account
                </Typography>

                <Stack sx={{
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>

                    <Box
                        component={'img'}
                        src={window.CDNHost + '/img/twitch/twitch.png'}
                        sx={{
                            width: '75px',
                            height: '75px',
                            borderRadius: '40px',
                            border: 2,
                            borderColor: '#679FC6'
                        }} />

                    <Typography sx={{
                        ml: 2,
                    }}>
                        {settingData.twitchData && settingData.twitchData.result.twitchUser.twitchName}
                    </Typography>

                </Stack>

                <Box
                    onClick={loginTwitchTap}
                    component={'span'}
                    sx={{
                        display: (!StringTool.isEmpty(settingData.twitchCode) || settingData.twitchData) ? 'none' : 'block',
                        cursor: 'pointer',
                        background: `url(${window.CDNHost}/img/twitch/purple_bt.png) no-repeat`,
                        backgroundSize: '100% 100%',
                        color: 'white',
                        height: '40px',
                        lineHeight: '40px',
                        fontSize: 18,
                        alignItems: 'center',
                        textAlign: 'center',
                        minWidth: '100px',
                        borderRadius: 1.5,
                        px: 2,
                        mt: 3,
                    }}>
                    Log In
                </Box>

                <Typography
                    onClick={bindTap}
                    sx={{
                        display: ((!StringTool.isEmpty(settingData.roleId) && !StringTool.isEmpty(settingData.twitchCode)) || settingData.twitchData) ? 'block' : 'none',
                        cursor: 'pointer',
                        mt: 5,
                        background: `url(${window.CDNHost}/img/twitch/blue_bt.png) no-repeat`,
                        backgroundSize: '100% 100%',
                        color: 'white',
                        height: '45px',
                        lineHeight: '45px',
                        fontSize: 15,
                        fontWeight: 'bold',
                        borderRadius: 1.5,
                        width: props.isMobile ? '220px' : '280px',
                        textAlign: 'center',
                    }}>
                    {settingData.twitchData ? 'Open Twitch Page' : 'Bind Twitch Account'}
                </Typography>


                <Box sx={{ flex: 1 }} />

                <Typography
                    onClick={logoutAll}
                    sx={{
                        display: settingData.opmData ? 'flex' : 'none',
                        borderBottom: 1,
                        cursor: 'pointer',
                        fontWeight: 'bold',
                    }}>
                    Log Out All
                </Typography>

                <Typography
                    onClick={ruleTaped}
                    sx={{
                        color: 'white',
                        fontSize: 14,
                        textAlign: 'center',
                        fontFamily: 'us',
                        borderBottom: 1,
                        cursor: 'pointer',
                        mb: 1,
                        alignSelf: 'flex-end',
                        mr: 3,
                    }}>
                    View Rules
                </Typography>


                <Typography
                    sx={{
                        color: 'white',
                        fontSize: 14,
                        textAlign: 'center',
                        fontFamily: 'us',
                        width: 0.9,
                        mb: 2,
                    }}>
                    Tips: Please log in and create a character in the game before you can bind to Twitch.
                </Typography>

            </Stack>


            <BindTwitchLoginAlertCR isMobile={props.isMobile} />
            <RoleAlertCR isMobile={props.isMobile} />
            <RuleAlert showRule={showRule} closeTap={closeTap} />

        </Stack>
    );
}

