export const isProduction = process.env.NODE_ENV === 'production';

export function isEmpty(str) {
    if (str === null || str === undefined || !str || str == "" || 'undefined' == str || 'null' == str || str.trim().length == 0) {
        return true;
    }
    return false;
}

export function isEmail(str) {
    if (isEmpty(str) || str.length < 1 || str.length > 255) {
        return false;
    }
    return str.match('[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}');
}

export function isPassword(str) {
    if (isEmpty(str)) {
        return false;
    }
    return str.match('^(?![0-9]+$)(?![a-zA-Z]+$)(?![_]+$)[0-9A-Za-z_]{8,30}$');
}

export function isVerifyCode(str) {
    if (isEmpty(str)) {
        return false;
    }
    return str.match('[0-9]{6}');
}

export function print(str) {
    if (typeof window !== 'undefined' && !isProduction) {
        console.log(`-----APJ Start------\n\n${str}\n\n-----APJ End------`);
    }
}

export function saveLocalString(key, value) {
    if (typeof window !== 'undefined') {
        if(value == null){
            localStorage.removeItem(key);
        }else{
            localStorage.setItem(key, value);
        }
        
    }
}

export function saveSessionString(key, value) {
    if (typeof window !== 'undefined') {
        sessionStorage.setItem(key, value);
    }
}

export function getLocalString(key) {
    if (typeof window !== 'undefined') {
        const str = localStorage.getItem(key);
        if (isEmpty(str)) {
            return "";
        }
        return str;
    } else {
        return "";
    }
}

export function getSessionString(key) {
    if (typeof window !== 'undefined') {
        const str = sessionStorage.getItem(key);
        if (isEmpty(str)) {
            return "";
        }
        return str;
    } else {
        return "";
    }
}

export function removeLocalString(key) {
    if (typeof window !== 'undefined') {
        localStorage.removeItem(key);
    }
}

export function clearLocalString() {
    if (typeof window !== 'undefined') {
        localStorage.clear();
    }
}

export function getBrowserLanguage() {
    if (typeof window !== 'undefined') {
        let str = navigator.language.toLowerCase + '';
        if (str.startsWith('de-')) {
            return 'DE';

        } else if (str.startsWith('fr-')) {
            return 'FR';

        } else if (str.startsWith('pt-')) {
            return 'PT';

        } else if (str.startsWith('es-')) {
            return 'ES';
        }
    }
    return "EN";
}

let os = function () {
    let ua = navigator.userAgent,
        isWindowsPhone = /(?:Windows Phone)/.test(ua),
        isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
        isAndroid = /(?:Android)/.test(ua),
        isFireFox = /(?:Firefox)/.test(ua),
        isChrome = /(?:Chrome|CriOS)/.test(ua),
        isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua)),
        isPhone = /(?:iPhone)/.test(ua) && !isTablet,
        isPc = !isPhone && !isAndroid && !isSymbian;
    return {
        isTablet: isTablet,
        isPhone: isPhone,
        isAndroid: isAndroid,
        isPc: isPc
    };
}();

export let isMobile = function isMobile() {
    if (window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
        return true;
    }
    return false;
}();

export let isAndroid = function () {
    if (os.isAndroid || (navigator.userAgent.toLowerCase().indexOf("android") !== -1)) {
        return true;
    }
    return false;
}();

export let isIOS = function () {
    let str = navigator.userAgent.toLowerCase();
    if (os.isPhone || (str.indexOf("iphone") !== -1) || str.indexOf("ipad") !== -1) {
        return true;
    }
    return false;
}();

export function uuid() {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < 32) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}

export function getPercent() {
    const dt = new Date();
    const dtStr = `${dt.getFullYear()}-${String(dt.getMonth()+1).padStart(2,'0')}-${String(dt.getDate()).padStart(2,'0')}`

    let p = 0.07;
    if (dtStr >= '2023-11-02') {
        p = 0.143;
    } if (dtStr >= '2023-11-09') {
        p = 0.286;
    } if (dtStr >= '2023-11-17') {
        p = 0.428;
    } if (dtStr >= '2023-12-02') {
        p = 0.571;
    } if (dtStr >= '2023-12-27') {
        p = 0.714;
    } if (dtStr >= '2024-01-10') {
        p = 0.857;
    } if (dtStr >= '2024-01-26') {
        p = 1;
    }
    
    return p;
}

//------keys------
export const LangKey = "_apj_lang_key";
export const CookieKey = "_apj_cookie_key";

//twitch
export const TWData = "_apj_tw_data";
export const TWSid = "_apj_tw_sid";
export const TWRid = "_apj_tw_rid";
export const TWData_CR = "_apj_tw_data_cr";
export const TWSid_CR = "_apj_tw_sid_cr";
export const TWRid_CR = "_apj_tw_rid_cr";

export const StoreSid = "product_store_sid";
export const StoreRid = "product_store_rid";
export const StoreSid_CR = "product_store_sid_cr";
export const StoreRid_CR = "product_store_rid_cr";



