import { Box, Stack, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import * as React from 'react';
import "swiper/css";
import { settingActions } from '../store/reducers/SettingSlice';
import * as APJRequest from '../js/APJRequest';


export default function VideoFooter() {
    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);

    function alertTap() {
        dispatch(settingActions.showSignDalog());
    }

    return (
        <Stack direction={'row'}
            sx={{
                zIndex: 21,
                position: 'absolute',
                bottom: 0,
                mb: 3,
                height: { xs: '25px', sm: '35px', md: '45px' },
                animation: 'bottomToUp 1s',
            }}>

            <Typography
                onClick={alertTap}
                sx={{
                    cursor: 'pointer',
                    fontSize: 12,
                    fontWeight: 'bold',
                    color: '#F3F3F3',
                    border: 0.2,
                    px: 1,
                    textAlign: 'center',
                    borderRadius: 1,
                    borderColor: '#A1A1A0',
                    minWidth: '130px',
                    height: '96%',
                    display: 'none',
                    justifyContent: 'center',
                    alignItems: 'center',
                    transitionDuration: '0.3s',
                    bgcolor: '#000000cc',
                    '&:hover': {
                        transform: 'scale(1.15)',
                    }
                }}>
                {settingData.langDic['lang6']}
            </Typography>


            <Box component={'img'} src={window.CDNHost+'/img/btn_down1_release.png'}
                onClick={() => { APJRequest.openApple() }}
                sx={{
                    border: 0.2,
                    borderColor: '#A1A1A0',
                    ml: 2.5,
                    borderRadius: 1,
                    transitionDuration: '0.3s',
                    cursor: 'pointer',
                    '&:hover': {
                        transform: 'scale(1.15)',
                    }
                }} />
            <Box component={'img'} src={window.CDNHost+'/img/btn_down2_release.png'}
                onClick={() => { APJRequest.openGoogle() }}
                sx={{
                    border: 0.2,
                    borderColor: '#A1A1A0',
                    ml: 2.5,
                    borderRadius: 1,
                    transitionDuration: '0.3s',
                    cursor: 'pointer',
                    '&:hover': {
                        transform: 'scale(1.15)',
                    }
                }} />
            <Box component={'img'} src={window.CDNHost+'/img/btn_down3_release.png'}
             onClick={() => { APJRequest.openPcDownload() }}
                sx={{
                    border: 0.2,
                    borderColor: '#A1A1A0',
                    ml: 2.5,
                    borderRadius: 1,
                    bgcolor: '#000000cc',
                    transitionDuration: '0.3s',
                    cursor: 'pointer',
                    '&:hover': {
                        transform: 'scale(1.15)',
                    }
                }} />

        </Stack>);

}