import { Stack, Box, Typography } from '@mui/material';
import * as React from 'react';
import InfoFooter from '../InfoFooter';
import TextView from './TextView';
import * as rdd from 'react-device-detect';
import MobileFooter from '../../components/mobile/MobileFooter';

export default function Page2(props) {

    const [selectIndex, setSelectIndex] = React.useState(-1);

    return (
        <Stack sx={{
            width: 1,
            height: 1,
            overflow: 'hidden',
        }}>
            <Box
                component={'img'}
                src={window.CDNHost + '/img/bg4.jpg'}
                sx={{
                    position: 'absolute',
                    width: 1,
                    height: 1,
                    objectFit: 'cover',
                }} />

            <Stack sx={{
                width: 1,
                height: 1,
                position: 'absolute',
                zIndex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: 'white',

            }}>
                <Box sx={{
                    height: props.isMobile ? '20px' : `${window.innerHeight * 0.1}px`,
                    maxHeight: '200px',
                }} />

                <TextView title='Event FAQ' isMobile={props.isMobile} />

                <Stack sx={{
                    width: props.isMobile ? 0.9 : '1100px',
                    height: 1,
                    overflow: 'auto',
                }}>
                    <SectionView index={0} selectIndex={selectIndex} tap={setSelectIndex} title='1. What are Twitch Drops?' detail='Twitch Drops are a unique in-game reward that can be obtained by watching One Punch Man: World live streams on Twitch.' />
                    <SectionView index={1} selectIndex={selectIndex} tap={setSelectIndex} title='2. How can I participate in the Twitch Drops event?' detail='"To participate in the Twitch Drops event, log in to your One Punch Man: World account and link it to your Twitch account, then head over to Twitch to watch One Punch Man: World live streams. Activate Twitch Drops by following the account binding process outlined at the top of the event page. If you don‘t have a One Punch Man: World account yet, you can download the game for free from Google Play or the App Store or visit the official website to download on PC."' />
                    <SectionView index={2} selectIndex={selectIndex} tap={setSelectIndex} title='3. How do I know I have successfully participated in the Twitch Drops event?' detail='"When you visit the channel for a Twitch Drop event that you have already participated in, a pop-up notification for ongoing Twitch Drop live streams will appear at the top of the chat window.
You can also check ongoing events and channels you have already visited in the Twitch Inventory."'/>
                    <SectionView index={3} selectIndex={selectIndex} tap={setSelectIndex} title='4.How do I claim my rewards?' detail='After watching a live stream for the required amount of time, you will see a pop-up notification in the Twitch Chat prompting you to claim your rewards, or you can view your rewards directly in the Drops & Rewards Inventory.' />
                    <SectionView index={4} selectIndex={selectIndex} tap={setSelectIndex} title='5. How long does it take to receive Twitch Drop rewards?' detail='Event rewards are typically sent to your in-game mailbox within 48 hours. We kindly ask Heroes to claim their rewards promptly after unlocking the in-game mailbox feature before they expire.' />
                    <SectionView index={5} selectIndex={selectIndex} tap={setSelectIndex} title='6. Is it possible to receive rewards multiple times if I have multiple game accounts?' detail='Each Twitch account can only be associated with a single in-game character account, and rewards can only be claimed once.' />
                </Stack>

                <Box sx={{ height: props.isMobile ? '170px' : 150 }} />

                {!props.isMobile && <InfoFooter isCR={props.isCR} />}
                {props.isMobile && <MobileFooter isCR={props.isCR} />}

            </Stack>
        </Stack>
    );
}

function SectionView(props) {

    function isMobile() {
        if (rdd.isMobile || window.innerWidth < 700) {
            return true;
        }
        return false;
    }

    return (
        <Stack
            onClick={() => {
                if (props.index == props.selectIndex) {
                    props.tap(-1);
                } else {
                    props.tap(props.index);
                }
            }}
            sx={{
                my: 2,
                cursor: 'pointer'
            }}>
            <Typography sx={{
                color: '#ffffff99',
                fontSize: isMobile() ? 16 : 26,

            }}>
                {props.title}
            </Typography>

            <Typography sx={{
                display: props.index == props.selectIndex ? 'flex' : 'none',
                color: '#ffffff66',
                minWidth: '80px',
                px: isMobile() ? 0 : 3,
                fontSize: isMobile() ? 14 : 18,
            }}>
                {props.detail}
            </Typography>

        </Stack>

    );
}
