import * as React from 'react';
import * as StringTool from '../js/StringTool';
import { Box, Stack, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';


export default function InputItem(props) {

    const dispatch = useDispatch();
    const [icon, setIcon] = React.useState('');
    const [inputValue, setInputValue] = React.useState('');
    const [msg, setMsg] = React.useState('');
    const [showPwd, setShowPwd] = React.useState(false);
    const settingData = useSelector(state => state.settingData);

    React.useEffect(() => {
        if (props.type == 'password') {
            setIcon('/img/pwd-hide.png');
        }
    }, []);

    function onIconClick() {
        if (props.type == 'email') {
            setMsg('');
            setIcon('');
            setInputValue('');
            props.onChange && props.onChange('');

        } else if (props.type == 'password') {
            if (showPwd) {
                setIcon('/img/pwd-hide.png');
                setShowPwd(false);
            } else {
                setIcon('/img/pwd-show.png');
                setShowPwd(true);
            }

        } 
    }

    function onFocus() {
        setMsg('');
        if (props.type == 'email') {
            setIcon('');

        } else if (props.type == 'password') {

        } 
    }

    function onBlur() {
        if (props.type == 'email') {
            if (StringTool.isEmpty(inputValue)) {
                setMsg(settingData.langDic['lang57']);
                setIcon('/img/close-red.png');

            } else if (!StringTool.isEmail(inputValue)) {
                setMsg(settingData.langDic['lang59']);
                setIcon('/img/close-red.png');
            }
        } else if (props.type == 'password') {
            if (StringTool.isEmpty(inputValue)) {
                setMsg(props.placeholder);
            } 
        } 
    }

    function onChange(e) {
        setInputValue(e.target.value);
        props.onChange && props.onChange(e.target.value);
    }

    return (
        <Stack sx={{
            width: '85%',
        }}>

            <Stack
                sx={{
                    position: 'relative',
                    display: 'flex',
                    width: 1,
                    mt: 1,
                    height: '45px',
                    border: 1,
                    borderColor: StringTool.isEmpty(msg) ? '#E7E8EE' : '#FF5E42',
                    bgcolor: StringTool.isEmpty(msg) ? '#FAFAFC' : '#FAFAFC',
                    borderRadius: '8px',
                }}>

                <Stack
                    component={'input'}
                    type={props.type == 'password' ? (showPwd ? 'text' : 'password') : 'email'}
                    value={inputValue}
                    placeholder={props.placeholder}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    onChange={onChange}
                    sx={{
                        maxLength: 4,
                        flexGrow: 1,
                        border: 0,
                        bgcolor: 'transparent',
                        px: 2,
                        outline: 'none',
                        color: 'black',
                        '&::placeholder': {
                            color: "#999999"
                        },
                    }} />

                {icon &&
                    <Box
                        component={'img'}
                        onClick={onIconClick}
                        src={window.CDNHost + icon}
                        sx={{
                            cursor: 'pointer',
                            position: 'absolute',
                            height: '17px',
                            width: '17px',
                            top: '14px',
                            right: '17px',
                        }} />
                }
            </Stack>
            <Typography
                sx={{ color: '#FC4F4E', mt: 0.5 }}
                component="span"
                fontSize={12}>
                {msg}
            </Typography>

        </Stack>
    );
}