import { Box, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { heroActions, obj } from '../store/reducers/HeroSlice';
import { Swiper, SwiperSlide } from "swiper/react";
import { settingActions } from '../store/reducers/SettingSlice';
import "swiper/css";
import * as APJRequest from '../js/APJRequest';

export default function InfoFooter(props) {
    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);

    function privacyTap() {
        if (props.isCR) {
            window.open('https://www.sonypictures.com/corp/privacy.html');
        } else {
            APJRequest.openPrivacy();
        }
    }

    function termTap() {
        if (props.isCR) {
            window.open('https://www.crunchyroll.com/games/terms/');
        } else {
            APJRequest.openTerm();
        }
    }

    function alertTap() {
        dispatch(settingActions.showSignDalog());
    }

    function serviceTap() {//客服表单
        if (props.isCR) {
            window.open('https://crgames.zendesk.com/hc/en-us');
        } else {
            APJRequest.openFAQ();
        }
    }

    return (
        <Stack sx={{
            zIndex: 100,
            display: 'flex',
            flexDirection: 'row',
            width: 1,
            height: { xs: '50px', sm: '60px', md: '70px', lg: '70px', xl: '80px' },
            bgcolor: '#000000cc',
            position: 'absolute',
            bottom: 0,
            alignItems: 'center',
            overflow: 'hidden',
            animation: 'bottomToUp 1s',
        }}>
            {/* 左边icon */}
            <Box component={'img'} src={window.CDNHost + '/img/wanmei.png'}
                sx={{
                    height: '35%',
                    ml: 2,
                }} />
            <Box component={'img'} src={window.CDNHost + '/img/flogo1.png'}
                sx={{
                    height: '33%',
                    ml: 2,
                }} />

            <Box component={'img'}
                src={window.CDNHost + '/img/flogo2.png'}
                sx={{
                    height: '38%',
                    ml: -1,
                }} />

            <Box component={'img'} src={window.CDNHost + '/img/flogo3.png'}
                sx={{
                    height: '34%',
                    ml: 2,
                }} />

            <Box component={'img'} src={window.CDNHost + '/img/teen12.png'}
                sx={{
                    height: '50%',
                }} />

            {/* 中间文字 */}
            <Stack sx={{
                color: '#CACFD199',
                minWidth: '330px',
                mr: -1,
            }}>
                <Stack direction={'row'} >
                    <Typography
                        onClick={privacyTap}
                        sx={{
                            cursor: 'pointer',
                            fontSize: 14,
                            pl: '5px',
                            transitionDuration: '0.3s',
                            '&:hover': {
                                color: 'white',
                                transform: 'scale(1.03)',
                            }
                        }}>Privacy Policy</Typography>

                    <Typography
                        sx={{
                            fontSize: 14,
                            ml: '5px',
                            mr: '5px',
                        }}> / </Typography>

                    <Typography
                        onClick={termTap}
                        sx={{
                            cursor: 'pointer',
                            fontSize: 14,
                            transitionDuration: '0.3s',
                            '&:hover': {
                                color: 'white',
                                transform: 'scale(1.03)',
                            }
                        }}>Terms of services</Typography>

                    <Typography
                        sx={{
                            fontSize: 14,
                            ml: '5px',
                            mr: '5px',
                        }}> / </Typography>

                    <Typography
                        onClick={serviceTap}
                        sx={{
                            cursor: 'pointer',
                            fontSize: 14,
                            transitionDuration: '0.3s',
                            '&:hover': {
                                color: 'white',
                                transform: 'scale(1.03)',
                            }
                        }}>ClientService</Typography>
                </Stack>
                <Typography sx={{
                    fontSize: 11,
                    ml: '5px',
                }}>©ONE, Yusuke Murata/SHUEISHA, Hero Association HQ</Typography>
            </Stack>

            {/* 右边icon */}
            <Typography
                onClick={alertTap}
                sx={{
                    cursor: 'pointer',
                    fontSize: 12,
                    ml: 2,
                    color: '#F3F3F3',
                    border: 0.2,
                    px: 1,
                    textAlign: 'center',
                    borderRadius: 1,
                    borderColor: '#A1A1A0',
                    minWidth: '130px',
                    height: '50%',
                    display: 'none',
                    justifyContent: 'center',
                    bgcolor: '#000000cc',
                    alignItems: 'center',
                    transitionDuration: '0.3s',
                    '&:hover': {
                        transform: 'scale(1.15)',
                    }
                }}>
                {settingData.langDic['lang6']}
            </Typography>
            <Box component={'img'} src={window.CDNHost + '/img/btn_down1_release.png'}
                onClick={() => {
                    if (props.isCR) {
                        window.open('https://apps.apple.com/us/app/one-punch-man-world/id6448791649');
                    } else {
                        APJRequest.openApple(props.adPlatform);
                    }
                }}
                sx={{
                    border: 0.2,
                    borderColor: '#A1A1A0',
                    height: '50%',
                    ml: 2,
                    borderRadius: 1,
                    transitionDuration: '0.3s',
                    cursor: 'pointer',
                    '&:hover': {
                        transform: 'scale(1.15)',
                    }
                }} />

            <Box component={'img'} src={window.CDNHost + '/img/btn_down2_release.png'}
                onClick={() => {
                    if (props.isCR) {
                        window.open('https://play.google.com/store/apps/details?id=com.crunchyroll.opm&pli=1');
                    } else {
                        APJRequest.openGoogle(props.adPlatform)
                    }
                }}
                sx={{
                    border: 0.2,
                    borderColor: '#A1A1A0',
                    height: '50%',
                    ml: 2,
                    borderRadius: 1,
                    transitionDuration: '0.3s',
                    cursor: 'pointer',
                    '&:hover': {
                        transform: 'scale(1.15)',
                    }
                }} />

            <Box component={'img'} src={window.CDNHost + '/img/btn_down3_release.png'}
                onClick={() => {
                    if (props.isCR) { 
                        window.open('https://yqcreubmcdn1.wmupd.com/clientRes/InstallPackage/OPMW_Installer.exe');

                    } else {
                        APJRequest.openPcDownload(props.adPlatform)
                    }
                }}
                sx={{
                    border: 0.2,
                    borderColor: '#A1A1A0',
                    height: '50%',
                    ml: 2,
                    borderRadius: 1,
                    transitionDuration: '0.3s',
                    cursor:  'pointer',
                    '&:hover': {
                        transform:'scale(1.15)',
                    }
                }} />

        </Stack>
    );


}