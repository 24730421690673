import { Box, Stack, Typography } from '@mui/material';
import * as React from 'react';

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/bundle";

import { Pagination } from 'swiper/modules';
import * as APJRequest from '../js/APJRequest';


export default function GameLauncher_US() {

    const [swp, setSwp] = React.useState(null);
    const [showNav, setShowNav] = React.useState(false);
    const [swpWidth, setSwpWidth] = React.useState(window.innerWidth * 0.277);
    const [newsWidth, setNewsWidth] = React.useState(window.innerWidth * 0.342);
    const [newsIndex, setNewsIndex] = React.useState(0);
    const [marginBt, setMarginBt] = React.useState(window.innerHeight * 0.094);

    const videoUrls = ["https://youtu.be/Q7AYNjhkyw0?si=kJiytipiZX4xC0ns", "https://youtu.be/Z1PBIrzmn0k?si=7O9aJ8se0j25Qkvs"];
    const videoImages = [window.CDNHost+"/img/launcher/img0.png", window.CDNHost+"/img/launcher/img1.png"];

    React.useEffect(() => {
        window.addEventListener('resize', sizeChanged);
        return () => {
            window.removeEventListener('resize', sizeChanged);
        }
    }, []);

    function sizeChanged() {
        setMarginBt(window.innerHeight * 0.094);

        let a = window.innerWidth * 0.277;
        if (a < 400) {
            a = 400;
        }
        setSwpWidth(a);

        let b = window.innerWidth * 0.342;
        if (b < 400) {
            b = 400;
        }
        if (b > 600) {
            b = 600;
        }
        setNewsWidth(b);
    }

    function imageTap(i) {
        window.open(videoUrls[i]);
    }

    function shareTap(i) {
        if (i == -1) { //web
            window.open('https://www.onepunchmanworld.com');

        } if (i == 0) { //disk
            window.open('https://discord.gg/one-punch-man-world');

        } else if (i == 1) {//tiwtter
            window.open('https://twitter.com/onepunchman_w');

        } else if (i == 2) { //fb
            window.open('https://www.facebook.com/onepunchmanworld');

        }
    }

    function newsTabTap(i) {
        setNewsIndex(i);
    }

    return (
        <Stack sx={{
            width: '100vw',
            height: '100vh',
        }}>
            <Stack sx={{
                zIndex: 2,
                width: 1,
                height: 1,
                position: 'absolute',
            }}>

                {/* 导航 */}
                <Stack
                    className='header'
                    sx={{
                        width: 1,
                        minHeight: '48px',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        backgroundSize: '100% 100%',
                        backdropFilter: 'blur(3px)',
                        background: 'linear-gradient(#00000099, #00000000)',
                        backgroundImage: `url(${window.CDNHost}/img/launcher/jianbian.plng) no-repeat`
                    }}>

                    <Box component={'img'}
                        onClick={(e) => shareTap(-1)}
                        src={window.CDNHost+'/img/www.png'}
                        sx={{
                            cursor: 'pointer',
                            width: '28px',
                            ml: 3,
                            '&:hover': {
                                filter: 'invert(14%) sepia(100%) saturate(1116%) hue-rotate(336deg) brightness(95%) contrast(96%)',

                            }
                        }} />

                    <Box component={'img'}
                        onClick={(e) => shareTap(0)}
                        src={window.CDNHost+'/img/pt1.png'}
                        sx={{
                            cursor: 'pointer',
                            width: '26px',
                            ml: 5,
                            '&:hover': {
                                filter: 'invert(14%) sepia(100%) saturate(1116%) hue-rotate(336deg) brightness(95%) contrast(96%)',
                            }
                        }} />

                    <Box component={'img'}
                        onClick={(e) => shareTap(1)}
                        src={window.CDNHost+'/img/pt2.png'}
                        sx={{
                            cursor: 'pointer',
                            width: '30px',
                            ml: 4.5,
                            '&:hover': {
                                filter: 'invert(14%) sepia(100%) saturate(1116%) hue-rotate(336deg) brightness(95%) contrast(96%)',
                            }
                        }} />

                    <Box component={'img'}
                        onClick={(e) => shareTap(2)}
                        src={window.CDNHost+'/img/pt3.png'}
                        sx={{
                            cursor: 'pointer',
                            width: '30px',
                            ml: 4.5,
                            '&:hover': {
                                filter: 'invert(14%) sepia(100%) saturate(1116%) hue-rotate(336deg) brightness(95%) contrast(96%)',
                            }
                        }} />

                </Stack>

                {/* logo */}
                <Box component={'img'}
                    src={window.CDNHost+'/img/logo.png'}
                    sx={{
                        width: '190px',
                        mr: 8,
                        mt: 1,
                        alignSelf: 'flex-end',
                    }} />

                <Box sx={{ flex: 1 }} />

                {/* 底部 */}
                <Stack sx={{
                    mb: `${marginBt}px`,
                    ml: 3.3,
                    width: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'start',
                }}>
                    {/* 图片 */}
                    <Stack
                        onMouseOver={() => { setShowNav(true) }}
                        onMouseLeave={() => { setShowNav(false) }}
                        sx={{
                            width: swpWidth,
                            height: swpWidth * 0.44,
                            position: 'relative',
                        }}>
                        <Swiper
                            className="mySwiper"
                            pagination={showNav}
                            resistanceRatio={0}
                            modules={[Pagination]}
                            style={{
                                width: '100%',
                                height: '100%',
                            }}
                            onSwiper={(sp) => {
                                setSwp(sp);
                            }}>
                            {
                                videoImages.map((value, i) => {
                                    return <SwiperSlide key={i}
                                        style={{
                                            width: "100%",
                                            height: '100%',
                                            display: 'flex',
                                        }}>
                                        <ImgItem value={value} index={i} imageTap={imageTap} />
                                    </SwiperSlide>
                                })
                            }
                        </Swiper>

                        {/* 左边 */}
                        <Stack
                            onClick={() => { swp.slidePrev() }}
                            sx={{
                                display: showNav ? 'flex' : 'none',
                                cursor: 'pointer',
                                width: '25px',
                                height: '100%',
                                bgcolor: '#00000066',
                                zIndex: '99',
                                position: 'absolute',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: 'white',
                            }}>
                            <Box component={'img'} src={window.CDNHost+'/img/left.png'} sx={{
                                width: 0.45,
                            }} />
                        </Stack>

                        {/* 右边 */}
                        <Stack
                            onClick={() => { swp.slideNext() }}
                            sx={{
                                display: showNav ? 'flex' : 'none',
                                cursor: 'pointer',
                                width: '25px',
                                height: '100%',
                                bgcolor: '#00000066',
                                zIndex: '99',
                                right: 0,
                                position: 'absolute',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: 'white',
                            }}>
                            <Box component={'img'} src={window.CDNHost+'/img/right.png'} sx={{
                                width: 0.45,
                            }} />
                        </Stack>
                    </Stack>

                    {/* 链接 */}
                    <Stack
                        className='newsCont'
                        sx={{
                            width: newsWidth,
                            ml: 1.5,
                            pt: 1,
                            backgroundSize: '100% 100%',
                            backdropFilter: 'blur(6px)',
                            background: '#00000066',
                            backgroundImage: `url(${window.CDNHost}/img/launcher/jianbian.plng) no-repeat`
                        }}>
                        {newsIndex <= 1 &&
                            <Stack sx={{ flex: 1 }}>
                                <NewsItem index={1} title='One Punch Man: World Playable Demo Comes To Gamescom' time='2023-08-15' />
                                <NewsItem index={0} title='A brand new 3D action game of the popular anime is now in development for PC and mobile devices.' time='2023-07-18' />
                            </Stack>
                        }

                        {newsIndex > 1 &&
                            <Stack sx={{ flex: 1 }}>
                                <NewsItem index={-1} title='Coming Soon' time='08/15' />
                            </Stack>
                        }

                        <NewsBottom newsIndex={newsIndex} newsTabTap={newsTabTap} />
                    </Stack>

                </Stack>
            </Stack>
        </Stack>
    );
}

function NewsBottom(props) {
    return (
        <Stack
            className='newsNav'
            sx={{
                bgcolor: '#000000',
                color: 'white',
                display: 'flex',
                flexDirection: 'row',
            }}>
            <ButtonItem index={0} newsIndex={props.newsIndex} title='Latest' newsTabTap={props.newsTabTap} />
            <ButtonItem index={1} newsIndex={props.newsIndex} title='News' newsTabTap={props.newsTabTap} />
            <ButtonItem index={2} newsIndex={props.newsIndex} title='Events' newsTabTap={props.newsTabTap} />
            <ButtonItem index={3} newsIndex={props.newsIndex} title='System' newsTabTap={props.newsTabTap} />
        </Stack>
    );
}

function ButtonItem(props) {
    return (
        <Typography
            onClick={() => { props.newsTabTap(props.index) }}
            sx={{
                cursor: 'pointer',
                width: 0.3,
                textAlign: 'center',
                fontSize: 14,
                borderBottom: 2,
                color: props.index == props.newsIndex ? '#FFD800' : 'white',
                borderBottomColor: props.index == props.newsIndex ? '#FFD800' : 'black',
                py: 0.5,
            }}>
            {props.title}
        </Typography>
    );
}

function NewsItem(props) {

    function newsTap(i) {
        const host = window.location.href.replace(window.location.pathname, '');
        window.open(host + '/newsDetail/' + i);
    }

    return (
        <Stack
            onClick={() => {
                if (props.index >= 0) {
                    newsTap(props.index)
                }
            }}
            sx={{
                cursor: 'pointer',
                width: 1,
                height: '25px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            }}>
            <Typography sx={{
                color: 'white',
                fontSize: 15,
                flex: 1,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                display: 'inline-block',
                pl: 1.5,
            }}>
                {props.title}
            </Typography>

            <Typography sx={{
                color: '#c0c0c0',
                fontSize: 15,
                pr: 1.5,
            }}>
                {props.time}
            </Typography>
        </Stack>
    );
}

function ImgItem(props) {
    return (
        <Stack onClick={() => props.imageTap(props.index)}
            sx={{
                width: 1,
                height: 1,
            }}>
            <Box component={'img'}
                src={props.value}
                sx={{
                    cursor: 'pointer',
                    objectFit: 'fill',
                    height: 1,
                }} />
        </Stack>
    );
}
