import ReactDOM from 'react-dom/client';
import * as React from 'react';
import './index.css';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import App from './App';
import store from './store/Store';
import { Provider } from 'react-redux';
import NewsDetail from './components/NewsDetail';
import MobileApp from './components/mobile/MobileApp'
import GameLauncher from './components/GameLauncher';
import GameLauncher_US from './components/GameLauncher_US';
import * as rdd from 'react-device-detect';
import { Stack } from '@mui/material';
import Privacy from './components/Privacy';
import Terms from './components/Terms';
import PreDownload from './components/PreDownload';
import BindTwitch from './components/twitch/BindTwitch';
import BindTwitchCR from './components/twitch/BindTwitchCR';
import SharePageWrapper from './components/SharePageWrapper';
import PreRegisterSuccessPage from './components/PreRegisterSuccessPage';
import AppleEmptyCallback from './components/twitch/AppleEmptyCallback';
import ProductStore from './components/payment/ProductStore';
import ProductStoreCR from './components/payment/USA/ProductStoreCR';
import GameVideo from './components/GameVideo';
import TimeCountPage from './components/TimeCountPage';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<AppWraper />} />
        <Route path='/K209DR85JG5HG054JF034JFN' element={<PreDownload />} />
        <Route path='/privacy' element={<Privacy />} />
        <Route path='/terms' element={<Terms />} />
        <Route path='/newsDetail/:newsId' element={<NewsDetail />} />
        <Route path='/game_launcher_eu' element={<GameLauncher />} />
        <Route path='/game_launcher_us' element={<GameLauncher_US />} />
        <Route path='*' element={<AppWraper />} />
        <Route path='/preRegisterSuccessPage' element={<PreRegisterSuccessPage />} />
        <Route path='/bindTwitch' element={<BindTwitch />} />
        <Route path='/twitchdrop' element={<BindTwitchCR />} />
        <Route path='/AppleEmptyCallback' element={<AppleEmptyCallback />} />
        <Route path='/productStoreEU' element={<ProductStore />} />
        <Route path='/productStoreCR' element={<ProductStoreCR />} />
        <Route path='/twitter_video_game' element={<GameVideo />} />
        <Route path='/timeCount' element={<TimeCountPage />} />
        

        {/* 运营分享用 */}
        <Route path='/RMNVC9S824NB7' element={<SharePageWrapper />} />
      </Routes>
    </BrowserRouter>
  </Provider>
);

function AppWraper() {
  function isMobile() {
    return rdd.isMobile || window.innerWidth < 700;
  }

  return (
    <Stack>
      {isMobile() && <MobileApp />}
      {!isMobile() && <App />}
    </Stack>
  )
}

