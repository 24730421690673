import { Box, Stack } from '@mui/material';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { settingActions } from '../store/reducers/SettingSlice';
import * as rdd from 'react-device-detect';
import * as APJRequest from '../js/APJRequest';


export default function RightIcon() {

    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);
    const [count, setCount] = React.useState(0);
    const [myTimer, setMyTimer] = React.useState(null);

    function musicAni() {
        setCount(prevCount => {
            if (prevCount >= 29) {
                return 0;
            }
            return prevCount + 1;
        });
    }

    function musicTap() {
        dispatch(settingActions.musicTap());
        if (myTimer == null) {
            setMyTimer(setInterval(function () { musicAni() }, 70));
        } else {
            clearInterval(myTimer);
            setMyTimer(null);
        }
    }

    function shareTap(i) {
        if (i == 0) { //disk
            APJRequest.openDiscord();

        } else if (i == 1) {//tiwtter
            APJRequest.openTwitter();

        } else { //fb
            APJRequest.openFacebook();
        }
    }

    function gifTap(){
        APJRequest.openQuestion();
    }

    return (
        <Stack
            sx={{
                position: 'absolute',
                display: (rdd.isMobile || window.innerWidth < 700) ? 'none' : 'flex',
                flexDirection: 'column',
                right: 0,
                alignItems: 'center',
                mr: '25px',
                top: '100px',
                zIndex: 10,
            }}>

            <Box
                id="music_img"
                component={'img'}
                src={settingData.playingMusic ? window.CDNHost+`/img/music_gif/_000${count}.png` : window.CDNHost+'/img/music_stop.png'}
                onClick={musicTap}
                sx={{
                    cursor: 'pointer',
                    width: '50px',
                    animation: settingData.playingMusic ? 'musicRotate 3s linear infinite' : '',
                    transitionDuration: '0.3s',
                    '&:hover': {
                        cursor: 'pointer',
                        transform: 'scale(1.1)',
                    }
                }} />

            <Box component={'img'}
                onClick={(e) => shareTap(0)}
                src={window.CDNHost+'/img/pt1.png'}
                sx={{
                    cursor: 'pointer',
                    width: '40px',
                    mt: 10,
                    transitionDuration: '0.3s',
                    '&:hover': {
                        transform: 'scale(1.1)',
                        filter: 'invert(30%) sepia(91%) saturate(603%) hue-rotate(182deg) brightness(90%) contrast(87%) drop-shadow(1px 1px 20px #3159BE)',
                    },
                }} />

            <Box component={'img'}
                onClick={(e) => shareTap(1)}
                src={window.CDNHost+'/img/pt2.png'}
                sx={{
                    cursor: 'pointer',
                    width: '44px',
                    mt: 5,
                    transitionDuration: '0.3s',
                    '&:hover': {
                        transform: 'scale(1.1)',
                        filter: 'invert(30%) sepia(91%) saturate(603%) hue-rotate(182deg) brightness(90%) contrast(87%) drop-shadow(1px 1px 20px #3159BE)',
                    }
                }} />

            <Box component={'img'}
                onClick={(e) => shareTap(2)}
                src={window.CDNHost+'/img/pt3.png'}
                sx={{
                    cursor: 'pointer',
                    width: '47px',
                    mt: 5,
                    transitionDuration: '0.3s',
                    '&:hover': {
                        transform: 'scale(1.1)',
                        filter: 'invert(30%) sepia(91%) saturate(603%) hue-rotate(182deg) brightness(90%) contrast(87%) drop-shadow(1px 1px 20px #3159BE)',
                    }
                }} />

            <Box component={'img'} src={window.CDNHost+'/img/float_icon.gif'}
            onClick={gifTap}
                sx={{
                    display: 'none',
                    height: '120px',
                    mt: 6,
                    filter: 'brightness(120%)',
                    transitionDuration: '0.3s',
                    '&:hover': {
                        cursor: 'pointer',
                        transform: 'scale(1.1)',
                        filter: 'drop-shadow(1px 1px 20px #3159BE)  brightness(150%)',
                    }
                }} />

            <Box component={'audio'}
                src={window.CDNHost+'/img/bgm.mp3'}
                loop={true}
                id='bg_music'
                preload={'auto'} />

        </Stack>
    );

}