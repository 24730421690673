import { Box, Stack } from '@mui/material';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { settingActions } from '../../store/reducers/SettingSlice';
import * as APJRequest from '../../js/APJRequest';
import * as rdd from 'react-device-detect';


export default function MobileDownFooter(props) {

    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);

    function signTap() {
        dispatch(settingActions.showSignDalog());
    }

    function isMobile() {
        if (rdd.isMobile || window.innerWidth < 700) {
            return true;
        }
        return false;
    }

    return (
        <Stack sx={{
            width: 1,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
        }}>

            <Box
                onClick={signTap}
                component={'span'}
                sx={{
                    cursor: 'pointer',
                    background: `url(${window.CDNHost}/img/blue_bg1.png) no-repeat`,
                    backgroundSize: '100% 100%',
                    color: 'white',
                    height: '34px',
                    lineHeight: '34px',
                    alignItems: 'center',
                    textAlign: 'center',
                    fontSize: 10,
                    px: '5px',
                    minWidth: '100px',
                    display: 'none',
                }}>
                {/* SE 2PRÉINSCRIRE MAINTENANT */}
                {settingData.langDic['lang6']}
            </Box>


            <Box component={'img'}
                onClick={() => {
                    if (props.isCR) {
                        window.open('https://apps.apple.com/us/app/one-punch-man-world/id6448791649');

                    } else {
                        APJRequest.openApple()
                    }
                }}
                src={window.CDNHost + '/img/btn_down1_release.png'}
                sx={{
                    cursor: 'pointer',
                    height: '40px',
                    border: 1,
                    ml: 0.5,
                    borderRadius: 1,
                    borderColor: '#A1A1A0',
                    mr: isMobile() ? 1 : 0,
                }} />

            <Box component={'img'}
                onClick={() => {
                    if (props.isCR) {
                        window.open('https://play.google.com/store/apps/details?id=com.crunchyroll.opm&pli=1');

                    } else {
                        APJRequest.openGoogle()
                    }
                }}
                src={window.CDNHost + '/img/btn_down2_release.png'}
                sx={{
                    cursor: 'pointer',
                    height: '40px',
                    border: 1,
                    ml: isMobile() ? 1 : 0.5,
                    borderRadius: 1,
                    borderColor: '#A1A1A0',
                }} />
        </Stack>
    );
}