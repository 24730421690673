import { Stack } from '@mui/material';
import * as React from 'react';
import * as rdd from 'react-device-detect';

import ShareUsedPage from './ShareUsedPage';
import ShareMobilePage from '../components/mobile/ShareMobilePage';

export default function SharePageWrapper() {

    function isMobile() {
        return rdd.isMobile || window.innerWidth < 700;
    }

    return (
        <Stack>
            {isMobile() && <ShareMobilePage />}
            {!isMobile() && <ShareUsedPage />} 
        </Stack>
    )

}