import { Backdrop, Box, Stack, Typography } from '@mui/material';
import * as React from 'react';
import $ from 'jquery';
import * as APJRequest from '../js/APJRequest';
import * as rdd from 'react-device-detect';

export default function GameVideo(pros) {

    const [vHeight, setVHeight] = React.useState(window.innerHeight);
    let resizeTimer;
    const [index, setIndex] = React.useState(1);
    const [muted, setMuted] = React.useState(false); //静音

    React.useEffect(() => {
        $(window).on('resize', function (e) {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(function () {
                setVHeight(window.innerHeight)
            }, 250);
        });
    }, []);

    function addMeta(tyep, value, type2, value2) {
        const head = document.getElementsByTagName('head').item(0);
        const script = document.createElement('meta');
        script.setAttribute(tyep, value);
        script.setAttribute(type2, value2);
        head.appendChild(script);
    }

    function startPlayTap() {
        setIndex(2);
        $('#game_video').get(0).play();
    }

    function onVideoFinished() {
        setMuted(true);
        setIndex(3);
    }

    function replayTap() {
        setMuted(false);
        setIndex(1);
    }

    return (
        <Stack sx={{
            bgcolor: 'black',
            minHeight: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
        }}>

            <Stack sx={{
                position: 'relative',
                height: `${vHeight}px`,
                maxHeight: '1000px',
                width: (rdd.isMobile ? 1 : 'auto'),
            }}>
                {/* 站位 */}
                <Box
                    component={'img'}
                    src={`${window.CDNHost}/img/videos/welcome.png`}
                    sx={{
                        height: 1,
                        width: 1,
                        objectFit: 'fill'
                    }} />

                <WelcomeView index={index} />

                <StartView index={index} startPlayTap={startPlayTap} setMuted={setMuted} muted={muted} />

                <VideoView index={index} setMuted={setMuted} muted={muted} onVideoFinished={onVideoFinished} />

                <FinishView index={index} replayTap={replayTap} />

            </Stack>

        </Stack>
    );
}


function WelcomeView(props) {
    return (
        <Stack
            id='index_0'
            sx={{
                display: props.index == 0 ? 'flex' : 'none',
                width: 1,
                height: 1,
                position: 'absolute',
                bgcolor: '#061429',
            }}>
            <Box
                component={'img'}
                src={`${window.CDNHost}/img/videos/welcome.png`}
                sx={{
                    height: 1,
                    width: 1,
                    objectFit: 'fill',
                    position: 'absolute',

                    //mask 动画
                    mask: `url(/img/videos/masky2.png)`,
                    maskImage: `url(/img/videos/masky2.png)`,
                    WebkitMaskImage: `url(/img/videos/masky2.png)`,
                    maskPosition: '0 0',
                    maskSize: '4000% 100%',
                    animation: 'maskMove 3s steps(39) forwards',
                }} />

        </Stack>
    )
}


function StartView(props) {
    return (
        <Stack
            id='index_1'
            sx={{
                visibility: props.index == 1 ? 'flex' : 'hidden',
                width: 1,
                height: 1,
                position: 'absolute',
                bgcolor: '#000000d0',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
            <Typography sx={{
                textAlign: 'center',
                width: 0.9,
                fontSize: 19,
                fontWeight: 'bold'
            }}>
                Please note that the sound will play when the sound is turned ON.
            </Typography>

            <Stack sx={{
                flexDirection: 'row',
                height: '60px',
                mt: 8,
                mb: 10,
            }}>
                <Box
                    onClick={() => {
                        props.setMuted(false);
                    }}
                    component={'img'}
                    src={props.muted ? `${window.CDNHost}/img/videos/on_black.png` : `${window.CDNHost}/img/videos/on_white.png`}
                    sx={{
                        height: 1,
                        mr: 5,
                        cursor: 'pointer',
                    }} />

                <Box
                    onClick={() => {
                        props.setMuted(true);
                    }}
                    component={'img'}
                    src={props.muted ? `${window.CDNHost}/img/videos/off_white.png` : `${window.CDNHost}/img/videos/off_black.png`}
                    sx={{
                        height: 1,
                        cursor: 'pointer',
                    }} />

            </Stack>

            <Typography
                onClick={props.startPlayTap}
                sx={{
                    textAlign: 'center',
                    width: '180px',
                    height: '46px',
                    lineHeight: '46px',
                    fontSize: 16,
                    fontWeight: 'bold',
                    bgcolor: '#F76E40',
                    borderRadius: '23px',
                    cursor: 'pointer',
                }}>
                Start Play
            </Typography>

        </Stack>
    )
}


function VideoView(props) {
    const [count, setCount] = React.useState(0);
    const [myTimer, setMyTimer] = React.useState(null);

    React.useEffect(() => {
        $('#game_video').on('ended', () => {
            props.onVideoFinished();
        })
    }, []);

    function musicTap() {
        props.setMuted(!props.muted);
        if (myTimer == null) {
            setMyTimer(setInterval(function () { musicAni() }, 70));
        } else {
            clearInterval(myTimer);
            setMyTimer(null);
        }
    }

    function musicAni() {
        setCount(prevCount => {
            if (prevCount >= 29) {
                return 0;
            }
            return prevCount + 1;
        });
    }

    return (
        <Stack sx={{
            display: props.index == 2 ? 'flex' : 'none',
            width: 1,
            height: 1,
            position: 'absolute',
        }}>
            <Box
                id='game_video'
                component={'video'}
                autoPlay={false}
                loop={false}
                muted={props.muted}
                src={`${window.CDNHost}/img/videos/test3.mp4`}
                sx={{
                    position: 'absolute',
                    display: props.index == 2 ? 'flex' : 'none',
                    height: 1,
                    width: 1,
                    objectFit: 'fill'
                }} />

            {/* 音乐 */}
            <Box
                component={'img'}
                src={!props.muted ? `${window.CDNHost}/img/music_gif/_000${count}.png` : `${window.CDNHost}/img/music_stop.png`}
                onClick={musicTap}
                sx={{
                    display: props.index == 2 ? 'flex' : 'none',
                    position: 'absolute',
                    right: '10px',
                    top: '45px',
                    cursor: 'pointer',
                    width: '50px',
                    animation: !props.muted ? 'musicRotate 3s linear infinite' : '',
                    transitionDuration: '0.3s',
                    '&:hover': {
                        cursor: 'pointer',
                        transform: 'scale(1.1)',
                    }
                }} />
        </Stack>
    );


}


function FinishView(props) {

    function buttonTap(index) {
        if (index == 0) {
            APJRequest.openApple();

        } else if (index == 1) {
            APJRequest.openGoogle();

        } else if (index == 2) {
            APJRequest.openPcDownload();
        }
    }

    return (
        <Stack
            id='index_3'
            sx={{
                display: props.index == 3 ? 'flex' : 'none',
                width: 1,
                height: 1,
                position: 'absolute',
            }}>
            <Box
                component={'img'}
                src={`${window.CDNHost}/img/videos/video_bg.png`}
                sx={{
                    height: 1,
                    width: 1,
                    objectFit: 'fill',
                    position: 'absolute'
                }} />

            <Stack sx={{
                width: 1,
                height: 1,
                position: 'absolute',
                alignItems: 'center',
                justifyContent: 'flex-end',
            }}>

                <Box
                    component={'img'}
                    src={`${window.CDNHost}/img/logo.png`}
                    sx={{
                        width: 0.8,
                    }} />

                <Stack sx={{
                    flexDirection: 'row',
                    width: 1,
                    justifyContent: 'center',
                    mb: 2,
                    mt: 7,
                }}>

                    <Box
                        onClick={() => {
                            buttonTap(0);
                        }}
                        component={'img'}
                        src={`${window.CDNHost}/img/btn_down1_release.png`}
                        sx={{
                            width: 0.27,
                            cursor: 'pointer',
                            border: 1,
                            borderColor: 'gray',
                            borderRadius: '6px',
                        }} />
                    <Box
                        onClick={() => {
                            buttonTap(1);
                        }}
                        component={'img'}
                        src={`${window.CDNHost}/img/btn_down2_release.png`}
                        sx={{
                            width: 0.27,
                            cursor: 'pointer',
                            ml: 0.5,
                            border: 1,
                            borderColor: 'gray',
                            borderRadius: '6px',
                        }} />
                    <Box
                        onClick={() => {
                            buttonTap(2);
                        }}
                        component={'img'}
                        src={`${window.CDNHost}/img/btn_down3_release.png`}
                        sx={{
                            width: 0.27,
                            ml: 0.5,
                            border: 1,
                            borderColor: 'gray',
                            borderRadius: '6px',
                        }} />
                </Stack>

                <Box component={'img'}
                    src={`${window.CDNHost}/img/payment/copy_right.png`}
                    sx={{
                        width: 0.8,
                        mb: 13,
                    }} />

                {/* 重看 */}
                <Box
                    component={'img'}
                    src={`${window.CDNHost}/img/videos/replayTap.png`}
                    onClick={props.replayTap}
                    sx={{
                        position: 'absolute',
                        right: '20px',
                        top: '55px',
                        cursor: 'pointer',
                        width: '28px',
                        transitionDuration: '0.3s',
                        '&:hover': {
                            transform: 'scale(1.1)',
                        }
                    }} />

            </Stack>
        </Stack>
    );
}