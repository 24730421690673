import { Box, Stack, Typography } from '@mui/material';
import * as React from 'react';
import RightIcon from './RightIcons';
import Home from './Home';
import NavView from './NavView';
import CharacterContainer from './CharacterContainer';
import Videos from './Videos';
import Introduction from './Introduction';
import SignDialog from './SignDialog';

import { useSelector, useDispatch } from 'react-redux';
import { settingActions, settingObj } from '../store/reducers/SettingSlice';
import NewsList from './NewsList';
import PreRegister from './PreRegister';
import CookiesView from './CookiesView';

export default function Main(props) {

    const settingData = useSelector(state => state.settingData);
    const dispatch = useDispatch();
    let isProcessing = false;

    React.useEffect(() => {
        addScrollListener();
    }, []);

    function addScrollListener() {
        if (!settingObj.mainOpened) {
            setTimeout(() => {
                window.addEventListener('wheel', scrollFunc);
            }, 3100);
        }
    }

    const scrollFunc = (e) => {
        if (isProcessing || settingObj.openAlert || !settingObj.mainOpened || !settingObj.enableScroll) {
            return;
        }

        if (e.wheelDelta < 0) {
            isProcessing = true;
            dispatch(settingActions.setNextNavIndex());

        } else if (e.wheelDelta > 0) {
            isProcessing = true;
            dispatch(settingActions.setPreNavIndex());
        }

        setTimeout(() => {
            isProcessing = false;
        }, 1000);
    }

    return (
        <Stack sx={{
            width: 1,
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            bgcolor: '#0E152C',
        }}>
            {settingData.navIndex == 0 && <Home backTap={props.backTap} />}
            {settingData.navIndex == 1 && <PreRegister />}
            {settingData.navIndex == 2 && <NewsList />}
            {settingData.navIndex == 3 && <CharacterContainer />}
            {settingData.navIndex == 4 && <Videos />}
            {settingData.navIndex == 5 && <Introduction />}

            <NavView />
            <CookiesView />

            <SignDialog />

        </Stack>
    );

}