import { Box, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { heroActions, obj } from '../store/reducers/HeroSlice';
import { Swiper, SwiperSlide } from "swiper/react";
import { settingActions } from '../store/reducers/SettingSlice';
import "swiper/css";
import * as APJRequest from '../js/APJRequest';
import InfoFooter from './InfoFooter';

export default function Introduction() {

    const dispatch = useDispatch();
    const heroData = useSelector(state => state.heroData);
    const [backIndex, setBackIndex] = React.useState(heroData.posters.length - 1);
    const [swp, setSwp] = React.useState(null);

    React.useEffect(() => {
        dispatch(heroActions.selectPosterIndex(0));
        setTimeout(() => {
            setBackIndex(0);
        }, 610);
    }, []);

    function indexChanged() {
        if (heroData.posterIndex != swp.realIndex) {
            dispatch(heroActions.selectPosterIndex(swp.realIndex));
            setTimeout(() => {
                setBackIndex(swp.realIndex);
            }, 610);
        }
    }

    return (
        <Stack
            sx={{
                width: 1,
                flex: 1,
                overflow: 'hidden',
                alignItems: 'center',
                background: 'black',
            }}>

            <Box
                component={'img'}
                src={window.CDNHost+`/img/hero_poster/${heroData.posters[backIndex]}`}
                sx={{
                    position: 'absolute',
                    width: 1,
                    height: 1,
                    objectFit: 'cover',
                }} />

            <Box
                key={'front_img' + heroData.posterIndex} //为了每次刷新
                component={'img'}
                src={window.CDNHost+`/img/hero_poster/${heroData.posters[heroData.posterIndex]}`}
                sx={{
                    position: 'absolute',
                    width: 1,
                    height: 1,
                    objectFit: 'cover',

                    //mask 动画
                    mask: `url(${window.CDNHost}/img/masky.png)`,
                    maskImage: `url(${window.CDNHost}/img/masky.png)`,
                    WebkitMaskImage: `url(${window.CDNHost}/img/masky.png)`,
                    maskPosition: '0 0',
                    maskSize: '4000% 100%',
                    animation: 'maskMove .6s steps(39) forwards',
                }} />

            {/* Banner 图片 */}
            <Stack sx={{
                width: { xs: '90%', sm: '600px', md: '700px', lg: '800px', },
                overflow: 'hidden',
                position: 'absolute',
                bottom: { xs: '70px', sm: '80px', md: '90px', lg: '100px' },
                right: { xs: '10px', sm: '60px' },
            }}>

                <Swiper
                    className='helloSP'
                    style={{
                        width: '100%',
                    }}

                    onSwiper={(sp) => {
                        setSwp(sp);
                    }}

                    onActiveIndexChange={(sp) => {
                        indexChanged();
                    }}

                    spaceBetween={20}
                    slideToClickedSlide={true}
                    slidesPerView={4}
                    loop={true}>

                    {
                        heroData.posters.map((value, i) =>
                            <SwiperSlide
                                key={i}
                                style={{
                                    display: 'flex',
                                    background: 'black',
                                }}>
                                <BannerItem value={value} index={i} activeIndex={heroData.posterIndex} />
                            </SwiperSlide>
                        )
                    }
                </Swiper>
            </Stack>

            <InfoFooter />

        </Stack>
    );
}

function BannerItem(props) {
    return (
        <Stack sx={{
            border: 2,
            borderColor: 'white',
            opacity: props.index == props.activeIndex ? 1 : 0.5,
            overflow: 'hidden',
        }}>

            <Box component={'img'}
                src={window.CDNHost+`/img/hero_poster/${props.value}`}
                sx={{
                    cursor: 'pointer',
                    objectFit: 'cover',
                    transitionDuration: '0.3s',
                    '&:hover': {
                        transform: 'scale(1.1)',
                    }
                }} />
        </Stack>
    );
}
