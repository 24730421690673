import { Box, Stack, Typography } from '@mui/material';
import * as React from 'react';
import ReactPlayer from 'react-player';
import VideoFooter from './VideoFooter';
import * as StringTool from '../js/StringTool';
import { useNavigate } from "react-router-dom";
import $ from 'jquery';
import * as APJRequest from '../js/APJRequest';

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from 'swiper/modules';

import { useSelector, useDispatch } from 'react-redux'
import { heroActions } from '../store/reducers/HeroSlice';
import { settingActions } from '../store/reducers/SettingSlice';
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';

export default function NewsList(props) {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const heroData = useSelector(state => state.heroData);
    const settingData = useSelector(state => state.settingData);

    const newsIdAry = ['15','14','13','12','11'];
    const iconArray = [window.CDNHost + '/img/news/puri.png', window.CDNHost + '/img/news/sonic.png',window.CDNHost + '/img/news/kaotou.png', window.CDNHost + '/img/news/tian.png', window.CDNHost + '/img/news/jie_nuo_si.png'];
    const [selectIndex, setSetselectIndex] = React.useState(0);
    const [swp, setSwp] = React.useState(null);
    const [twitterViewId, settwitterViewId] = React.useState('');
    var resizeTimer;

    React.useEffect(() => {
        $(window).on('resize', function (e) {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(function () {
                // 此函数在resize结束的时候执行
                settwitterViewId(Math.random());
            }, 250);
        });
    }, []);


    function indexChanged() {
        if (swp) {
            if (swp.realIndex != selectIndex) {
                setSetselectIndex(swp.realIndex);
            }
        }
    }

    function scrollTo(i) {
        swp.slideTo(i);
    }

    function newsTap(i) {
        window.open(window.location + 'newsDetail/' + i);
    }

    function getTTHeight() {
        let x = window.innerWidth * 0.8;
        if (x > 1600) {
            x = 1600;
        }
        if (x < 1000) {
            x = 1000;
        }
        return x * 0.48 * 0.75;
    }

    return (
        <Stack
            sx={{
                width: 1,
                flex: 1,
                overflow: 'hidden',
                alignItems: 'center',
            }}>

            <Box
                component={'img'}
                src={window.CDNHost + '/img/bg1.jpg'}
                sx={{
                    position: 'absolute',
                    width: 1,
                    height: 1,
                    objectFit: 'cover',
                    animation: 'bgani 5s ease-in-out both infinite',
                }} />

            <Stack sx={{
                position: 'absolute',
                width: 0.8,
                height: 1,
                display: 'flex',
                justifyContent: 'center',
                maxWidth: '1600px',
                minWidth: '1000px',
            }}>

                <Stack sx={{
                    color: 'white',
                    animation: 'upToBottom 1s',
                }}>
                    <Box component={'img'} src={window.CDNHost + '/img/news/tit_zs.png'}
                        sx={{
                            height: '40px',
                            width: '150px',
                            position: 'absolute',
                            ml: 3,
                            mt: 2
                        }} />

                    <Typography sx={{
                        fontSize: 48,
                        fontFamily: 'redFont',
                        zIndex: 1,
                    }}>
                        {settingData.langDic['lang1']}
                    </Typography>
                </Stack>

                {/* 左右分割 */}
                <Stack sx={{
                    flexDirection: 'row',
                }}>
                    {/* 左边 */}
                    <Stack sx={{
                        width: 0.47,
                    }}>
                        <Stack
                            id='news_list_div'
                            onMouseOver={() => {
                                dispatch(settingActions.setWindowWheelEnable(false));
                            }}
                            onMouseLeave={() => {
                                dispatch(settingActions.setWindowWheelEnable(true));
                            }}
                            sx={{
                                height: '125px',
                                overflowY: 'scroll',
                            }}>

                            <NewsItem newsTap={newsTap} id={15} title="Rate Up Banner: Puri-puri Prisoner" date='2024-05-09' /> 
                            <NewsItem newsTap={newsTap} id={14} title="New Character: Speed-o'-Sound Sonic—Prisoner" date='2024-04-25' />     
                            <NewsItem newsTap={newsTap} id={13} title="Rate Up Banner: Silverfang" date='2024-04-11' />
                            <NewsItem newsTap={newsTap} id={12} title="New Character: Handsome Kamen Amai Mask" date='2024-03-28' />
                            <NewsItem newsTap={newsTap} id={11} title="Rate Up Banner: Genos – Cyborg of Justice" date='2024-03-21' />
                            <NewsItem newsTap={newsTap} id={10} title="Rate Up Banner: Hellish Blizzard" date='2024-03-07' />    
                            <NewsItem newsTap={newsTap} id={9} title="New Hero: Atomic Samurai" date='2024-02-29' />
                            <NewsItem newsTap={newsTap} id={8} title="Rate Up Banner: Saitama - Dreamworld" date='2024-02-15' />
                            <NewsItem newsTap={newsTap} id={7} title="Terrible Tornado Time-limited Rate Up Banner" date='2024-02-03' />
                            <NewsItem newsTap={newsTap} id={6} title="Update Details Feb. 1st, 2024" date='2024-02-01' />
                            <NewsItem newsTap={newsTap} id={5} title="HEROES ASSEMBLE! ONE PUNCH MAN: WORLD LAUNCHES AS THE SERIES' FIRST 3D ONLINE ACTION GAME ON PC AND MOBILE" date='2024-01-30' />
                            <NewsItem newsTap={newsTap} id={4} title='One Punch Man: World Closed Beta Test FAQ' date='2023-10-13' />
                            <NewsItem newsTap={newsTap} id={3} title='One Punch Man: World Announcement of Pre-Registration and Closed Beta Test' date='2023-10-04' />
                            <NewsItem newsTap={newsTap} id={2} title='One Punch Man: World Closed Beta Test Application Opens NOW!' date='2023-10-04' />
                            <NewsItem newsTap={newsTap} id={1} title='One Punch Man: World Playable Demo Comes To Gamescom' date='2023-08-15' />
                            <NewsItem newsTap={newsTap} id={0} title='One Punch Man: World, a brand new 3D action game of the popular anime is now in development for PC and mobile devices.' date='2023-07-18' />
                        </Stack>

                        <Stack sx={{
                            width: 1,
                            position: 'relative',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                            <Box component={'img'}
                                src={window.CDNHost + '/img/news/bg2.png'}
                                sx={{
                                    width: 1,
                                }} />

                            <Swiper
                                resistanceRatio={0}
                                loop={true}
                                modules={[Autoplay]}
                                slidesPerView={1}
                                centeredSlides={true}
                                slideToClickedSlide={true}

                                autoplay={{
                                    delay: 3000,
                                    disableOnInteraction: false,
                                }}

                                style={{
                                    width: '97%',
                                    height: '96%',
                                    position: 'absolute',
                                    borderRadius: '4px',
                                }}

                                onSwiper={(sp) => {
                                    setSwp(sp);
                                }}

                                onActiveIndexChange={(sp) => {
                                    indexChanged();
                                }} >

                                {
                                    iconArray.map((value, i) => {
                                        return <SwiperSlide key={i}
                                            style={{
                                                display: 'flex',
                                                background: 'black',
                                            }}>
                                            <ImageItem newsTap={newsTap} id={newsIdAry[i]} key={i} value={value} />
                                        </SwiperSlide>

                                    })
                                }
                            </Swiper>
                        </Stack>
                    </Stack>

                    <Box sx={{ flex: 1 }} />

                    {/* 右边 */}
                    <Stack
                        key={twitterViewId}
                        component={'div'}
                        sx={{
                            position: 'relative',
                            width: 0.47,
                            borderRadius: '14px',
                            height: 1,
                            overflow: 'hidden',
                        }}>

                        <TwitterTimelineEmbed
                            sourceType="profile"
                            screenName="onepunchmanw_eu"
                            theme='light'
                            options={{ height: getTTHeight() }}
                        />

                        <Box component={'img'}
                            src={window.CDNHost + '/img/news/bottom_2.png'}
                            sx={{
                                height: '65px',
                                position: 'absolute',
                                bottom: '58px',
                                right: 0,

                            }} />

                        {/* 底部 */}
                        <Stack sx={{
                            width: 1,
                            bottom: 0,
                            height: '60px',
                            position: 'absolute',
                            background: `url(${window.CDNHost}/img/news/bottom_1.png) no-repeat`,
                            backgroundSize: '100% 100%',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}>

                            <Box component={'img'}
                                src={window.CDNHost + '/img/share/X.png'}
                                sx={{
                                    height: '30px',
                                    ml: 3,
                                }} />

                            <Typography sx={{
                                flex: 1,
                                fontWeight: 'bold',
                                fontSize: 28,
                                color: 'white',
                                ml: 2,
                            }}>
                                FOLLOW US
                            </Typography>


                            <Typography
                                onClick={() => {
                                    APJRequest.openTwitter();
                                }}
                                sx={{
                                    mr: 2,
                                    fontWeight: 'bold',
                                    fontSize: 14,
                                    color: '#D5E5FC',
                                    cursor: 'pointer'
                                }}>
                                @ONEPUNCHMANW_EU
                            </Typography>

                        </Stack>
                    </Stack>

                    <Box component={'img'}
                        src={window.CDNHost + '/img/news/right.png'}
                        sx={{
                            height: '200px',
                            mt: 1,
                        }} />
                </Stack>
            </Stack>

            {/* 下载Footer */}
            <VideoFooter />
        </Stack>

    );
}


function ImageItem(props) {
    return (
        <Box component={'img'}
            onClick={() => props.newsTap(props.id)}
            src={props.value}
            sx={{
                cursor: 'pointer',
                width: 1,
                height: 1,
                transitionDuration: '0.3s',
                '&:hover': {
                    color: 'white',
                    transform: 'scale(1.03)',
                }
            }} />
    );
}


function ProgressView(props) {

    return (
        <Stack sx={{
            display: 'flex',
            flexDirection: 'row',
            zIndex: 2,
            position: 'absolute',
            justifyContent: 'flex-end',
            bottom: '-15px',
            right: '20px',
        }}>
            {
                new Array(props.count).fill(1).map((v, i) => {
                    return <Box
                        onClick={() => props.scrollTo(i)}
                        key={i}
                        component={'div'} sx={{
                            cursor: 'pointer',
                            width: '40px',
                            height: '6px',
                            bgcolor: i == props.selectIndex ? '#4FFFFAaa' : '#636770aa',
                            ml: 2,
                            borderRadius: '2px',
                        }} />
                })
            }
        </Stack>
    );
}

function NewsItem(props) {

    return (
        <Stack
            onClick={() => props.newsTap(props.id)}
            sx={{
                cursor: 'pointer',
                minHeight: '40px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                color: '#D5D8DC',
            }}>

            <Typography sx={{
                fontSize: 14,
                fontSize: 17,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                display: 'inline-block',
                flex: 1,
            }}>
                {props.title}
            </Typography>


            <Typography sx={{
                fontSize: 15,
                color: '#9DACC9',
                ml: 5,
                mr: 2,
            }}>
                {props.date}
            </Typography>

        </Stack>
    );
}