import { Box, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-creative';
import MobileDownFooter from './MobileDownFooter';

export default function MobileNews() {

    const settingData = useSelector(state => state.settingData);

    const newsIdAry = ['15','14','13','12','11'];
    const iconArray = [window.CDNHost + '/img/news/puri.png', window.CDNHost + '/img/news/sonic.png',window.CDNHost + '/img/news/kaotou.png', window.CDNHost + '/img/news/tian.png', window.CDNHost + '/img/news/jie_nuo_si.png'];
   
    const [selectIndex, setSetselectIndex] = React.useState(0);
    const [swp, setSwp] = React.useState(null);

    const [imgWidth, setImageWidth] = React.useState(window.innerWidth * 1);

    function indexChanged() {
        if (swp.realIndex != selectIndex) {
            setSetselectIndex(swp.realIndex);
        }
    }

    function scrollTo(i) {
        swp.slideTo(i);
    }

    function newsTap(i) {
        window.open(window.location + 'newsDetail/' + i);
    }

    return (
        <Stack sx={{
            height: 1,
            display: 'flex',
            justifyContent: 'center',
            bgcolor: '#455172',
            alignItems: 'center',
        }}>
            {/* 背景 */}
            <Box component={'img'}
                src={window.CDNHost + '/img/mobile/m_bg2.jpg'}
                sx={{
                    zIndex: 0,
                    position: 'absolute',
                    width: 1,
                    height: 1,
                    objectFit: 'cover',
                }} />

            <Typography sx={{
                zIndex: 5,
                color: 'white',
                fontFamily: 'us',
                fontSize: 32,
                ml: 3,
                mb: 1,
                alignSelf: 'start',
            }}>
                {settingData.langDic['lang1']}
            </Typography>

            {/* 图片 */}
            <Stack sx={{
                width: imgWidth,
                height: imgWidth * 0.81388,
                zIndex: 3,
            }} >
                <Box component={'img'}
                    src={window.CDNHost + '/img/news/news_icon_bg2.png'}
                    sx={{
                        height: 1,
                    }} />
                <Swiper
                    resistanceRatio={0}
                    style={{
                        height: imgWidth * 0.585,
                        width: imgWidth * 0.935,
                        marginLeft: imgWidth * 0.033,
                        marginTop: imgWidth * 0.107,
                        position: 'absolute',
                        borderRadius: '4px',
                    }}

                    onSwiper={(sp) => {
                        setSwp(sp);
                    }}

                    onActiveIndexChange={(sp) => {
                        indexChanged();
                    }} >

                    {
                        iconArray.map((value, i) => {
                            return <SwiperSlide key={i}
                                style={{
                                    display: 'flex',
                                    background: 'black',
                                }}>
                                <ImageItem newsTap={newsTap} id={newsIdAry[i]} key={i} value={value} />
                            </SwiperSlide>

                        })
                    }
                </Swiper>
                <ProgressView w={imgWidth} count={iconArray.length} selectIndex={selectIndex} scrollTo={scrollTo} />
            </Stack>

            {/* 新闻列表 */}
            <Stack sx={{
                maxHeight: '155px',
                overflow: 'auto',
                zIndex: 10,
            }}>
                <NewsItem newsTap={newsTap} id={15} title="Rate Up Banner: Puri-puri Prisoner" date='2024-05-09' /> 
                <NewsItem newsTap={newsTap} id={14} title="New Character: Speed-o'-Sound Sonic—Prisoner" date='2024-04-25' />     
                <NewsItem newsTap={newsTap} id={13} title="Rate Up Banner: Silverfang" date='2024-04-11' />
                <NewsItem newsTap={newsTap} id={12} title="New Character: Handsome Kamen Amai Mask" date='2024-03-28' />
                <NewsItem newsTap={newsTap} id={11} title="Rate Up Banner: Genos – Cyborg of Justice" date='2024-03-21' />
                <NewsItem newsTap={newsTap} id={10} title="Rate Up Banner: Hellish Blizzard" date='2024-03-07' />
                <NewsItem newsTap={newsTap} id={9} title="New Hero: Atomic Samurai" date='2024-02-29' />
                <NewsItem newsTap={newsTap} id={8} title="Rate Up Banner: Saitama - Dreamworld" date='2024-02-15' />
                <NewsItem newsTap={newsTap} id={7} title="Terrible Tornado Time-limited Rate Up Banner" date='2024-02-03' />
                <NewsItem newsTap={newsTap} id={6} title="Update Details Feb. 1st, 2024" date='2024-02-01' />
                <NewsItem newsTap={newsTap} id={5} title="HEROES ASSEMBLE! ONE PUNCH MAN: WORLD LAUNCHES AS THE SERIES' FIRST 3D ONLINE ACTION GAME ON PC AND MOBILE" date='2023-01-30' />
                <NewsItem newsTap={newsTap} id={4} title='One Punch Man: World Closed Beta Test FAQ' date='2023-10-13' />
                <NewsItem newsTap={newsTap} id={3} title='One Punch Man: World Announcement of Pre-Registration and Closed Beta Test' date='2023-10-04' />
                <NewsItem newsTap={newsTap} id={2} title='One Punch Man: World Closed Beta Test Application Opens NOW!' date='2023-10-04' />
                <NewsItem newsTap={newsTap} id={1} title='One Punch Man: World Playable Demo Comes To Gamescom' date='2023-08-15' />
                <NewsItem newsTap={newsTap} id={0} title='One Punch Man: World, a brand new 3D action game of the popular anime is now in development for PC and mobile devices.' date='2023-07-18' />
            </Stack>
            <Stack sx={{ height: '50px', }} />

            {/* 底部 */}
            <Stack sx={{
                position: 'absolute',
                width: 1,
                bottom: 0,
                mb: 2,
            }}>
                <MobileDownFooter />
            </Stack>


        </Stack>

    );
}

function ImageItem(props) {
    return (
        <Box component={'img'}
            onClick={() => props.newsTap(props.id)}
            src={props.value}
            sx={{
                cursor: 'pointer',
                width: 1,
                height: 1,
            }} />
    );
}


function ProgressView(props) {

    return (
        <Stack sx={{
            display: 'flex',
            flexDirection: 'row',
            zIndex: 2,
            position: 'absolute',
            mt: `${props.w * 0.73}px`,
            width: props.w * 0.93,
            justifyContent: 'flex-end',
        }}>
            {
                new Array(props.count).fill(1).map((v, i) => {
                    return <Box
                        onClick={() => props.scrollTo(i)}
                        key={i}
                        component={'div'} sx={{
                            cursor: 'pointer',
                            width: '40px',
                            height: '4px',
                            bgcolor: i == props.selectIndex ? '#4FFFFAaa' : '#636770aa',
                            ml: 1,
                            borderRadius: '2px',
                        }} />
                })
            }
        </Stack>
    );
}



function NewsItem(props) {

    return (
        <Stack
            onClick={() => props.newsTap(props.id)}
            sx={{
                zIndex: 3,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                ml: 2,
                mr: 2,
                mt: 2,
                color: '#D5D8DC',
                borderBottom: 1,
                borderColor: '#D5D8DC40',
            }}>

            <Typography sx={{
                fontSize: 14,
                width: window.innerWidth - 115,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                display: 'inline-block',
            }}>
                {props.title}
            </Typography>

            <Typography sx={{
                fontSize: 12,
                color: '#9DACC9',
                ml: 1,
            }}>
                {props.date}
            </Typography>

        </Stack>
    );
}