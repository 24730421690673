import { Box, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { useParams, useLocation, useSearchParams } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import RightIcon from '../components/RightIcons';
import $ from 'jquery';
import * as rdd from 'react-device-detect';

export default function NewsDetail() {

    const navigate = useNavigate();
    const [contentWidth, setContentWidth] = React.useState(900);
    const params = useParams();
    const [newsId, setNewsId] = React.useState(0);
    const [isMobile, setIsMobile] = React.useState(false);
    const [imgTag, setImgTag] = React.useState('pc_img');

    React.useEffect(() => {
        setNewsId(params['newsId']);

        if (rdd.isMobile || window.innerWidth < 700) {
            setContentWidth(window.innerWidth * 0.95);
            setIsMobile(true);
            setImgTag('mobile_img');
        }
    }, []);

    function backTap() {
        navigate(-1);
    }

    function topTap() {
        $("#news_detail_div").animate({
            scrollTop: 0
        }, "slow");
    }

    return (
        <Stack
            component={'div'}
            sx={{
                width: 1,
                height: '100vh',
                color: '#cccccc',
                alignItems: 'center',
            }}>

            <Box component={'img'} src={window.CDNHost + '/img/news/detail_bg.png'}
                sx={{
                    position: 'absolute',
                    width: 1,
                    height: 1,
                    zIndex: -1,
                    objectFit: 'cover',
                }} />

            <Stack
                id='news_detail_div'
                component={'div'}
                sx={{
                    width: 1,
                    alignItems: 'center',
                    overflow: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    overflowX: 'hidden',
                }}>
                <Box component={'img'}
                    id='news_detail_banner'
                    src={window.CDNHost + '/img/news/detail_banner.png'} sx={{
                        width: 1,
                        maxHeight: '300px',
                        objectFit: 'cover',
                        display: isMobile ? 'none' : '',
                    }} />

                {/* 标题 */}
                <Stack sx={{
                    width: 1,
                }}>

                    <Stack sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        minHeight: '80px',
                    }}>

                        <Typography
                            sx={{
                                width: contentWidth,
                                fontFamily: 'us',
                                fontSize: isMobile ? 20 : 25,
                                my: 2,
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                mx: 3,
                            }}>

                            {newsId == 15 && 'Rate Up Banner: Puri-puri Prisoner'}
                            {newsId == 14 && 'New Character: Speed-o\'-Sound Sonic—Prisoner'}
                            {newsId == 13 && 'Rate Up Banner: Silverfang'}
                            {newsId == 12 && 'New Character: Handsome Kamen Amai Mask'}
                            {newsId == 11 && 'Rate Up Banner: Genos - Cyborg of Justice'}
                            {newsId == 10 && 'Rate Up Banner: Hellish Blizzard'}
                            {newsId == 9 && 'New Hero: Atomic Samurai'}
                            {newsId == 8 && 'Rate Up Banner: Saitama - Dreamworld'}
                            {newsId == 7 && 'Terrible Tornado Time-limited Rate Up Banner'}
                            {newsId == 6 && 'Update Details Feb. 1st, 2024'}
                            {newsId == 5 && 'HEROES ASSEMBLE! ONE PUNCH MAN: WORLD LAUNCHES AS THE SERIES\' FIRST 3D ONLINE ACTION GAME ON PC AND MOBILE'}
                            {newsId == 4 && 'One Punch Man: World Closed Beta Test FAQ'}
                            {newsId == 3 && 'One Punch Man: World Announcement of Pre-Registration and Closed Beta Test'}
                            {newsId == 2 && 'One Punch Man: World Closed Beta Test Application Opens NOW!'}
                            {newsId == 1 && 'One Punch Man: World Playable Demo Comes To Gamescom'}
                            {newsId == 0 && 'One Punch Man: World, a brand new 3D action game of the popular anime is now in development for PC and mobile devices.'}

                        </Typography>

                    </Stack>

                    <Box sx={{
                        height: '2px',
                        bgcolor: '#344158',
                    }} />

                </Stack>

                {/* 内容 */}
                {newsId == 0 && <News0 w={contentWidth} imgTag={imgTag} />}
                {newsId == 1 && <News1 w={contentWidth} imgTag={imgTag} />}
                {newsId == 2 && <News2 w={contentWidth} imgTag={imgTag} />}
                {newsId == 3 && <News3 w={contentWidth} imgTag={imgTag} />}
                {newsId == 4 && <News4 w={contentWidth} imgTag={imgTag} />}
                {newsId == 5 && <News5 w={contentWidth} imgTag={imgTag} />}
                {newsId == 6 && <News6 w={contentWidth} imgTag={imgTag} />}
                {newsId == 7 && <News7 w={contentWidth} imgTag={imgTag} />}
                {newsId == 8 && <News8 w={contentWidth} imgTag={imgTag} />}
                {newsId == 9 && <News9 w={contentWidth} imgTag={imgTag} />}
                {newsId == 10 && <News10 w={contentWidth} imgTag={imgTag} />}
                {newsId == 11 && <News11 w={contentWidth} imgTag={imgTag} />}
                {newsId == 12 && <News12 w={contentWidth} imgTag={imgTag} />}
                {newsId == 13 && <News13 w={contentWidth} imgTag={imgTag} />}
                {newsId == 14 && <News14 w={contentWidth} imgTag={imgTag} />}
                {newsId == 15 && <News15 w={contentWidth} imgTag={imgTag} />}

            </Stack>

            <RightIcon />

            <Stack sx={{
                width: contentWidth + 200,
                justifyContent: 'flex-end',
                display: 'flex',
                flexDirection: 'row',
                position: 'absolute',
                bottom: '30px',
                display: isMobile ? "none" : 'flex',
            }}>

                <Box component={'img'}
                    onClick={topTap}
                    src={window.CDNHost + '/img/top.png'}
                    sx={{
                        cursor: 'pointer',
                        height: '40px',
                        transitionDuration: '0.3s',
                        '&:hover': {
                            transform: 'scale(1.1)',
                            filter: '  brightness(130%)',
                        },
                    }} />

            </Stack>

        </Stack>
    );
}

function News0(props) {
    return (
        <Stack
            className='news_content'
            component={'div'}
            sx={{
                width: props.w,
                fontFamily: '-moz-initial',
            }} >

            <br />
            <h4><em>Publisher A PLUS JAPAN and developer Perfect World will deliver the game to EU and MENA*.</em> <br /></h4>

            <img className={props.imgTag} src={window.CDNHost + '/img/news/news0_icon0.png'} />

            <em>Pre-registration is now open!</em>
            <br />

            <p> <strong><em>One Punch Man: World</em></strong> is a cross-platform 3D action game that recreates the world of the globally popular anime series One-Punch Man and offers a hardcore action experience for PC and mobile!</p>
            <p> <strong>On July 17, A PLUS JAPAN</strong> announced the launch of the game's <a href="https://eu.onepunchmanworld.com/" target="_blank">website</a>. Players are able to pre-register for the PC version. A closed beta test is planned within 2023, and the project is in the works for release this year.</p>
            <br />

            <strong>In <em>One Punch Man: World</em>:</strong>
            <ul>
                <li aria-level="1" dir="ltr">You will be able to experience a hero's everyday life, deal with monsters, and bring them to justice;</li>
                <li aria-level="1" dir="ltr">You can also set up a team of the heroes from the anime, such as Genos and Mumen Rider, level them up, and work your way to the top;</li>
                <li aria-level="1" dir="ltr">In the hardcore action battles, you will perform brilliant combos and wipe out monsters with powerful special moves;</li>
                <li aria-level="1" dir="ltr">Facing enemies that you cannot defeat alone, fight alongside your friends to protect the city together;</li>
                <li aria-level="1" dir="ltr">Aiming to become an experienced hero, players will be exploring different spots where everything is alive;</li>
                <li aria-level="1" dir="ltr">Relive the epic story of Saitama, the man who has already become the most powerful man in the world, in an unexpected way.</li>
            </ul>

            <p> To get abundant pre-registration rewards and further updates, keep an eye on the official <a href="https://eu.onepunchmanworld.com/" target="_blank">website</a> and official social media of <em>One Punch Man: World</em>, which were unveiled today!</p>

            Stay tuned for more information on the upcoming San Diego Comic-Con!
            <br />
            <br />
            <br />
            <strong>Game Details:</strong>
            <br />
            <span><strong>Title: </strong><em>One Punch Man: World</em></span><br />
            <span><strong>Publisher: </strong>A PLUS JAPAN</span><br />
            <span><strong>Developer: </strong>Perfect World</span><br />
            <span><strong>Genre: </strong>Multiplayer Action RPG</span><br />
            <span><strong>Platforms: </strong>Windows PC, iOS, Android</span><br />
            <span><strong>Launch:</strong> 2023</span><br />
            <span><strong>Price:</strong> Free (In-App Purchases)</span><br />
            <br />

            (*)Albania, Algeria, Andorra, Angola, Armenia, Austria, Azerbaijan, Bahrain, Belarus, Belgium, Benin, Bosnia and, Herzegovina, Bulgaria, Burkina, Faso, Burundi, Cameroon, Cape Verde, Central African Republic, Chad, Comoros, C&ocirc;te d&#39;Ivoire, Croatia, Cyprus, Czech Republic, Democratic Republic of the Congo, Republic of the Congo, Djibouti, Egypt, Equatorial Guinea, Estonia, Ethiopia, France, Gabon, Georgia, Germany, Greece, Guinea, Guinea-Bissau, Hungary, Iran, Iraq, Israel, Italy, Jordan, Kazakhstan, Kyrgyzstan, Kosovo, Kuwait, Latvia, Lebanon, Liechtenstein, Lithuania, Luxembourg, Madagascar, Mali, Malta, Mauritania, Moldova, Monaco, Montenegro, Morocco, Mozambique, Netherlands, Niger, Oman, Palestine, Poland, Portugal, Qatar, Romania, Russia, Sao Tome and Principe, Saudi Arabia, Senegal, Serbia, Seychelles, Slovakia, Slovenia, Spain, Switzerland, Syria, Tajikistan, Togo, Tunisia, Turkey, Turkmenistan, Ukraine, United Arab Emirates, Uzbekistan, Western Sahara, and Yemen.<br />
            &nbsp;<br />
            &nbsp;<br />
            <div><strong>About A PLUS JAPAN INC.</strong><br />
                A PLUS JAPAN INC. focuses on Japanese anime-based mobile game development and publishing. They have a solid long-term partnership globally with several anime IPs and broadcast/distribution channels. Under the mission of &ldquo;Sharing joy with the world&rdquo;, they continue to get exciting games derived from anime to players all over the world.</div>
            <br />
            <br />
            <br />
        </Stack>
    )
}

function News5(props) {
    return (
        <Stack
            className='news_content'
            component={'div'}
            sx={{
                width: props.w,
                fontFamily: '-moz-initial',
            }} >

            <Box component={'div'}
                dangerouslySetInnerHTML={{
                    __html: `

                    <img style="width:100%" src='https://game-cdn.aplusjapan-game.com/opmw/img/news/ob_0.png'/>

            <p>Tokyo, Japan (Jan 30th, 2024) &ndash; <em>One Punch Man: World </em>makes its highly anticipated debut on
                <strong>February 1st CET </strong>on PC, Android, and iOS with seamless <strong>cross-play </strong>and shared
                progression! With over 5 million pre-registration, publisher<strong> <a
                        href="https://aplusjapan.co.jp/en/#tdsub">A PLUS JAPAN</a></strong>&nbsp;and developer Perfect World are
                prepared to launch in Europe, the Middle East, Africa, and other regions. The series&rsquo; first 3D Online
                Action GAME will soon meet the <em>One Punch Man</em>&nbsp;fans, and deliver an experience like no other.</p>


                <p>If you are ready to be among the first to get into <em>One Punch Man: World</em>, don&rsquo;t forget to have the
                game preloaded. The pre-download for PC will start from <strong>8 PM Jan. 29th</strong>&nbsp;(CET), and for
                Android and iOS the pre-download will start from <strong>2 AM Jan. 31st</strong>&nbsp;(CET). The server will
                officially go live at <strong>2 AM Feb. 1st</strong>&nbsp;(CET). for Europe, the Middle East, and Africa. Can't
                wait to enter the hero&rsquo;s world? Come and join the hype in the 48-hour countdown site.</p>

                <p><em>One Punch Man: World</em>&nbsp;offers fans of the series a chance to relive the iconic fights and stories
                with a brand new perspective. Fans will enjoy interacting and playing as their favorite characters. In this
                vivid world, players can explore the cities as they like, and get to know more about the characters by
                completing all kinds of side quests. A big roster of heroes and villains is available, and each character has
                unique mechanics that players will gradually get familiar with by enjoying the easy-to-learn, hard-to-master
                fights. The game&rsquo;s multiplayer system also offers a chance for players around the globe to play together
                and take down difficult bosses. A whole new world is here for you to ENFORCE JUSTICE.</p>

                <p>To fully experience the world of <em>One Punch Man </em>characters, it&rsquo;s important to have a proper gaming
                device. Below are the recommendations from the developers. Make sure you have the tools to start your hero
                activity!</p>
                <br/>
                <table style="border-collapse: collapse; width: 100%; height: 473.095px;" border="1">
                <tbody>
                    <tr style="height: 40px; background-color: #FF9C00; color: white;">
                        <td style="width: 50%; height: 40px;"><strong>Minimum Specs</strong></td>
                        <td style="width: 50%; height: 40px;"><strong>Recommended Specs</strong></td>
                    </tr>
                    <tr style="height: 40px;">
                        <td style="width: 50%; height: 40px;"><strong>PC</strong></td>
                        <td style="width: 50%; height: 40px;"><strong>PC</strong></td>
                    </tr>
                    <tr style="height: 40px;">
                        <td style="width: 50%; height: 40px;">Operating system: Windows 7 SP1 64-bit&nbsp;</td>
                        <td style="width: 50%; height: 40px;">Operating system: Windows 10 64-bit<strong><br /></strong>
                        </td>
                    </tr>
                    <tr style="height: 40px;">
                        <td style="width: 50%; height: 40px;">Processor: Intel Core i5 or equivalent</td>
                        <td style="width: 50%; height: 40px;">&nbsp;Processor: Intel Core i7 and above</td>
                    </tr>
                    <tr style="height: 40px;">
                        <td style="width: 50%; height: 40px;">Memory: 8 GB RAM</td>
                        <td style="width: 50%; height: 40px;">Memory: 16 GB RAM</td>
                    </tr>
                    <tr style="height: 40px;">
                        <td style="width: 50%; height: 40px;">Graphics: NVIDIA GeForce GT 1060 3GB&nbsp;</td>
                        <td style="width: 50%; height: 40px;">Graphics: NVIDIA GeForce GT 1060 6GB and above</td>
                    </tr>
                    <tr style="height: 40px;">
                        <td style="width: 50%; height: 40px;">DirectX: Version 11&nbsp;</td>
                        <td style="width: 50%; height: 40px;">DirectX Version: 11</td>
                    </tr>
        
                    <tr style="height: 40px;">
                        <td style="width: 50%; height: 40px;"><strong>Android</strong> &nbsp;</td>
                        <td style="width: 50%; height: 40px;"><strong>Android</strong></td>
                    </tr>
                    <tr style="height: 40px;">
                        <td style="width: 50%; height: 40px;">Memory: More than 64 GB</td>
                        <td style="width: 50%; height: 40px;">CPU: Snapdragon 855, Kirin 990, Dimensity 1000 and above</td>
                    </tr>
                    <tr style="height: 40px;">
                        <td style="width: 50%; height: 40px;">RAM: 3 GB and above</td>
                        <td style="width: 50%; height: 40px;">RAM: 4GB and above</td>
                    </tr>
                    <tr style="height: 40px;">
                        <td style="width: 50%; height: 40px;">CPU: Snapdragon 660, Helio X25, Kirin 950, Exynos 8890 or
                            equivalent&nbsp;</td>
                        <td style="width: 50%; height: 40px;"><strong>iOS</strong></td>
                    </tr>
                    <tr style="height: 40px;">
                        <td style="width: 50%; height: 40px;">Support 64-bit architectures</td>
                        <td style="width: 50%; height: 40px;">&nbsp;iPhone: iPhoneXS, iPhoneXR and above&nbsp;</td>
                    </tr>
                    <tr style="height: 47.5938px;">
                        <td style="width: 50%; height: 47.5938px;">
                            <p>System version: Android 7 and above</p>
                        </td>
                        <td style="width: 50%; height: 47.5938px;">iPad: Devices with Apple A12 and up processors (devices
                            launched later than October 2018)</td>
                    </tr>
                    <tr style="height: 47.5938px;">
                        <td style="width: 50%; height: 47.5938px;"><strong>iOS</strong></td>
                        <td style="width: 50%; height: 47.5938px;">
                            <p>*System version needs to be iOS12 and above. Apple Mac is currently not supported.</p>
                        </td>
                    </tr>
                    <tr style="height: 47.5938px;">
                        <td style="width: 50%; height: 47.5938px;">
                            <p>iPhone: iPhone8P, iPhoneX and above</p>
                        </td>
                        <td style="width: 50%; height: 47.5938px;">
                            <p>&nbsp;</p>
                        </td>
                    </tr>
                    <tr style="height: 47.5938px;">
                        <td style="width: 50%; height: 47.5938px;" width="198">
                            <p>iPad: Devices with Apple A11 and up processors</p>
                        </td>
                        <td style="width: 50%; height: 47.5938px;">
                            <p>&nbsp;</p>
                        </td>
                    </tr>
                    <tr style="height: 47.5938px;">
                        <td style="width: 50%; height: 47.5938px;" width="198">
                            <p>*System version needs to be iOS12 and above. Apple Mac is currently not supported.</p>
                        </td>
                        <td style="width: 50%; height: 47.5938px;">&nbsp;</td>
                    </tr>
                </tbody>
            </table>
            <p>&nbsp;</p>
            <p><strong>Game Details: </strong></p>
            <p>Title: One Punch Man: World</p>
            <p>Publisher: A Plus Japan</p>
            <p>Developer: Perfect World</p>
            <p>Genre: Online Action</p>
            <p>Platforms: Windows PC, iOS, Android</p>
            <p>Launch: February 1</p>
            <p>Price: Free (In-App Purchases)</p>
            <p><strong>&nbsp;</strong></p>
            <p><strong>Community</strong><strong>：</strong></p>
            <p>Official: <a href="https://eu.onepunchmanworld.com/#tdsub"><u>https://eu.onepunchmanworld.com/</u></a></p>
            <p>Twitter: &nbsp;&nbsp;&nbsp;&nbsp;<a
                    href="https://twitter.com/onepunchmanw_eu#tdsub"><u>https://twitter.com/onepunchmanw_eu</u></a></p>
            <p>Facebook: &nbsp;&nbsp;&nbsp;&nbsp;<a
                    href="https://www.facebook.com/onepunchmanworldEUMENA#tdsub"><u>https://www.facebook.com/onepunchmanworldEUMENA</u></a>
            </p>
            <p>Discord: &nbsp;&nbsp;&nbsp;<a href="https://discord.gg/one-punch-man-world"><u>https://discord.gg/one-punch-man-world</u></a>
            </p>
            <p>&nbsp;</p>
            <p>(*)Albania, Algeria, Andorra, Angola, Armenia, Austria, Azerbaijan, Bahrain, Belarus, Belgium, Benin, Bosnia and
                Herzegovina, Bulgaria, Burkina Faso, Burundi, Cameroon, Cape Verde, Central African Republic, Chad, Comoros,
                C&ocirc;te d'Ivoire, Croatia, Cyprus, Czech Republic, Democratic Republic of the Congo, Republic of the Congo,
                Djibouti, Egypt, Equatorial Guinea, Estonia, Ethiopia, France, Gabon, Georgia, Germany, Greece, Guinea,
                Guinea-Bissau, Hungary, Iran, Iraq, Israel, Italy, Jordan, Kazakhstan, Kyrgyzstan, Kosovo, Kuwait, Latvia,
                Lebanon, Liechtenstein, Lithuania, Luxembourg, Madagascar, Mali, Malta, Mauritania, Moldova, Monaco, Montenegro,
                Morocco, Mozambique, Netherlands, Niger, Oman, Palestine, Poland, Portugal, Qatar, Romania, Russia, Sao Tome and
                Principe, Saudi Arabia, Senegal, Serbia, Seychelles, Slovakia, Slovenia, Spain, Switzerland, Syria, Tajikistan,
                Togo, Tunisia, Turkey, Turkmenistan, Ukraine, United Arab Emirates, Uzbekistan, Western Sahara, Yemen</p>
            <p>&nbsp;</p>
            <p>About A PLUS JAPAN INC.</p>
            <p>A PLUS JAPAN INC. is a mobile game publisher with its headquarters in Tokyo and an office in Shanghai.</p>
            <p>The company&rsquo;s mission is to bring fun and surprise to the world by combining its globally influential IP
                titles with its mobile game development ability to deliver compelling content to the world.</p>
            <p>With its strong presence in publishing high-quality and innovative games, A PLUS JAPAN expects to expand beyond
                its publishing area with more upcoming titles and a new branch office in Singapore.</p>
                <br/>
                <br/>
                <br/>

                    ` }}
                sx={{
                    mt: 2,

                }} />

        </Stack>
    )
}


function News1(props) {
    return (
        <Stack
            className='news_content'
            component={'div'}
            sx={{
                width: props.w,
                fontFamily: '-moz-initial',
            }} >

            <br />
            <img className={props.imgTag} src={window.CDNHost + '/img/news/news1_icon0.png'} />

            <p><strong>Tokyo, Japan (Aug. 15, 2023) -</strong> From August 23rd to 27th, <a href="https://aplusjapan.co.jp/en/" target="_blank">A PLUS JAPAN</a>, a worldwide publisher of hit anime games, and <a href="https://www.crunchyroll.com/games/" target="_blank">Crunchyroll Games</a>, the interactive division of the global leader in anime, will join forces at <strong>Gamescom</strong> in Cologne, Germany to give event goers an early hands-on preview of the highly anticipated online action game <strong><em>One Punch Man: World</em></strong>. Located at <strong>Stand B-031 </strong>in <strong>Hall 6</strong>, Gamescom visitors will be among the first in the world to experience the English demo of <em>One Punch Man: World</em> and have the chance to take on heroic challenges to win themed prizes.</p>


            <img className={props.imgTag} src={window.CDNHost + '/img/news/news1_icon1.png'} />
            <br />

            <span>Let's have a closer look at the events:</span>
            <ul>
                <li><strong>Guidebook:</strong> Start your journey here! Come to the <em>One Punch Man: World </em>booth, follow the social media accounts and get a guidebook from the staff!</li>
                <li><strong>The Hero&#8217;s Journey: </strong>Take on all the activities below and collect special stamps for each to win fun prizes and swag!</li>
                <li><strong>Game Demo:</strong> Be some of the first players in the world to try the English demo of <em>One Punch Man: World</em>! It&#8217;s your turn to become a hero for fun!</li>
                <li><strong>Punching Machine: </strong>How strong is your &#8220;one punch&#8221;? Come to the punching machine and challenge the record!</li>
                <li><strong>Photo Op:</strong> Pose with One-Punch Man himself—Saitama, and take a great shot!</li>
                <li><strong>Prizes: </strong>Show off your completed guide book for prizes, including themed hand fans, masks, hairpins, and mouse mats. (Limited supplies, First-come First-Served Basis!)</li>
            </ul>

            <img className={props.imgTag} src={window.CDNHost + '/img/news/news1_icon2.png'} />

            <p> <em>One Punch Man: World </em>is an online action game that recreates the world of the globally popular anime series <em>One-Punch Man</em> and offers a hardcore action experience for PC and mobile! The game will be available cross-platform on App Store, Google Play, and PC with language support in English, French, German, Spanish, and Portuguese. Pre-registration for PC is now open on the official website.</p>
            <p>For news and further updates on the game, keep an eye on the <em>One Punch Man: World</em> official website and social media pages:</p>


            <br />
            <p><strong>A PLUS JAPAN Published Regions (*1): </strong><br /> </p>
            <span> Official Website:    <a href="https://eu.onepunchmanworld.com/" target="_blank">https://eu.onepunchmanworld.com/</a></span>
            <span> Twitter:                <a href="https://twitter.com/onepunchmanw_eu" target="_blank" >https://twitter.com/onepunchmanw_eu</a></span>
            <span> Facebook:           <a href="https://www.facebook.com/onepunchmanworldEUMENA" target="_blank" >https://www.facebook.com/onepunchmanworldEUMENA</a></span>
            <span>  Discord:              <a href="https://discord.gg/one-punch-man-world" target="_blank" >https://discord.gg/one-punch-man-world</a></span>
            <br />
            <br />

            <strong>Crunchyroll Games Published Regions (*2):</strong><br />
            <span>  Official Website:    <a href="https://www.onepunchmanworld.com/" target="_blank">https://www.onepunchmanworld.com/</a><br /></span>
            <span> Twitter:                <a href="https://twitter.com/onepunchman_w" target="_blank" >https://twitter.com/onepunchman_w</a><br /></span>
            <span> Facebook:           <a href="https://www.facebook.com/onepunchmanworld/" target="_blank" >https://www.facebook.com/onepunchmanworld/</a><br /></span>
            <span> Discord:              <a href="https://discord.gg/one-punch-man-world" target="_blank" >https://discord.gg/one-punch-man-world</a></span>
            <br />

            <p> (*1) A PLUS JAPAN INC. publishes <em>One Punch Man: World </em>in most European countries and the Middle East.<br /><br />
                (*2) Crunchyroll Games publishes <em>One Punch Man: World </em>in the U.S., Canada, United Kingdom, Ireland, Norway, Sweden, Finland, Denmark, Iceland, Australia, New Zealand, and Latin America.</p>
            <p ><strong>About A PLUS JAPAN INC.</strong><br />
                A PLUS JAPAN INC. focuses on Japanese anime-based mobile game development and publishing. They have a solid long-term partnership globally with several anime IPs and broadcast/distribution channels. Under the mission of &#8220;Sharing joy with the world&#8221;, they continue to get exciting games derived from anime to players all over the world.</p>

            <p><strong>About Crunchyroll</strong><br />
                <a href="https://www.crunchyroll.com/" target="_blank" rel="noopener noreferrer">Crunchyroll</a> connects anime and manga fans across 200+ countries and territories with the content and experiences they love. In addition to free ad-supported and subscription premium content, Crunchyroll serves the anime community across events, theatrical, games, consumer products, collectibles and manga publishing.</p>
            <p>Anime fans have access to one of the largest collections of licensed anime through Crunchyroll and translated in multiple languages for viewers worldwide. Viewers can also access simulcasts — top series available immediately after Japanese broadcast.</p>
            <p>The Crunchyroll app is available on over 15 platforms, including all gaming consoles.</p>
            <p>Crunchyroll, LLC is an independently operated joint venture between US-based Sony Pictures Entertainment, and Japan&#8217;s Aniplex, a subsidiary of Sony Music Entertainment (Japan) Inc., both subsidiaries of Tokyo-based Sony Group.</p>

            <br />
            <br />
            <br />
        </Stack >
    )
}



function News2(props) {
    return (
        <Stack
            className='news_content'
            component={'div'}
            sx={{
                width: props.w,
                fontFamily: '-moz-initial',
            }} >

            <p >
                <span >One Punch Man: World will start the first closed beta test on October 18th. We welcome all who are interested in participating in this test as the application for entry starts today! Please check the following for the entry application:</span>
            </p>

            <img className={props.imgTag} src={window.CDNHost + '/img/news/news2_icon0.png'} />

            <p >
                <strong><span >APPLICATION</span></strong>
            </p>

            <p >
                <strong><span >Recruiting Period:</span></strong> <span >From now to 2023/10/11 8:00 (GMT+2)</span>
            </p>
            <p >
                <strong><span >How To Apply:</span></strong><span >&nbsp;</span> <span >Fill the survey via the link below</span>
            </p>
            <p >
                <strong><span >Survey Link:</span></strong> <a >One Punch Man: World - Closed Beta Test Registration 【Already Over】</a>
            </p>
            <p >
                <span >We will notify qualified participants by email from 2023/10/13. Please make sure to fill out the survey as accurately as you can because mistakes may disqualify your entry.</span>
            </p>

            <p >
                <strong><span >TEST OVERVIEW</span></strong>
            </p>

            <p >
                <strong><span >Special note:</span></strong> <span >Limited amount of qualified entries / non-payment / accounts will be deleted after the test ends.</span>
            </p>
            <p >
                <strong><span >Platform:</span></strong> <span >PC and Android</span>
            </p>

            <p >
                <strong><span >Pre-download:</span></strong> <span >Available from 2023/10/16 16:00 (GMT+2)</span>
            </p>
            <p >
                <strong><span >Test Period / Server Open:</span></strong> <span >2023/10/18 14:00 - 2023/10/26 3:59 (GMT+2)</span>
            </p>

            <p >
                <strong><span >PLEASE NOTE:</span></strong>
            </p>

            <p >
                <span >1. All content of this test is still in development stages which does not represent the final quality of the game. The development team will continuously improve and adjust the gaming experience.</span>
            </p>
            <p >
                <span >2. We will send the activation code to qualified participants for test entry via email after the recruitment ends.</span>
            </p>
            <p >
                <span >3. This test prohibits account trading or sharing. Such behavior will lead to immediate disqualification once found. Do not believe any information related to beta test account transactions.</span>
            </p>

            <p >
                <span >Follow our official social media accounts for more information!</span>
            </p>

            <p >
                <strong><span >Facebook: </span></strong><span >&nbsp;</span>
                <a target='_blank' href="https://www.facebook.com/onepunchmanworld"><span ><span>https://www.facebook.com/onepunchmanworld</span></span></a>
            </p>

            <p >
                <strong><span >X (Twitter): </span></strong>

                <a target='_blank' href="https://twitter.com/onepunchmanw_eu"><span ><span>https://twitter.com/onepunchmanw_eu</span></span></a>
            </p>

            <p >
                <strong><span >Discord: </span></strong>
                <a target='_blank' href="https://discord.gg/one-punch-man-world"><span ><span >https://discord.gg/one-punch-man-world</span></span></a>
            </p>

            <p >
                <span >Thanks for your love and support! See you soon in One Punch Man: World!</span>
            </p>

            <br />
            <br />
            <br />


        </Stack>
    )
}


function News3(props) {
    return (
        <Stack
            className='news_content'
            component={'div'}
            sx={{
                width: props.w,
                fontFamily: '-moz-initial',
            }} >

            <img className={props.imgTag} src={window.CDNHost + '/img/news/news3_icon0.png'} />

            <p>Tokyo, Japan (Oct. 3rd, 2023) &ndash; Publisher A PLUS JAPAN and Crunchyroll Games announced the pre-registration of the new action game One Punch Man: World for mobile platforms. Players can now pre-register on Google Play and App Store for pre-registration rewards.</p>

            <p>The game&rsquo;s first closed beta test is also announced and scheduled between Oct. 18th - 25th. Players may apply for a chance to become a tester from Oct. 3rd by filling out a signup form.</p>

            <img className={props.imgTag} src={window.CDNHost + '/img/news/news3_icon1.png'} />

            <p>In this round of beta test, testers will experience the world of <em>One-Punch Man</em>&nbsp;like never before:</p>

            <ul>
                <li><strong>Encounter the Iconic Heroes:</strong>Play as beloved heroes such as Genos, Mumen Rider, Silverfang&hellip; and the protagonist Saitama? He will also be playable in an unexpected way!</li>
                <li><strong>Experience the Stories: </strong>In an action game way! Feel the heroes' every punch and kick on your palms, and join the legendary battles yourself! And what if we switch sides, and have a perspective of the villains, like breaking out as Carnage Kabuto?</li>
                <li><strong>Enjoy the Fight: </strong><strong> </strong> Parry the enemy&rsquo;s attacks, complete different combos, accumulate power, unleash ultimate skills, and finish bosses as you like! This hardcore 3D action game will give you a sense of battle like never before!</li>
                <li><strong>Enforce Justice:</strong> Complete the tasks assigned by the Hero Association, defend the city with fellow heroes, and become stronger!</li>
                <li><strong>Explore the World:</strong> Experience a hero&rsquo;s everyday life, indulge in games at the Arcade Center or join forces with Saitama during a discount frenzy at the supermarket!</li>
            </ul>

            <p>Ready to enter the world of heroes? Pre-registration is now available on Google Play and App Store. Please find the links down below.</p>
            <p>If you also want to become a tester for the closed beta test, please follow these simple steps:</p>

            <p><strong>APPLICATION</strong></p>
            <p><strong>Schedule:</strong> From now to 3:59, Oct. 11th, 2023 CEST</p>
            <p><strong>How To Apply:</strong>&nbsp; Complete the survey for beta test, and leave an email to receive the results.</p>
            <p><strong>How To Check the Admission:</strong>&nbsp;All the participants who get the entry will receive an activation code by email from Oct. 13th. Therefore, please make sure that the email address is correct when filling out the survey.</p>

            <p><strong>TEST OVERVIEW</strong></p>
            <p><strong>What's Available:</strong> Limited entry with no in-game purchase. Demo accounts will be deleted after the test.</p>
            <p><strong>Language:</strong><strong> </strong><strong> </strong>English</p>
            <p><strong>Platform:</strong> PC and Android</p>
            <p><strong>Available Time:</strong> 14:00 Oct. 18th - 3:59 Oct. 26th, 2023 CEST</p>
            <p><strong>Pre-download:</strong> Available from 16:00 Oct. 16th, 2023 CEST</p>

            <p>One Punch Man: World is an 3D action game that recreates the world of the globally popular anime series <em>One-Punch Man</em>&nbsp;and offers a hardcore action experience for PC and mobile.</p>
            <p>The game will be available cross-platform on App Store, Google Play, and PC with language support in English, French, German, Spanish, and Portuguese. Pre-registration is now available on Google Play, App Store, and the One Punch Man: World official website.</p>
            <p>For news and further updates on the game, keep an eye on the official website and official social media of "One-Punch Man: World":</p>
            <p>For European and MENA regions: </p>
            <p>Google Play:&nbsp;<a target='_blank' href="https://play.google.com/store/apps/details?id=com.opmw.eu"><u>https://play.google.com/store/apps/details?id=com.opmw.eu</u></a></p>
            <p>App Store:&nbsp;<a target='_blank' href="https://itunes.apple.com/app/id6446193358"><u>https://itunes.apple.com/app/id6446193358</u></a></p>
            <p>Official Website:<a target='_blank' href="https://eu.onepunchmanworld.com/">&nbsp;https://eu.onepunchmanworld.com/</a></p>
            <p>Twitter: <a target='_blank' href="https://twitter.com/onepunchmanw_eu">https://twitter.com/onepunchmanw_eu</a></p>
            <p>Facebook: <a target='_blank' href="https://www.facebook.com/onepunchmanworldEUMENA">https://www.facebook.com/onepunchmanworldEUMENA</a></p>
            <p>Discord: <a target='_blank' href="https://discord.gg/one-punch-man-world">https://discord.gg/one-punch-man-world</a></p>

            <br />
            <p> For North and South American regions:</p>
            {/* <p>Google Play:&nbsp;<a target='_blank' href="https://play.google.com/store/apps/details?id=com.crunchyroll.opm"><u>https://play.google.com/store/apps/details?id=com.crunchyroll.opm</u></a></p> */}
            <p>Google Play:&nbsp;coming soon</p>
            <p>App Store:&nbsp;<a target='_blank' href="https://apps.apple.com/us/app/one-punch-man-world/id6448791649"><u>https://apps.apple.com/us/app/one-punch-man-world/id6448791649</u></a></p>
            <p>Official Website: <a target='_blank' href="https://www.onepunchmanworld.com/">&nbsp;https://www.onepunchmanworld.com/</a></p>
            <p>Twitter: <a target='_blank' href="https://twitter.com/onepunchman_w">https://twitter.com/onepunchman_w</a></p>
            <p>Facebook: <a target='_blank' href="https://www.facebook.com/onepunchmanworld/">https://www.facebook.com/onepunchmanworld/</a></p>
            <p>Discord: <a target='_blank' href="https://discord.gg/one-punch-man-world">https://discord.gg/one-punch-man-world</a></p>

            <br />
            <br />
            <p><strong><u>About A PLUS JAPAN INC.</u></strong></p>
            <p><a target='_blank' href="https://aplusjapan.co.jp/en/"><u>A PLUS JAPAN INC.</u></a>&nbsp;is a mobile game publisher with its headquarters in Tokyo and an office in Shanghai.</p>
            <p>The company&rsquo;s mission is to bring fun and surprise to the world by combining its globally influential IP titles with its mobile game development ability to deliver compelling content to the world.</p>
            <p>With its strong presence in publishing high-quality and innovative games, A PLUS JAPAN expects to expand beyond its publishing area with more upcoming titles and a new branch office in Singapore.</p>
            <p>For more information, please visit <a target='_blank' href="https://aplusjapan.co.jp/"><u>https://aplusjapan.co.jp/</u></a>&nbsp;</p>

            <br />
            <br />
            <p><strong><u>About Crunchyroll</u></strong></p>
            <p><a target='_blank' href="https://www.crunchyroll.com/">Crunchyroll</a>&nbsp;connects anime and manga fans across 200+ countries and territories with the content and experiences they love. In addition to free ad-supported and subscription premium content, Crunchyroll serves the anime community across events, theatrical, games, consumer products, collectibles and manga publishing.</p>

            <p>Anime fans have access to one of the largest collections of licensed anime through Crunchyroll and translated in multiple languages for viewers worldwide. Viewers can also access simulcasts &mdash; top series available immediately after Japanese broadcast.</p>
            <p>The Crunchyroll app is available on over 15 platforms, including all gaming consoles.</p>
            <p>Crunchyroll, LLC is an independently operated joint venture between US-based Sony Pictures Entertainment, and Japan&rsquo;s Aniplex, a subsidiary of Sony Music Entertainment (Japan) Inc., both subsidiaries of Tokyo-based Sony Group.</p>
            <br />
            <br />
            <br />
        </Stack>
    )
}

function News4(props) {
    return (
        <Stack
            className='news_content'
            component={'div'}
            sx={{
                width: props.w,
                fontFamily: '-moz-initial',
            }} >


            <p>Pre-download is available for One Punch Man: World's first closed beta test in EU and US. Qualified participants who received the activation code can download the game now via the link below, and enter the game tomorrow after the server opens!</p>
            <img className={props.imgTag} src={window.CDNHost + '/img/news/news4_icon0.png'} />

            <p><strong>Q: What can I expect to see in this game?</strong></p>
            <p>A: -Encounter the Iconic Heroes: Play as beloved heroes such as Genos, Mumen Rider, Silverfang&hellip; and the protagonist Saitama? He will also be playable in an unexpected way!</p>
            <p>-Experience the Stories: &nbsp;In an action game way! Feel the heroes' every punch and kick on your palms, and join the legendary battles yourself! And what if we switch sides, and have a perspective of the villains, like breaking out as Carnage Kabuto?</p>
            <p>-Enjoy the Fight: Parry the enemy&rsquo;s attacks, complete different combos, accumulate power, unleash ultimate skills, and finish bosses as you like! This hardcore 3D action game will give you a sense of battle like never before!</p>
            <p>-Enforce Justice: Complete the tasks assigned by the Hero Association, defend the city with fellow heroes, and become stronger!</p>
            <p>-Explore the World: Experience a hero&rsquo;s everyday life, indulge in games at the Arcade Center or join forces with Saitama during a discount frenzy at the supermarket!</p>
            <p>&nbsp;</p>
            <p><strong>Q: How do I know if I am qualified for the beta test?</strong></p>
            <p>A: We will notify you through the email address you fill in the closed beta test survey. If you receive the qualification email and the activation code of the current test, you can enter the game when the closed beta test begins. Please check your email spam box as well to make sure that you do not miss the qualification email.</p>
            <p>※ This test prohibits account trading or sharing. Such behavior will lead to immediate disqualification once found. Do not believe any information related to beta test account transactions.</p>
            <p>&nbsp;</p>
            <p><strong>Q: I have received the activation code, what should I do next?</strong></p>
            <p>A: You can download the game via the links in the email.</p>
            <p>&nbsp;</p>
            <p><strong>Q: When can I pre-download the game?</strong></p>
            <p>A: 2023/10/16 16:00 (GMT+2)</p>
            <p>&nbsp;</p>
            <p><strong>Q: When will the beta test begin?</strong></p>
            <p>A:&nbsp;2023/10/18 14:00 (GMT+2)</p>
            <p>&nbsp;</p>
            <p><strong>Q： What platforms are available for this test? How do I log in?</strong></p>
            <p>A: This test is open for PC and Android. After downloading the game, you need to register an account and redeem your activation code before entering the test.</p>
            <p>&nbsp;</p>
            <p><strong>Q： Will in-game purchases be available for this test? Will my account be deleted after the test?</strong></p>
            <p>A: In-game purchases are <strong>NOT</strong>&nbsp;available for this test. All player accounts will be deleted after the test ends</p>
            <p>&nbsp;</p>
            <p><strong>Q: What is the minimum requirement for PC?</strong></p>
            <p>A: Operating system: Windows 10 64-bit</p>
            <p>Processor: Intel Core i5 or equivalent processor</p>
            <p>Memory: 8 GB RAM</p>
            <p>Graphics: NVIDIA GeForce GT 1060 3GB or equivalent</p>
            <p>DirectX: Version 11</p>
            <p>Storage: 10 GB or more free space</p>
            <p>Please note that devices lower than the requirements will likely not be able to run the game properly.</p>
            <p>&nbsp;</p>
            <p><strong>Q: What are the minimum requirements for Android?</strong></p>
            <p>A: CPU: Snapdragon 670, Helio X27 and above</p>
            <p>RAM: 3GB and above</p>
            <p>System version: Android 7.0 and above</p>
            <p>Storage: 10 GB disk space reserved</p>
            <p>Please note that devices lower than the requirements will likely not be able to run the game properly.</p>
            <p>&nbsp;</p>
            <p><strong>Q: Any social media accounts to follow?</strong></p>
            <p>A: Currently, we have official Facebook, X (Twitter) and Discord.</p>
            <p><strong>Facebook: </strong><a href="https://www.facebook.com/onepunchmanworld">https://www.facebook.com/onepunchmanworld</a></p>
            <p><strong>X (Twitter): </strong><a href="https://twitter.com/onepunchmanw_eu">https://twitter.com/onepunchmanw_eu</a></p>
            <p><strong>Discord: </strong><a href="https://discord.gg/one-punch-man-world">https://discord.gg/one-punch-man-world</a></p>

            <br />
            <br />
            <br />
        </Stack>
    )
}



function News6(props) {

    return (
        <Stack
            className='news_content'
            component={'div'}
            sx={{
                width: props.w,
                fontFamily: '-moz-initial',
            }} >

            <Box component={'div'}
                dangerouslySetInnerHTML={{
                    __html: `

            <p>Hello Heroes,</p>
            <p>We have updated the game with the following changes to improve your gaming experience!</p>
            <div style="margin-left: 30px;">
                    <p>1. Added a Skip button to the world introduction video!</p>

                    <p>2. Added red notification badges to certain easy-to-miss buttons in the menu!</p>

                    <p>3. Optimized the Photo modes to allow for easier completion of camera related missions!</p>
            </div>
            <p>Thank you for supporting the game, and we hope you will continue to enjoy One Punch Man: World!</p>
            <p style="text-align: right;">-- One Punch Man: World Team</p>
       
            ` }}
                sx={{
                    mt: 2,

                }} />

        </Stack>
    )
}

function News7(props) {

    return (
        <Stack
            className='news_content'
            component={'div'}
            sx={{
                width: props.w,
                fontFamily: '-moz-initial',
            }} >

            <Box component={'div'}
                dangerouslySetInnerHTML={{
                    __html: `

                    <p>In respect of her terrifying abilities, the Association added "Terror" to her hero name&hellip;Rate-up banner for SSR Character: Terrible Tornado is now available!</p>
                  
                    <p><strong>►Event Duration:</strong></p>
                    <p>Maintenance finished- 2:59 AM February 22nd  (CET)</p>
                    <p>&nbsp;</p>
                  
                    <p><strong>►Event Description:</strong></p>
                    <p>In the Rate Up Banner, when you obtain an SSR character by Draw, there is a <strong>50% chance</strong>&nbsp;it will be the <strong>UP character [Terrible Tornado]</strong>. If the SSR character obtained in this Draw is not the UP character, <strong>your next SSR character </strong>obtained by Draw is <strong>guaranteed</strong>&nbsp;to be the UP character.</p>
                    
                         <p><video controls="controls" width="100%" autoplay="autoplay" muted="muted" poster="https://game-cdn.aplusjapan-game.com/opmw/img/news/ljb_rx.jpeg">
                         <source src="https://game-cdn.aplusjapan-game.com/opmw/img/news/ljb_rx.mp4" type="video/mp4" /></video></p>

                    <p>&nbsp;</p>
                    <p><strong>►Character Showcase</strong></p>
                    <p>Play as Tornado, it's crucial to cast the skills in the right order and get her into the powered-up state, so she can finish off the enemy more efficiently.</p>

                              <p><video controls="controls" width="100%" autoplay="autoplay" muted="muted" poster="https://game-cdn.aplusjapan-game.com/opmw/img/news/ljb_jn.jpeg">
                                 <source src="https://game-cdn.aplusjapan-game.com/opmw/img/news/ljb_jn.mp4" type="video/mp4" /></video></p>

                    <p>Although she is small in stature, one can only look up to her because the strongest esper deserves to fly above the rest! Ready to play as Terrible Tornado in the World? Go try your luck in the Rate Up banner!</p>

                    <p>Also, special deals for Limited Draw Tickets are available! Remember to check them out!</p>
                    <p>&nbsp;</p>
                    <p style="text-align: right;">-- One Punch Man: World Team</p>

       
            ` }}
                sx={{
                    mt: 2,
                    mb: 15,
                }} />

        </Stack>
    )
}



function News8(props) {

    return (
        <Stack
            className='news_content'
            component={'div'}
            sx={{
                width: props.w,
                fontFamily: '-moz-initial',
            }} >

            <Box component={'div'}
                dangerouslySetInnerHTML={{
                    __html: `

                    <p><em>Play as Saitama-sensei himself, and enjoy hot-blooded fights in the dreamworld!</em></p>
                  
                     <img src="https://game-cdn.aplusjapan-game.com/opmw/img/news/gt.png"/>
                  
                    <p>Feb. 15<sup>th</sup>, 2024 &ndash; Starting today, the rate up banner for SSR character Saitama - Dreamworld is available for a limited time! It&rsquo;s your time to play as the strongest man in the world, and feel every punch in his dream fight on your fingertips!</p>

                    <p><strong>Event Duration: </br>
                    Feb. 15<sup>th</sup>&nbsp;after maintenance &ndash; 0:00 Feb. 29<sup>th</sup>, 2024 (UTC-2) </strong></p>

                    <p>Unlike the real world where he has become too strong and finishes all monsters with a single punch, Saitama can finally enjoy a good fight in his dream. Let&rsquo;s see some highlights of his skills:</p>
                   
                   
                    <p><video controls="controls" width="100%" autoplay="autoplay" muted="muted" poster="https://game-cdn.aplusjapan-game.com/opmw/img/news/gt.png">
                    <source src="https://game-cdn.aplusjapan-game.com/opmw/img/news/gt_video.mp4" type="video/mp4" /></video></p>

                    <p>Combining dodges and normal attacks, Saitama Dreamworld can use a variety of different combos to generate enough energy, and power up his moves.</p>

                    <p><strong>Also, don&rsquo;t miss other special events that are available in the meantime like the Valentine&rsquo;s Day event &ldquo;Sweet Support&rdquo;! Some special deals are newly added that let you get 5 Normal Draw Tickets with only 6 World Golds. Don&rsquo;t forget to check them out and get your dream hero! </strong></p>
       
            ` }}
                sx={{
                    mt: 2,
                    mb: 15,
                }} />

        </Stack>
    )
}

function News9(props) {

    return (
        <Stack
            className='news_content'
            component={'div'}
            sx={{
                width: props.w,
                fontFamily: '-moz-initial',
            }} >

            <Box component={'div'}
                dangerouslySetInnerHTML={{
                    __html: `
                    <h1 style="text-align: center;">New Hero: Atomic Samurai&nbsp;</h1>
                    <h3 style="text-align: center;">A swing of his katana can even break an atom!</h3>
                    <p><strong>Feb. 29th, 2024</strong> &ndash; Limited <strong>SSR</strong> character <strong>Atomic Samurai</strong> joins the game! Rate up banners for this limited character and his Impression arms are available for two weeks! Are you ready to swing your katana like this master swordsman? Remember, Atomic Samurai will only acknowledge those who are strong enough!&nbsp;</p>
                   
                    <p><img style="display: block; margin-left: auto; margin-right: auto;" src="https://game-cdn.aplusjapan-game.com/opmw/img/news/yzws_2.png" alt="" /></p>

                    <p><strong>Event Duration:</strong><br /><strong>Feb. 29th after maintenance &ndash; 0:00 Mar. 13th, 2024 (UTC-2)</strong></p>
                    <p>* Limited characters will no longer be obtainable from any Draw until their banner returns at a later date.</p>
                    <p>As a master swordsman, Atomic Samurai is both brave and calm. The samurai observe the enemy moves carefully to find their weakness and strive with slashes to end the opponent. Let&rsquo;s see how he does his moves:&nbsp;</p>

                    <p><video controls="controls" width="100%" autoplay="autoplay" muted="muted" poster="https://game-cdn.aplusjapan-game.com/opmw/img/news/yzws_2.png">
                    <source src="https://game-cdn.aplusjapan-game.com/opmw/img/news/yzws.mp4" type="video/mp4" /></video></p>
                  
                    <p>The two combat styles let the samurai attack swiftly and counterattack to deal great damage. Switching between Batto and Iaido Forms, and inflicting Weakness Marks on the enemy are the keys to playing this unit well.&nbsp;</p>
                    <p><strong>Also, don't miss special deals Atomic Samurai in the shop including packs of Draw Tickets, Impression Arms, Wills, &nbsp;and more. Packs for the Spring events are also coming so don&rsquo;t miss the chance to power up your heroes!</strong></p>
                  
            ` }}
                sx={{
                    mt: 2,
                    mb: 15,
                }} />

        </Stack>
    )
}

function News10(props) {

    return (
        <Stack
            className='news_content'
            component={'div'}
            sx={{
                width: props.w,
                fontFamily: '-moz-initial',
            }} >

            <Box component={'div'}
                dangerouslySetInnerHTML={{
                    __html: `
                    <h1 style="text-align: center;">&nbsp;Rate Up Banner: Hellish Blizzard&nbsp;</h1>
                    <h3 style="text-align: center;">The noble queen who reigns over her black legion...&nbsp;</h3>
                   
                    <p><img style="display: block; margin-left: auto; margin-right: auto;" src="https://game-cdn.aplusjapan-game.com/opmw/img/news/lj_2_new_2.png" alt="" /></p>


                    <p><strong>Mar. 7th, 2024</strong> &ndash; Starting today, the rate up banner for <strong>SSR</strong> character <strong>Hellish Blizzard</strong> and her Impression Arms are available for two weeks! If you haven&rsquo;t tried to play as the formidable leader of &ldquo;Blizzard Group&rdquo; yet, your chance is now! Wield the esper power, and show the monsters the real inferno!</p>
                    <p><strong>Event Duration:</strong><br /><strong>Mar. 7th after maintenance &ndash; 0:00 Mar. 20th, 2024 (UTC-2)</strong></p>
                    <p>As the younger sister of the No. 2 hero Tornado, Hellish Blizzard is also an absolute expert in controlling Telekinesis power. When she attacks, a flickering mark will appear on her. That&rsquo;s the chance to attack, dodge, or counter-attack to deal great damage! Wanna know how to do it? Let&rsquo;s take a closer look at how she elegantly enhances her skills:&nbsp;</p>

                    <p><video controls="controls" width="100%" autoplay="autoplay" muted="muted" poster="https://game-cdn.aplusjapan-game.com/opmw/img/news/lj_2_new_2.png">
                    <source src="https://game-cdn.aplusjapan-game.com/opmw/img/news/lj_2_video.mp4" type="video/mp4" /></video></p>

                    <p>Play as Blizzard, players will need to seize as much flickering chance as possible, and cast the enhanced skills. Once her energy is maxed out, use the ultimate to finish the opponent. Blizzard will call forth a storm that will sweep across all enemies. As the enemies fall into the infernal of the howling winds, they will recall how Hellish Blizzard earned her hero name.&nbsp;</p>
                    <p><strong>To take Blizzard to the next level, you won't want to miss the special deals in the shop to power her up! Spring special events are also coming, so let&rsquo;s go save the world with our favorite heroes, and win lots of rewards!</strong></p>
                    
                  
            ` }}
                sx={{
                    mt: 2,
                    mb: 15,
                }} />

        </Stack>
    )
}

function News11(props) {

    return (
        <Stack
            className='news_content'
            component={'div'}
            sx={{
                width: props.w,
                fontFamily: '-moz-initial',
            }} >

            <Box component={'div'}
                dangerouslySetInnerHTML={{
                    __html: `
                    <p style="text-align: center;"><em>Reborn as a cyborg, his Cannon can even blow up a building in an instant... &nbsp;</em></p>

                    <p><img style="display: block; margin-left: auto; margin-right: auto;" src="https://game-cdn.aplusjapan-game.com/opmw/img/news/jie_nuo_si.png" alt="" /></p>

                    <p><strong>Mar. 21st, 2024</strong> &ndash; Starting today, the rate up banner for <strong>SSR</strong> character <strong>Genos</strong> &ndash; Cyborg of Justice and his Impression Arms are available for two weeks! This variant of Genos is an SSR unit and has great abilities to attack in long-distance and melee fights. Take your chance to get the all-rounded cyborg now!</p>
                    <p><strong>Event Duration:</strong><br /><strong>Mar. 21st after maintenance &ndash; 23:59 Apr. 3rd, 2024 (UTC-2)</strong></p>
                    <p>In order to revenge on the crazy cyborg that ruined his hometown, Genos asked Dr. Kuseno to modify himself into a cyborg. Then, he started walking on the path to eliminate evil. Even with the most advanced technology, he keeps refining himself and aims to find... Wait, what? I heard someone saying this is too much and that I should shorten it to 20 words or less?! Okay, okay, let's check out this cool video then:</p>

                    <p><video controls="controls" width="100%" autoplay="autoplay" muted="muted" poster="https://game-cdn.aplusjapan-game.com/opmw/img/news/jie_nuo_si.png">
                    <source src="https://game-cdn.aplusjapan-game.com/opmw/img/news/jie_nuo_si.mp4" type="video/mp4" /></video></p>

                    <p>Genos - Cyborg of Justice is a unit of great mobility. With this character, the players can easily dodge the enemy attacks and seize the chance to deal damage. Powered by the core, Genos can build up energy during battle, and use his cannons to blow up the enemy at once. Even though he appears to be a humble disciple, Genos - Cyborg of Justice is surely the star of the battlefield.</p>
                    <p><strong>Genos will never turn down a chance to become better, so don't miss the special packs in the shop to further upgrade his high-tech parts!&nbsp;</strong></p>
            ` }}
                sx={{
                    mt: 2,
                    mb: 15,
                }} />

        </Stack>
    )
}


function News12(props) {

    return (
        <Stack
            className='news_content'
            component={'div'}
            sx={{
                width: props.w,
                fontFamily: '-moz-initial',
            }} >

            <Box component={'div'}
                dangerouslySetInnerHTML={{
                    __html: `
              
                    <p style="text-align: center;"><em>Besides hero, he is also very active as an entertainer, actor, and singer. &nbsp;</em></p>
                  
                    <p><img style="display: block; margin-left: auto; margin-right: auto;" src="https://game-cdn.aplusjapan-game.com/opmw/img/news/tian.png" alt="" /></p>
                  
                    <p><strong>Mar. 28th, 2024</strong> &ndash; Starting today, new <strong>SSR</strong> character <strong>Handsome Kamen Amai Mask</strong> joins the game together with his Impression Arms! Amai Mask is absolutely professional in both hero and artist careers. Now is your chance to fight alongside people&rsquo;s favorite idol!</p>
                    <p><strong>Event Duration:</strong><br /><strong>Mar. 28th after maintenance &ndash; 23:59 Apr. 10th, 2024 (UTC-2)</strong></p>
                    <p>As the most popular idol, Amai Mask fights elegantly with the beats. Let&rsquo;s check out his abilities in the video below:</p>
                   
                    <p><video controls="controls" width="100%" autoplay="autoplay" muted="muted" poster="https://game-cdn.aplusjapan-game.com/opmw/img/news/tian.png">
                    <source src="https://game-cdn.aplusjapan-game.com/opmw/img/news/tian.mp4" type="video/mp4" /></video></p>
                   
                    <p>Playing Handsome Kamen Amai Mask, you will need to pay attention to the beats, and make sure that you attack in the right direction. In this way, he can increase the damage and quickly gain energy. Also, he is a unit of high mobility. While dodging, he can cancel the move with a normal attack. This surprise attack can deal some extra damage!</p>
                    <p><strong>Amai Mask loath all that is ugly, so he will of course finish the fight beautifully. Don't miss the special deals in the shop to develop his abilities, so he can get nearer to perfection!&nbsp;</strong></p>

            ` }}
                sx={{
                    mt: 2,
                    mb: 15,
                }} />

        </Stack>
    )
}

function News13(props) {

    return (
        <Stack
            className='news_content'
            component={'div'}
            sx={{
                width: props.w,
                fontFamily: '-moz-initial',
            }} >

            <Box component={'div'}
                dangerouslySetInnerHTML={{
                    __html: `
              
            <p style="text-align: center;"><em>He pierces evil with his sharp fangs!</em> &nbsp;</p>
           
            <p><img style="display: block; margin-left: auto; margin-right: auto;" src="https://game-cdn.aplusjapan-game.com/opmw/img/news/kaotou.png" alt="" /></p>
           
            <p><strong>Mar. 21st, 2024</strong> &ndash; Starting today, the rate up banner for <strong>SSR</strong> character <strong>Silverfang</strong> and his Impression Arms are available for <strong>two weeks!</strong> As a Master Martial Artist, Bang has many disciples. And now it&rsquo;s our time to learn from the great master!</p>
            <p><strong>Event Duration:</strong><br /><strong>Apr. 11th after maintenance &ndash; 23:59 Apr. 24th, 2024 (UTC-2)</strong></p>
            <p>Bang, also known as Silverfang, is one of the leading figure in the heroes&rsquo; world. Many has been inspired by him, and some even became his disciplines. An elder he may be, but he keeps improving his martial art moves, in order to live up to his duty as a hero, and keep the city safe. His signature move is called &ldquo;Water Stream Rock Smashing Fist&rdquo;, let&rsquo;s see how he does it in the game:</p>
           
            <p><video controls="controls" width="100%" autoplay="autoplay" muted="muted" poster="https://game-cdn.aplusjapan-game.com/opmw/img/news/kaotou.png">
            <source src="https://game-cdn.aplusjapan-game.com/opmw/img/news/kaotou.mp4" type="video/mp4" /></video></p>
           
            <p><br />Counterattack is the key mechanism in Silverfang&rsquo;s moveset. To maximize the power of this master of Martial Arts, you'll need to counterattack at the right time. This will also charge up his energy to deal tons of damage. So respect the elder, or wait for broken bones!</p>
            <p><strong>Special packs for Silverfang and his Impression Arms are also available together with the banners. Don't miss the chance to get and improve master Bang during the Rate Up period!&nbsp;</strong></p>

            ` }}
                sx={{
                    mt: 2,
                    mb: 15,
                }} />

        </Stack>
    )
}

function News14(props) {

    return (
        <Stack
            className='news_content'
            component={'div'}
            sx={{
                width: props.w,
                fontFamily: '-moz-initial',
            }} >

            <Box component={'div'}
                dangerouslySetInnerHTML={{
                    __html: `
              
<p style="text-align: center;"><em>The fastest ninja got out of the prison!</em></p>

<p><img style="display: block; margin-left: auto; margin-right: auto;" src="https://game-cdn.aplusjapan-game.com/opmw/img/news/sonic.png" alt="" /></p>

<p><strong>Apr. 25th, 2024</strong> &ndash; New <strong>SSR</strong> character <strong>Speed-o'-Sound Sonic&mdash;Prisoner</strong>! Rate up banners for this limited character and his Impression arms are available for <strong>two weeks</strong>! The dangerous ninja broke free! Now no one can stop him&hellip;&nbsp;</p>
<p><strong>Event Duration:</strong><br /><strong>04/25 after maintenance &ndash; 5/8 23:59 (UTC-2)</strong></p>
<p>* Limited characters will no longer be obtainable from any Draw until their banner returns at a later date.</p>
<p>As the fastest ninja, Sonic can get too speedy for human eyes to catch! Speed-o'-Sound Sonic&mdash;Prisoner can create up to 3 Afterimages to perform dazzling combos. And when the energy is full, he can consume them for a powerful Ultimate! Let&rsquo;s see how the shadows work:&nbsp;</p>

<p><video controls="controls" width="100%" autoplay="autoplay" muted="muted" poster="https://game-cdn.aplusjapan-game.com/opmw/img/news/sonic.png">
<source src="https://game-cdn.aplusjapan-game.com/opmw/img/news/sonic.mp4" type="video/mp4" /></video></p>

<p>The afterimages can be fragile and won't resist long under enemy attack, so a clever ninja always knows how to dodge and assault the enemy&rsquo;s weakness! While playing as Speed-o'-Sound Sonic&mdash;Prisoner, make sure that you keep the shadows for more combos, but remember to consume them for a strong ultimate!&nbsp;</p>
<p><strong>Speed-o'-Sound Sonic&mdash;Prisoner's exclusive bundles are also up with his banners. Don't miss the chance to get Draw Tickets, Impression Arms, Wills, &nbsp;and more! Also, another time-limited event is coming soon, so stay tuned for more goodies to get!</strong></p>

          
            ` }}
                sx={{
                    mt: 2,
                    mb: 15,
                }} />

        </Stack>
    )
}

function News15(props) {

    return (
        <Stack
            className='news_content'
            component={'div'}
            sx={{
                width: props.w,
                fontFamily: '-moz-initial',
            }} >

            <Box component={'div'}
                dangerouslySetInnerHTML={{
                    __html: `
              
                    <p style="text-align: center;"><em>"I escaped from prison just to see you!" &nbsp;</em></p>
                  
                    <p><img style="display: block; margin-left: auto; margin-right: auto;" src="https://game-cdn.aplusjapan-game.com/opmw/img/news/puri.png" alt="" /></p>
                   
                    <p><strong>May 9<sup>th</sup>, 2024</strong> &ndash; Starting today, the rate-up banner for <strong>SSR</strong> character <strong>Puri-puri Prisoner </strong>and his Impression Arms are available for <strong>two weeks!</strong> Are you ready for this flamboyant superhero? It&rsquo;s time to go Angel&nbsp;☆&nbsp;Style!</p>

                    <p><strong>Event Duration:</strong><br/>
                     <strong>May 9<sup>th</sup>,&nbsp;after maintenance &ndash; 23: 59 May 22<sup>nd</sup>, 2024 (UTC-2)</strong></p>
                    <p>Puri-puri Prisoner, a hero who is usually kept in prison, is now out of jail and in with the forces to protect the city! The source of his power is LOVE. And with all the love, Puri-puri Prisoner is never afraid and will counter every monster attack head-on. Now let&rsquo;s check what he does in the battles:</p>
                   
                    <p><video controls="controls" width="100%" autoplay="autoplay" muted="muted" poster="https://game-cdn.aplusjapan-game.com/opmw/img/news/puri.png">
                    <source src="https://game-cdn.aplusjapan-game.com/opmw/img/news/puri.mp4" type="video/mp4" /></video></p>                    

                    <p>During the event period, there is an increased chance to Draw Puri-puri Prisoner from the limited-time character banner. In this limited-time Draw, whenever the player pulls an SSR character, that SSR character has a 50% chance to be the banner character [Puri-puri Prisoner]. If the SSR character obtained in the Draw is not the banner character, the next SSR character obtained through the Draw is guaranteed to be the banner Character [Puri-puri Prisoner].</p>
                    <p><strong>Wanna go stronger and stronger until your clothes burst? Remember to check Puri-puri Prisoner's special packs in the shop! </strong></p>
                    
          
            ` }}
                sx={{
                    mt: 2,
                    mb: 15,
                }} />

        </Stack>
    )
}