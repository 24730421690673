import { Box, Stack } from '@mui/material';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { settingActions } from '../../store/reducers/SettingSlice';
import * as APJRequest from '../../js/APJRequest';

export default function MobileHome(props) {

    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);

    function signTap() {
        dispatch(settingActions.showSignDalog());
    }

    return (
        <Stack sx={{
            height: 1,
            bgcolor: 'black',
        }}>
            {/* 背景 */}
            <Box component={'img'}
                src={window.CDNHost+'/img/mobile/m_bg1.jpg'}
                sx={{
                    zIndex: 0,
                    position: 'absolute',
                    width: 1,
                    height: 1,
                    objectFit: 'cover',
                }} />


            {/* 下载 */}
            <Stack sx={{
                position: 'absolute',
                bottom: '80px',
                width: 1,
                alignItems: 'center',
            }}>

                <Box
                    onClick={signTap}
                    component={'span'}
                    sx={{
                        cursor: 'pointer',
                        background: `url(${window.CDNHost}/img/blue_bg1.png) no-repeat`,
                        backgroundSize: '100% 100%',
                        color: 'white',
                        mb: 0.5,
                        height: '47px',
                        lineHeight: '47px',
                        fontSize: 18,
                        alignItems: 'center',
                        textAlign: 'center',
                        minWidth: '138px',
                        mb: 1,
                        borderRadius: 1.5,
                        px: 1.5,
                        display: 'none',
                    }}>
                    {settingData.langDic['lang6']}
                </Box>

                <Box component={'img'}
                    onClick={() => { APJRequest.openApple() }}
                    src={window.CDNHost+'/img/btn_down1_release.png'}
                    sx={{
                        cursor: 'pointer',
                        width: '160px',
                        height: '50px',
                        mb: 1,
                        border: 1,
                        borderRadius: 2,
                        borderColor: '#A1A1A0',

                    }} />

                <Box component={'img'}
                    onClick={() => { APJRequest.openGoogle() }}
                    src={window.CDNHost+'/img/btn_down2_release.png'}
                    sx={{
                        cursor: 'pointer',
                        width: '160px',
                        height: '50px',
                        border: 1,
                        borderRadius: 2,
                        borderColor: '#A1A1A0',
                    }} />

            </Stack>



            {/* 底部提示 */}
            <Stack direction={'column'}
                onClick={() => { props.slideTo(1) }}
                sx={{
                    position: 'absolute',
                    width: 1,
                    height: '45px',
                    bottom: 0,
                    alignItems: 'center',
                    animation: 'scrollerAni 2s ease-in-out both infinite',
                }}>
                <Box component={'img'} src={window.CDNHost+'/img/down_icon.png'}
                    sx={{
                        width: '30px',
                    }} />
                <Box component={'img'} src={window.CDNHost+'/img/down_icon.png'}
                    sx={{
                        width: '30px',
                    }} />
            </Stack>

            <Box component={'audio'}
                src={window.CDNHost+'/img/bgm.mp3'}
                loop={true}
                id='bg_music'
                preload={'auto'} />

        </Stack>
    );
}