import { Box, Stack, Typography } from '@mui/material';
import * as React from 'react';
import 'swiper/css';
import 'swiper/css/effect-creative';
import MobileDownFooter from './MobileDownFooter';
import * as APJRequest from '../../js/APJRequest';

export default function MobileFooter(props) {

    function privacyTap() {
        if (props.isCR) {
            window.open('https://www.sonypictures.com/corp/privacy.html');
        } else {
            APJRequest.openPrivacy();
        }
    }

    function termTap() {
        if (props.isCR) {
            window.open('https://www.crunchyroll.com/games/terms/');
        } else {
            APJRequest.openTerm();
        }
    }

    function serviceTap() {//客服表单
        if (props.isCR) {
            window.open('https://crgames.zendesk.com/hc/en-us');
        } else {
            APJRequest.openFAQ();
        }
    }

    return (
        <Stack sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: 1,
            mb: 0.8,
            alignItems: 'center',
        }}>

            {/* 下载按钮 */}
            <MobileDownFooter isCR={props.isCR} />

            <Stack sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                my: 0.7,
            }}>

                {/* 左边icon */}
                <Box component={'img'} src={window.CDNHost + '/img/wanmei.png'}
                    sx={{
                        height: '17px',
                        ml: 2,
                    }} />
                <Box component={'img'} src={window.CDNHost + '/img/flogo1.png'}
                    sx={{
                        height: '17px',
                        ml: 1,
                    }} />

                <Box component={'img'}
                    src={window.CDNHost + '/img/flogo2.png'}
                    sx={{
                        height: '17px',
                    }} />

                <Box component={'img'} src={window.CDNHost + '/img/flogo3.png'}
                    sx={{
                        height: '17px',
                        ml: 1,
                    }} />
            </Stack>

            {/* 中间文字 */}
            <Stack sx={{
                color: '#CACFD199',
            }}>
                <Stack direction={'row'} sx={{
                    justifyContent: 'center',
                }}>
                    <Typography
                        onClick={privacyTap}
                        sx={{
                            cursor: 'pointer',
                            fontSize: 10,
                            pl: '5px',
                        }}>Privacy Policy </Typography>

                    <Typography
                        sx={{
                            fontSize: 10,
                            ml: '3px',
                            mr: '3px',
                        }}> / </Typography>

                    <Typography
                        onClick={termTap}
                        sx={{
                            cursor: 'pointer',
                            fontSize: 10,
                        }}>Terms of services </Typography>

                    <Typography
                        sx={{
                            fontSize: 10,
                            ml: '3px',
                            mr: '3px',
                        }}> / </Typography>

                    <Typography
                        onClick={serviceTap}
                        sx={{
                            cursor: 'pointer',
                            fontSize: 10,
                        }}>ClientService</Typography>
                </Stack>

                <Typography sx={{
                    fontSize: 10,
                    ml: '5px',
                }}>
                    ©ONE, Yusuke Murata/SHUEISHA, Hero Association HQ
                </Typography>

            </Stack>

        </Stack>
    );

}