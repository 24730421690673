import { Box, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as APJRequest from '../../js/APJRequest';

export default function MobileMenu(props) {

    const settingData = useSelector(state => state.settingData);

    function itemTap(i) {
        props.slideTo(i);
        props.closeMenu();
    }

    function shareTap(i) {
        if (i == 0) { //disk
            APJRequest.openDiscord();

        } else if (i == 1) {//tiwtter
            APJRequest.openTwitter();

        } else { //fb
            APJRequest.openFacebook();
        }
    }

    return (
        <Stack sx={{
            width: window.innerWidth * 0.7,
            height: window.innerHeight,
            bgcolor: 'black',
        }}>

            <Box component={'img'}
                onClick={props.closeMenu}
                src={window.CDNHost+'/img/close.png'}
                sx={{
                    width: '20px',
                    mt: 4,
                    mb: 5,
                    ml: 3,
                }} />

            <Item title={settingData.langDic['lang0']} index={0} itemTap={itemTap} />
            <Item title={settingData.langDic['lang54']} index={1} itemTap={itemTap} />
            <Item title={settingData.langDic['lang1']} index={2} itemTap={itemTap} />
            <Item title={settingData.langDic['lang2']} index={3} itemTap={itemTap} />
            <Item title={settingData.langDic['lang3']} index={4} itemTap={itemTap} />
            <Item title={settingData.langDic['lang4']} index={5} itemTap={itemTap} />

            {/* 底部icon */}
            <Stack sx={{
                width: 1,
                height: '50px',
                position: 'absolute',
                bottom: '15px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
            }}>

                <Box component={'img'} src={window.CDNHost+'/img/pt1.png'}
                    onClick={(e) => shareTap(0)}
                    sx={{
                        height: '25px',
                    }} />

                <Box component={'img'} src={window.CDNHost+'/img/pt2.png'}
                    onClick={(e) => shareTap(1)}
                    sx={{
                        height: '28px',
                        ml: 4,
                    }} />

                <Box component={'img'} src={window.CDNHost+'/img/pt3.png'}
                    onClick={(e) => shareTap(2)}
                    sx={{
                        height: '28px',
                        ml: 3.5,
                    }} />

            </Stack>

        </Stack>
    );
}

function Item(props) {
    return (
        <Stack onClick={() => props.itemTap(props.index)}>
            <Typography sx={{
                color: 'white',
                fontFamily: 'us',
                fontSize: 18,
                height: '70px',
                lineHeight: '70px',
                pr: 3,
                pl: 3,
                // textAlign: 'end',
            }}>
                {props.title}
            </Typography>
            <Box sx={{
                width: 1,
                height: '1px',
                bgcolor: '#C9CDD140',
            }} />
        </Stack>
    );
}