import { Box, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { useSelector } from 'react-redux';
import * as APJRequest from '../js/APJRequest';
import * as rdd from 'react-device-detect';


export default function PreRegisterSuccessPage() {

    const settingData = useSelector(state => state.settingData);

    function isMobile() {
        return rdd.isMobile || window.innerWidth < 700;
    }

    function shareTap(i) {
        if (i == 0) { //disk
            APJRequest.openDiscord();

        } else if (i == 1) {//tiwtter
            APJRequest.openTwitter();

        } else { //fb
            APJRequest.openFacebook();
        }
    }

    return (
        <Stack sx={{
            width: 1,
            height: '100vh',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }}>

            <Box
                component={'img'}
                src={window.CDNHost + `/img/share/bg.jpg`}
                sx={{
                    position: 'absolute',
                    width: 1,
                    height: 1,
                    objectFit: 'cover',
                }} />

            <Box
                component={'img'}
                src={window.CDNHost + '/img/logo.png'}
                sx={{
                    zIndex: 1,
                    alignSelf: 'flex-start',
                    height: isMobile() ? '40px' : '65px',
                    ml: isMobile() ? 2 : 5,
                    mt: isMobile() ? 2 : 5,
                }} />

            <Box sx={{ flex: 1 }} />

            <Box
                component={'img'}
                src={window.CDNHost + `/img/share/wenzi_${settingData.langKey}.png`}
                sx={{
                    zIndex: 1,
                    height: 0.08,
                    minHeight: isMobile() ? '30px' : '80px',
                    maxWidth: 0.95,
                    maxHeight: '200px',
                    objectFit: 'contain',
                    mb: 1,
                }} />

            <Typography sx={{
                zIndex: 1,
                fontFamily: 'us',
                fontSize: isMobile() ? 18 : 40,
                color: 'white',
                textAlign: 'center',
                mb: 1,
            }}>
                {settingData.langDic['lang56']}
            </Typography>

            <Stack sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                mt: 3,
                mb: 12,
                zIndex: 1,
            }}>
                <Box component={'img'}
                    onClick={(e) => shareTap(0)}
                    src={window.CDNHost + '/img/share/discord.png'} sx={{
                        cursor: 'pointer',
                        height: isMobile() ? '30px' : '45px',
                    }} />

                <Box component={'img'}
                    onClick={(e) => shareTap(1)}
                    src={window.CDNHost + '/img/share/X.png'} sx={{
                        cursor: 'pointer',
                        height: isMobile() ? '30px' : '45px',
                        ml: 11,
                    }} />

                <Box component={'img'}
                    onClick={(e) => shareTap(2)}
                    src={window.CDNHost + '/img/share/facebook.png'} sx={{
                        cursor: 'pointer',
                        height: isMobile() ? '35px' : '55px',
                        ml: 11,
                    }} />
            </Stack>
        </Stack>
    );
}