import { Stack, Box, Typography, Grid, Snackbar, Backdrop, CircularProgress } from '@mui/material';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation, useSearchParams, json } from 'react-router-dom';
import * as StringTool from '../../js/StringTool';
import { TWSid, TWRid, StoreSid, StoreRid, TWData } from '../../js/StringTool';
import { settingActions, settingObj } from '../../store/reducers/SettingSlice';
import RoleAlert from '../twitch/RoleAlert';
import BindTwitchLoginAlert from '../twitch/BindTwitchLoginAlert';
import * as rdd from 'react-device-detect';
// import VConsole from 'vconsole';


export default function ProductStore(props) {
    const productList = [
        {
            'name': 'World Gold 33',
            'name2': null,
            'productId': 'com.opmwapj.webshop1',
            'money': '5.49',
            'icon': '98 World Gold.png'
        },
        {
            'name': 'World Gold 105',
            'name2': 'Bonus Silver120',
            'productId': 'com.opmwapj.webshop2',
            'money': '17.49',
            'icon': '108w.png'
        },
        {
            'name': 'World Gold 215',
            'name2': 'Bonus Silver300',
            'productId': 'com.opmwapj.webshop3',
            'money': '34.49',
            'icon': '218w.png'
        },
        {
            'name': 'World Gold 355',
            'name2': 'Bonus Silver700',
            'productId': 'com.opmwapj.webshop4',
            'money': '59.49',
            'icon': '1500w.png'
        },
        {
            'name': 'World Gold 718',
            'name2': 'Bonus Silver1700',
            'productId': 'com.opmwapj.webshop5',
            'money': '99.49',
            'icon': '8300w.png'
        }
    ];

    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);
    const [sid, setSid] = React.useState(null);
    const [rid, setRid] = React.useState(null);
    const [isMobile, setIsMobile] = React.useState(false);
    const [isPortrait, setIsPortrait] = React.useState(false);

    React.useEffect(() => {
        if (rdd.isMobile || window.innerWidth < 700) {
            setIsMobile(true);
            if (window.innerWidth < window.innerHeight) {
                setIsPortrait(true);
            }
        }
    }, []);

    function updateRoleId(sid, rid) {
        setSid(sid);
        setRid(rid);
    }

    function productItemTap(data) {
        //先登录游戏
        if (!settingData.opmData || !settingData.opmData.websdk) {
            dispatch(settingActions.showToast('Please login OPMW Account'));
            return;
        }

        //再选角色
        if (StringTool.isEmpty(StringTool.getLocalString(StoreRid))) {
            dispatch(settingActions.showToast('Please Select Character'));
            return;
        }
        let url = `https://static.perfectworldgames.com/OPMpay/index.html?appid=1000136&uid=${settingData.opmData.websdk.uid}&roleid=${StringTool.getLocalString(StoreRid)}&serverid=${StringTool.getLocalString(StoreSid)}&productid=${data.productId}&appOrderId=${StringTool.uuid()}&local=en&token=${settingData.opmData.websdk.token}&extraInfo=&headerData=`;
        window.open(url);
        console.log(url);
    }

    return (
        <Stack sx={{
            width: 1,
            height: '100vh',
            alignItems: 'center',
            overflowY: 'auto',
        }}>

            <Box
                component={'img'}
                src={window.CDNHost + '/img/payment/BG.png'}
                sx={{
                    width: 1,
                    height: 1,
                    objectFit: 'fill',
                    position: 'absolute',
                    zIndex: -1,
                }} />

            <Stack sx={{
                width: 1,
                alignItems: 'center',
            }}>

                <Stack sx={{
                    width: 1,
                    position: 'relative',
                }}>
                    <Box
                        component={'img'}
                        src={window.CDNHost + '/img/payment/pay_banner.jpg'}
                        sx={{
                            width: 1,
                            minHeight: isMobile ? '100px' : '190px',
                            maxHeight: isMobile ? '140px' : '250px',
                            objectFit: 'cover'
                        }} />

                    <Box component={'img'}
                        src={window.CDNHost + '/img/logo.png'}
                        sx={{
                            position: 'absolute',
                            mt: isMobile ? 2 : 3,
                            ml: isMobile ? 2 : 5,
                            height: isMobile ? '30px' : '45px',
                        }} />

                    <Box component={'img'}
                        src={window.CDNHost + '/img/payment/copy_right.png'}
                        sx={{
                            position: 'absolute',
                            right: 0,
                            bottom: 0,
                            height: isMobile ? '30px' : '40px',
                        }} />
                </Stack>

                <Stack sx={{
                    width: isMobile ? 0.94 : '1200px',
                    mt: isMobile ? 0 : 1,
                    flexDirection: 'row',
                }}>
                    {/* 登录 */}
                    <Stack sx={{
                        width: isMobile ? '330px' : '370px',
                        mt: 2,
                        mr:2,
                        display: isPortrait ? 'none' : 'flex',
                    }}>
                        <LoginItem isMobile={isMobile} isPortrait={isPortrait} sid={sid} rid={rid} updateRoleId={updateRoleId} />
                    </Stack>


                    {/* 列表 */}
                    <Grid container
                        spacing={2}
                        sx={{
                            mt: '1px',
                            mb: 10,
                        }} >

                        {
                            isPortrait &&
                            <Grid item xs={12}>
                                <LoginItem isMobile={isMobile} sid={sid} rid={rid} updateRoleId={updateRoleId} />
                            </Grid>
                        }

                        {
                            productList.map((item, i) =>
                                <Grid item xs={(isPortrait ? 6 : (isMobile ? 4 : 3))} key={i}>
                                    <ProductItem isMobile={isMobile} data={item} productItemTap={productItemTap} />
                                </Grid>
                            )
                        }
                    </Grid>
                </Stack>

                <BindTwitchLoginAlert isMobile={isMobile} />
                <RoleAlert isMobile={isMobile} key={settingData.roleAlertKey} storeConfirmTap={updateRoleId} />

                {/* loading view */}
                <Backdrop
                    sx={{ color: '#fff', zIndex: 99999 }}
                    open={settingData.showLoading}
                    onClick={() => { }}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                {/* 提示 */}
                <Snackbar open={!StringTool.isEmpty(settingData.showToast)} autoHideDuration={1500} onClose={() => {
                    dispatch(settingActions.showToast(null));
                }}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}>
                    <Box
                        sx={{
                            minWidth: '250px',
                            height: '45px',
                            lineHeight: '45px',
                            borderRadius: '5px',
                            bgcolor: '#eeeeee',
                            color: 'black',
                            textAlign: 'center',
                            px: 3,
                        }}>
                        {settingData.showToast}
                    </Box>
                </Snackbar>

            </Stack>
        </Stack>
    );
}

function LoginItem(props) {

    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);
    const [inGame, setInGame] = React.useState(false);
    // const vConsole = new VConsole();
    const [sName, setSName] = React.useState(null);
    const [rName, setRName] = React.useState(null);

    React.useEffect(() => {
        checkGameLogin();
        // return () => {
        //     vConsole.destroy();
        // }
    }, []);

    function checkGameLogin() {
        /*判断客户端*/
        let userAgent = window.navigator.userAgent.toLowerCase();
        //判断端内
        let _judgeClient = userAgent.indexOf('activitysdk') > -1 || userAgent.indexOf('activtysdk') > -1 || userAgent.indexOf('pcactivtysdk') > -1;
        console.log('_judgeClient 打印：' + _judgeClient);

        //sdk获取信息
        if (_judgeClient) {
            window.webveiwFuns.ready(function (data) {
                function getMsg(data) {
                    if (typeof (data) == 'string') {
                        try {
                            var jsonData = JSON.parse(data);
                        } catch (err) {
                            var jsonData = false;
                        }
                    } else if (typeof (data) == 'object') {
                        var jsonData = data;
                    }
                    return jsonData;
                }

                window.webveiwFuns.getDeviceInfo(function (data) {
                    console.log(data);

                    let gameData = getMsg(data).result;
                    if (gameData == null || gameData == undefined) { //空
                        initLogin();
                    } else {
                        logoutTap(); //清一下
                        setInGame(true);

                        let user = {
                            username: gameData.roleName,
                            uid: gameData.uid,
                            token: gameData.token,
                        }
                        //保存本地再init sdk 
                        setCookie('_websdk_' + '1000136', JSON.stringify(user));
                        StringTool.saveLocalString(StoreSid, gameData.serviceId);
                        StringTool.saveLocalString(StoreRid, gameData.roleId);

                        setSName(gameData.serviceName);
                        setRName(gameData.roleName);

                        if (StringTool.isEmpty(gameData.serviceName)) {
                            setSName(gameData.serviceId);
                        }
                        if (StringTool.isEmpty(gameData.roleName)) {
                            setRName(gameData.roleId);
                        }

                        dispatch(settingActions.setOpmData({
                            websdk: user,
                            avatar: null,
                            state: true
                        }));
                    }
                });
            })
        } else {
            initLogin();
        }
    }

    function setCookie(key, value) {
        var d = new Date();
        d.setTime(d.getTime() + (7 * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = key + "=" + value + "; " + expires + "; path=/";
    }

    function initLogin() {
        setTimeout(() => {
            startInit();
        }, 500);
    }

    function startInit() {
        window.initOPM((data) => {
            showLoading(false);
            if (data.state) {
                dispatch(settingActions.setOpmData(data));
                dispatch(settingActions.showTwitchLogin(false)); //关闭登录框

                //服务列表
                window.requestRoleList(true, data.websdk.uid, data.websdk.token, (sData) => {
                    if (sData.success) {
                        dispatch(settingActions.setServiceData(sData));

                        //检查商城Role
                        checkProductStoreRole();
                    } else {
                        console.log('service list is empty')
                    }
                });
            }
        }, (errorMsg) => {
            showLoading(false);
            if (errorMsg) {
                showToast(errorMsg);
            }
        });
    }


    function showLoading(show) {
        dispatch(settingActions.showLoading(show));
    }

    function showToast(msg) {
        dispatch(settingActions.showToast(msg));
    }

    function checkProductStoreRole() {
        const sid = StringTool.getLocalString(StoreSid);
        const rid = StringTool.getLocalString(StoreRid);
        if (StringTool.isEmpty(rid) && settingObj.opmData) {
            dispatch(settingActions.showRoleAlert(true));
        } else {
            props.updateRoleId(sid, rid);
        }
    }

    function loginTap() {
        dispatch(settingActions.showTwitchLogin(true));
    }

    function logoutTap() {
        window.logoutGame();
        StringTool.removeLocalString(TWSid);
        StringTool.removeLocalString(TWRid);
        dispatch(settingActions.setServiceData(null));
        dispatch(settingActions.setOpmData(null)); //game user
        dispatch(settingActions.setServiceId(null));
        dispatch(settingActions.setRoleId(null));
        dispatch(settingActions.setTwitchData(null));
        dispatch(settingActions.setTwitchCode(null));

        StringTool.removeLocalString(StoreSid);
        StringTool.removeLocalString(StoreRid);
        StringTool.removeLocalString(TWData);
    }

    function selectRoleTap() {
        if (!inGame) {
            dispatch(settingActions.showRoleAlert(true));
        }
    }

    function getRoleName(rid) {
        if (!StringTool.isEmpty(rid) && settingObj.serviceData) {
            for (const md of settingObj.serviceData.roleList) {
                if (md.roleId == rid) {
                    if (!StringTool.isEmpty(md.roleName)) {
                        return md.roleName;
                    } else {
                        return md.roleId;
                    }
                }
            }
        }
        return '';
    }

    function getServiceName(sid) {
        if (!StringTool.isEmpty(sid) && settingObj.serviceData) {
            for (const md of settingObj.serviceData.serverList) {
                if (md.serverId == sid) {
                    if (!StringTool.isEmpty(md.serverName)) {
                        return md.serverName;
                    } else {
                        return md.serverId;
                    }
                }
            }
        }
        return '';
    }

    return (
        <Stack sx={{
            borderRadius: '6px',
            boxShadow: 3,
            alignItems: 'center',
            background: `url(${window.CDNHost}/img/payment/account_bg.png) no-repeat`,
            backgroundSize: '100% 100%',
            color: 'white',
            border: 3,
            borderColor: '#4162AE',
            pb: props.isMobile ? 2 : 3,
        }}>
            <Typography
                sx={{
                    mt: props.isMobile ? 2 : 3,
                    fontWeight: 'bold',
                    fontSize: props.isMobile ? 16 : 20,
                }}>
                OPMW Account
            </Typography>

            <Typography sx={{
                display: settingData.opmData ? 'none' : 'flex',
                fontSize: 13,
                mt: 2,
                width: 0.8,
            }}>
                Please log in and create a character in the game before you can recharge on the page.
            </Typography>

            <Typography
                onClick={loginTap}
                sx={{
                    display: settingData.opmData ? 'none' : 'flex',
                    color: 'white',
                    fontWeight: 'bold',
                    px: 3,
                    height: '40px',
                    lineHeight: '40px',
                    mt: props.isMobile ? 2 : 4,
                    fontSize: props.isMobile ? 13 : 15,
                    borderRadius: '20px',
                    cursor: 'pointer',
                    // bgcolor: '#F76E40',
                    background: `url(${window.CDNHost}/img/twitch/blue_bt.png) no-repeat`,
                    backgroundSize: '100% 100%',
                }}>
                Login Account
            </Typography>

            <Stack sx={{
                width: 0.8,
                display: settingData.opmData ? 'flex' : 'none',
            }}>
                <Stack sx={{
                    fontSize: props.isMobile ? 14 : 16,
                    mt: 3,
                    flexDirection: 'row',
                }}>
                    Account：{settingData.opmData && settingData.opmData.websdk.uid}
                </Stack>

                <Stack sx={{ flexDirection: 'row' }}>
                    <Typography
                        onClick={selectRoleTap}
                        sx={{
                            fontSize: props.isMobile ? 14 : 16,
                            mt: 2,
                            cursor: inGame ? '' : 'pointer',
                            borderBottom: inGame ? 0 : 2,
                            borderColor: '#A1B3D6',
                        }}>
                        Service：{props.sid && !StringTool.isEmpty(StringTool.getLocalString(StoreSid)) ? getServiceName(props.sid) : (sName ? sName : 'Select Service')}
                    </Typography>

                </Stack>

                <Stack sx={{ flexDirection: 'row' }}>
                    <Typography
                        onClick={selectRoleTap}
                        sx={{
                            fontSize: props.isMobile ? 14 : 16,
                            mt: 2,
                            cursor: inGame ? '' : 'pointer',
                            borderBottom: inGame ? 0 : 2,
                            borderColor: '#A1B3D6',
                        }}>
                        Character：{props.rid && !StringTool.isEmpty(StringTool.getLocalString(StoreRid)) ? getRoleName(props.rid) : (rName ? rName : 'Select Character')}

                    </Typography>
                </Stack>

                <Typography sx={{
                    fontSize: 13,
                    mt: 3,
                }}>
                    Please log in and create a character in the game before you can recharge on the page.
                </Typography>

                <Typography
                    onClick={logoutTap}
                    sx={{
                        display: inGame ? 'none' : 'flex',
                        alignSelf: 'flex-end',
                        width: '52px',
                        fontSize: 14,
                        mt: 1.5,
                        borderBottom: 2,
                        borderColor: '#A1B3D6',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                    }}>
                    Logout
                </Typography>
            </Stack>

        </Stack>
    );
}

function ProductItem(props) {
    return (
        <Stack
            onClick={() => {
                props.productItemTap(props.data);
            }}
            sx={{
                position: 'relative',
                width: 1,
                cursor: 'pointer',
                alignItems: 'center',
                borderRadius: '10px',
                overflow: 'hidden',
                transitionDuration: '0.3s',
                '&:hover': {
                    transform: 'scale(1.03)',
                }
            }}>

            <Stack sx={{
                position: 'relative',
                alignItems: 'center',
                width: 1,
            }}>
                <Box component={'img'}
                    src={window.CDNHost + '/img/payment/bg_1.png'}
                    sx={{
                        width: 1,
                        opacity: 0.8,
                    }} />

                <Box component={'img'}
                    src={window.CDNHost + '/img/payment/daoju/' + props.data.icon}
                    sx={{
                        position: 'absolute',
                        width: 0.8,
                        mt: 1,
                    }} />


                <Stack sx={{
                    mb: props.data.name2 ? 0.3 : 1.8,
                    position: 'absolute',
                    bottom: 0,
                }}>
                    <Typography sx={{
                        textAlign: 'center',
                        fontSize: props.isMobile ? 13 : 15,
                        color: 'white',
                        fontWeight: 'bold',
                    }}>
                        {props.data.name}
                    </Typography>

                    <Stack sx={{
                        display: props.data.name2 ? 'flex' : 'none'
                    }}>
                        <Typography sx={{
                            textAlign: 'center',
                            lineHeight: '9px',
                            color: 'white'
                        }}>
                            +
                        </Typography>

                        <Typography sx={{
                            textAlign: 'center',
                            fontSize: props.isMobile ? 13 : 15,
                            color: 'white',
                            fontWeight: 'bold',
                        }}>
                            {props.data.name2}
                        </Typography>

                    </Stack>

                </Stack>


            </Stack>

            <Stack sx={{
                borderTop: 3,
                borderColor: '#253C80',
                position: 'relative',
                width: 1,
            }}>
                <Typography sx={{
                    background: `url(${window.CDNHost}/img/payment/bg_2.png) no-repeat`,
                    backgroundSize: '100% 100%',
                    fontWeight: 'bold',
                    color: '#FDD706',
                    textAlign: 'center',
                    fontSize: props.isMobile ? 14 : 17,
                    py: 1.5,
                }}>
                    EUR {props.data.money}
                </Typography>


            </Stack>

        </Stack>
    );
}