import { Box, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { settingActions, settingObj } from '../store/reducers/SettingSlice';
import * as StringTool from '../js/StringTool';
import * as APJRequest from '../js/APJRequest';
import RightIcon from './RightIcons';

export default function NewsList(props) {

    const settingData = useSelector(state => state.settingData);

    function downTap(){
        window.open('https://game-cdn.aplusjapan-game.com/opmw/OPM_EURO_common_1.0.1.1027.exe');
    }

    return (
        <Stack sx={{
            width: 1,
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            bgcolor: 'black',
        }}>

            <Box component={'img'} src={window.CDNHost + '/img/video3.jpg'} sx={{
                width: 1,
                height: 1,
                objectFit: 'cover',
                position: 'absolute',
            }} />

            <Box component={'img'} src={window.CDNHost + '/img/logo.png'} sx={{
                height: '60px',
                position: 'absolute',
                zIndex: 1,
                left: '40px',
                top: '40px',
            }} />

            <Box
                component={'video'}
                autoPlay={true}
                loop={true}
                muted={true}
                src={window.CDNHost + `/img/video3.mp4`}
                sx={{
                    position: 'absolute',
                    width: 1,
                    height: 1,
                    objectFit: 'cover',
                }} />

            <Box
                onClick={downTap}
                component={'span'}
                sx={{
                    position: 'absolute',
                    bottom: '100px',
                    cursor: 'pointer',
                    background: `url(${window.CDNHost}/img/btn.png) no-repeat`,
                    backgroundSize: '100% 100%',
                    color: 'white',
                    pl: 10,
                    pr: 11.5,
                    py: 0.2,
                    height: '70px',
                    fontSize: 30,
                    alignItems: 'center',
                    textAlign: 'center',
                    lineHeight: '60px',
                    animation: 'homeBtAni 1.5s ease-in-out both infinite',
                    '&:hover': {
                        color: '#FFFF84',
                    }
                }}>
                DOWNLOAD
            </Box>

            <RightIcon />
        </Stack>
    );
}