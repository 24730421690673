import { Stack, Box, Typography } from '@mui/material';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Mousewheel } from 'swiper/modules';
import { settingActions } from '../store/reducers/SettingSlice';
import { heroActions } from '../store/reducers/HeroSlice';
import InfoFooter from './InfoFooter';
import HomeFooter from './HomeFooter';
import PreRegister from './PreRegister';
import SignDialog from './SignDialog';
import { useParams, useLocation, useSearchParams } from 'react-router-dom'

export default function ShareUsedPage(props) {

    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);
    const [swp, setSwp] = React.useState(null);
    const [searchParams, setSearchParams] = useSearchParams();

    const [adPlatform, setAdPlatform] = React.useState('');


    React.useEffect(() => {
        const pt = searchParams.get('adPlatform');
        setAdPlatform(pt);
    }, []);

    return (
        <Stack
            key={adPlatform}
            sx={{
                width: 1,
                height: window.innerHeight,
            }}>
            <Swiper
                direction={'vertical'}
                slidesPerView={1}
                mousewheel={true}
                className="mySwiper"
                modules={[Mousewheel]}
                resistanceRatio={0}

                onSwiper={(sp) => {
                    setSwp(sp);
                    sp.activeIndex = settingData.mobileSwpIndex;
                }}

                onActiveIndexChange={(sp) => {

                }}

                style={{
                    width: '100%',
                    height: '100%',
                }}>

                <SwiperSlide style={{ overflow: 'hidden', width: '100%', height: '100%' }}><Page0 adPlatform={adPlatform}/></SwiperSlide>
                <SwiperSlide style={{ overflow: 'hidden', width: '100%', height: '100%' }}><Page2 adPlatform={adPlatform}/></SwiperSlide>
            </Swiper>

            <SignDialog />

        </Stack>
    );
}

function Page0(props) {
    const settingData = useSelector(state => state.settingData);

    return (
        <Stack sx={{
            width: 1,
            height: 1,
            overflow: 'hidden',

            display: 'flex',
            flexDirection: 'column',
            bgcolor: 'black',
        }}>
            <Box
                component={'img'}
                src={window.CDNHost + '/img/share/bg_11.jpg'}
                sx={{
                    position: 'absolute',
                    width: 1,
                    height: 1,
                    objectFit: 'cover',
                }} />

            <Box
                component={'video'}
                autoPlay={true}
                loop={true}
                muted={true}
                src={window.CDNHost + '/img/share/video0.mp4'}
                sx={{
                    position: 'absolute',
                    width: 1,
                    height: 1,
                    objectFit: 'cover',
                }} />

            <Box
                component={'img'}
                src={window.CDNHost + '/img/logo.png'}
                sx={{
                    zIndex: 1,
                    position: 'absolute',
                    height: '60px',
                    ml: 5,
                    mt: 3,
                }} />

            <LanguageView />

            <Stack sx={{
                width: 1,
                height: 1,
                position: 'absolute',
                zIndex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}>

                <Box
                    component={'img'}
                    src={window.CDNHost + `/img/share/pc_${settingData.langKey}_release.png`}
                    sx={{
                        zIndex: 1,
                        position: 'absolute',
                        height: 0.18,
                        maxHeight: '220px',
                        minHeight: '100px',
                        mb: 30,
                        bottom: 0,

                    }} />
            </Stack>

            <HomeFooter adPlatform={props.adPlatform}/>


            {/* 底部提示 */}
            <Stack direction={'column'}
                onClick={() => { props.slideTo(1) }}
                sx={{
                    position: 'absolute',
                    width: 1,
                    height: '45px',
                    bottom: 0,
                    alignItems: 'center',
                    animation: 'scrollerAni 2.5s ease-in-out both infinite',
                }}>
                <Box component={'img'} src={window.CDNHost + '/img/down_icon.png'}
                    sx={{
                        width: '25px',
                    }} />
                <Box component={'img'} src={window.CDNHost + '/img/down_icon.png'}
                    sx={{
                        width: '25px',
                    }} />
            </Stack>

        </Stack>
    );

}

function Page1() {
    return (
        <Stack sx={{
            width: 1,
            height: 1,
            overflow: 'hidden',
        }}>

            <PreRegister isShare={true} />

        </Stack>
    );
}

function Page2(props) {
    return (
        <Stack sx={{
            width: 1,
            height: 1,
            overflow: 'hidden',
        }}>

            <ShareHeroDetail />


            <InfoFooter adPlatform={props.adPlatform}/>

        </Stack>
    );
}


function ShareHeroDetail(props) {
    const dispatch = useDispatch();
    const heroData = useSelector(state => state.heroData);
    const [skillIndex, setSkillIndex] = React.useState(0);

    const [swp, setSwp] = React.useState(null);
    const [videoWidth, setVideoWidth] = React.useState(window.innerWidth * 0.25);
    const [flagAni, setFlagAni] = React.useState('detailFlag');
    const [heroAni, setHeroAni] = React.useState('leftToRight');
    const [mpAni, setMpAni] = React.useState('leftToRight');

    React.useEffect(() => {
        window.addEventListener(
            'resize',
            () => {
                fitWith();
            }
        );
    }, []);

    function fitWith() {
        setVideoWidth(window.innerWidth * 0.3);
    }

    function leftTap() {
        let x = heroData.index - 1;
        if (x < 0) {
            x = 0;
        }
        heroTap(x);
    }

    function rightTap() {
        let x = heroData.index + 1;
        if (x > heroData.heros.length - 1) {
            x = heroData.heros.length - 1;
        }
        heroTap(x);
    }

    function heroTap(i) {
        dispatch(heroActions.selectHeroIndex(i));
        setFlagAni('bigHeroFlag');
        setHeroAni('bigHeroLeftToRight');
        setMpAni('bottomToUp_Half');
        setSkillIndex(0);
        swp.slideTo(i - 2);
    }

    function skillTap(index) {
        setSkillIndex(index);
    }

    return (
        <Stack
            sx={{
                zIndex: 0,
                display: 'flex',
                alignItems: 'center',
                width: 1,
                flex: 1,
                overflow: 'hidden',
                position: 'relative',
                justifyContent: 'center',
            }}>

            <Box
                component={'img'}
                src={window.CDNHost + '/img/bg2.png'}
                sx={{
                    position: 'absolute',
                    width: 1,
                    height: 1,
                    objectFit: 'cover',
                }} />

            <Box
                key={`${heroData.name}_bg`}
                component={'img'}
                src={window.CDNHost + `/img/hero_bg/${heroData.name}_bg.png`}
                sx={{
                    position: 'absolute',
                    // minHeight: '500px',
                    objectFit: 'cover',
                    height: 1,
                    left: 0,
                    animation: `${flagAni} 0.7s`,
                }} />

            <Stack sx={{
                position: 'absolute',
                width: 1,
                height: 1,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Stack sx={{
                    height: 1,
                    width: 0.5,
                    justifyContent: 'center',
                }}>
                    <Box
                        key={`${heroData.name}_full`}
                        component={'img'}
                        src={window.CDNHost + `/img/hero_full/${heroData.name}_full.png`}
                        sx={{
                            objectFit: 'cover',
                            width: '150%',
                            mt: '55%',
                            ml: '-30%',
                            animation: `${heroAni} 0.7s`,
                        }} />

                    <Box
                        key={`${heroData.name}_mp`}
                        component={'img'}
                        src={window.CDNHost + `/img/hero_mp/${heroData.name}_icon.png`}
                        sx={{
                            position: 'absolute',
                            ml: '12%',
                            mt: '28%',
                            height: '140px',
                            animation: `${mpAni} 0.7s`,
                        }} />

                </Stack>

                <Stack sx={{
                    height: 1,
                    width: 0.5,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    animation: 'rightToLeft 0.7s',
                }}>

                    {/* 1. Swiper Banner */}
                    <Stack sx={{
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        width: (videoWidth * 1.4),
                        height: (videoWidth * 1.4 / 5.35),
                    }}>


                        <Swiper
                            style={{
                                height: '100%',
                                width: '90%',
                                transform: 'skew(-29deg)',
                            }}
                            onSwiper={(swiper) => {
                                setSwp(swiper);
                                swiper.activeIndex = heroData.index - 2;
                            }}
                            spaceBetween={5}
                            slidesPerView={5}>

                            {heroData.heros.map((value, i) => {
                                return <SwiperSlide key={i}>
                                    <HeroItem value={value} index={i} activeIndex={heroData.index} heroTap={heroTap} />
                                </SwiperSlide>;
                            })}
                        </Swiper>


                    </Stack>


                    {/* 2. Vidwo 视频 */}
                    <Stack>
                        <Stack sx={{
                            mt: 5,
                            width: videoWidth,
                            height: videoWidth * 0.53,
                            overflow: 'hidden',
                            display: 'flex',
                            alignItems: 'center',
                            borderRight: videoWidth * 0.015,
                            borderColor: '#5D43EA',
                        }}>

                            <Box
                                component={'video'}
                                autoPlay={true}
                                loop={true}
                                muted={true}
                                src={window.CDNHost + `/img/hero_video/${heroData.name}${heroData.skills[skillIndex]}.mp4`}
                                sx={{
                                    mb: 1,
                                    width: videoWidth * 1.15,
                                    height: 1,
                                    objectFit: 'cover',
                                }} />

                        </Stack>

                        <Box component={'img'} src={window.CDNHost + '/img/gif_bottom.png'} sx={{
                            width: videoWidth + videoWidth * 0.015,
                            alignSelf: 'bottom',
                        }} />

                        {/* 操作 Item */}
                        <Stack sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: 1,
                            height: videoWidth * 0.14,
                            mt: 2.5,
                            alignItems: 'center',
                        }}>
                            {heroData.skills.map((value, i) => {
                                return <VidwoPlayItem key={i} height={videoWidth * 0.14} index={i} selectIndex={skillIndex} skillTap={skillTap} />
                            })}

                            <Box sx={{
                                zIndex: 0,
                                width: videoWidth * 1.1,
                                height: '1px',
                                bgcolor: '#3A3AB3',
                                position: 'absolute',
                                ml: 0.7,
                                mt: '3px',
                            }} />
                        </Stack>

                    </Stack>

                </Stack>

            </Stack>


            <Box component={'img'}
                src={window.CDNHost + "/img/left_bt.png"}
                onClick={leftTap}
                sx={{
                    zIndex: 1000,
                    position: 'absolute',
                    width: '35px',
                    top: 0,
                    bottom: 0,
                    left: '11%',
                    margin: 'auto',
                    cursor: 'pointer',
                    transitionDuration: '0.3s',
                    '&:hover': {
                        transform: 'scale(1.1)',
                        filter: 'drop-shadow(1px 1px 6px #3159BE)  brightness(130%)',
                    },
                }} />


            <Box component={'img'}
                src={window.CDNHost + "/img/right_bt.png"}
                onClick={rightTap}
                sx={{
                    zIndex: 1000,
                    position: 'absolute',
                    width: '35px',
                    top: 0,
                    right: '11%',
                    bottom: 0,
                    margin: 'auto',
                    cursor: 'pointer',
                    transitionDuration: '0.3s',
                    '&:hover': {
                        transform: 'scale(1.1)',
                        filter: 'drop-shadow(1px 1px 6px #3159BE)  brightness(130%)',
                    },
                }} />

        </Stack>
    );
}

function HeroItem(props) {
    return (
        <Stack
            onClick={() => props.heroTap(props.index)}
            sx={{
                height: 1,
                width: 1,
                cursor: 'pointer',
                transitionDuration: '0.3s',
                '&:hover': {
                    transform: 'scale(1.1)',
                },
            }}>
            <Box component={'img'}
                src={window.CDNHost + `/img/hero_small/${props.value}_small.png`}
                sx={{
                    pointerEvents: 'none',
                    WebkitFilter: props.index == props.activeIndex ? 'brightness(100%)' : 'brightness(50%)',
                    filter: props.index == props.activeIndex ? 'brightness(100%)' : 'brightness(50%)',
                    width: '190%',
                    left: '-45%',
                    height: 1,
                    transform: 'skew(29deg)',
                    position: 'absolute',
                    overflow: 'visible',
                    transitionDuration: '0.3s',
                    objectFit: 'cover',
                    '&:hover': {
                        transform: 'scale(1.15)',
                    },
                }} />
        </Stack>
    );
}

function VidwoPlayItem(props) {
    const heroData = useSelector(state => state.heroData);

    function skillTap() {
        props.skillTap(props.index);
    }

    return (
        <Stack
            onClick={skillTap}
            sx={{
                cursor: 'pointer',
                zIndex: 1,
                width: props.height,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mr: 1,
                overflow: 'hidden',
            }}>

            <Box component={'img'}
                src={props.index == props.selectIndex ? window.CDNHost + '/img/hero_skill/jn_bg2.png' : window.CDNHost + '/img/hero_skill/jn_bg1.png'}
                sx={{
                    width: 1,
                }} />

            <Box component={'img'}
                src={window.CDNHost + `/img/hero_skill/${heroData.name}${heroData.skills[props.index]}.png`}
                sx={{
                    background: '#172145',
                    borderRadius: props.height * 0.75 / 2,
                    position: 'absolute',
                    mt: props.height * 0.82 * 0.013,
                    width: props.height * 0.75,
                    filter: props.index == props.selectIndex ? 'brightness(100%)' : 'brightness(50%)',
                    transitionDuration: '0.3s',
                    '&:hover': {
                        transform: 'scale(1.08)',
                    }
                }} />

        </Stack>
    );
}


//语言选项菜单
function LanguageView(props) {
    const [show, setShow] = React.useState(false);
    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);

    function onMouseOver() {
        setShow(true);
    }

    function onMouseLeave() {
        setShow(false);
    }

    function itemTap(k) {
        dispatch(settingActions.setLangKey(k));
        setShow(false);
    }


    return (
        <Stack
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
            sx={{
                position: 'relative',
            }}>

            <Stack
                direction={'row'}
                sx={{
                    alignItems: 'center',
                    width: '130px',
                    height: '40px',
                    lineHeight: '40px',
                    bgcolor: '#00000066',
                    color: 'white',
                    position: 'absolute',
                    zIndex: 200,
                    top: '25px',
                    right: '50px',
                    cursor: 'pointer',
                    textAlign: 'center',
                    justifyContent: 'center'
                }}>

                {settingData.langDic['lang43']}

            </Stack>

            {/* 下拉菜单 */}
            <Stack sx={{
                position: 'absolute',
                width: '130px',
                bgcolor: '#00000099',
                top: '65px',
                right: '50px',
                display: show ? 'flex' : 'none',
                zIndex: 210
            }}>
                <LanguageItem LKey="EN" title='ENGLISH' itemTap={itemTap} />
                <LanguageItem LKey="FR" title='FRANÇAIS' itemTap={itemTap} />
                <LanguageItem LKey="PT" title='PORTUGUÊS' itemTap={itemTap} />
                <LanguageItem LKey="DE" title='DEUTSCH' itemTap={itemTap} />
                <LanguageItem LKey="ES" title='ESPAÑOL' itemTap={itemTap} />
            </Stack>
        </Stack>
    );
}


function LanguageItem(props) {

    function onClick() {
        props.itemTap(props.LKey);
    }

    return (
        <Stack onClick={onClick}>
            <Typography sx={{
                color: 'white',
                textAlign: 'center',
                height: '40px',
                lineHeight: '40px',
                fontFamily: 'us',
                fontSize: { xs: 12, sm: 15, md: 15 },
                transitionDuration: '0.3s',
                cursor: 'pointer',
                '&:hover': {
                    transform: 'scale(1.15)',
                }
            }}>
                {props.title}
            </Typography>
            <Box component={'div'} sx={{
                height: '1px',
                bgcolor: '#35313566',
            }} />

        </Stack>
    );
}
