import { Stack, Box, Typography, Backdrop, CircularProgress, Snackbar } from '@mui/material';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { settingActions, settingObj } from '../../store/reducers/SettingSlice';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Mousewheel } from 'swiper/modules';
import Page0 from './Page0';
import Page1 from './Page1';
import Page2 from './Page2';
import { TWRid, TWSid } from '../../js/StringTool';
import * as StringTool from '../../js/StringTool';
import SignDialog from '../../components/SignDialog';
import * as rdd from 'react-device-detect';


export default function BindTwitch(props) {

    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);
    const [swp, setSwp] = React.useState(null);
    const [isMobile, setIsMobile] = React.useState(false);

    React.useEffect(() => {
        if (rdd.isMobile || window.innerWidth < 700) {
            setIsMobile(true);
        }
    }, []);

    function linkTap() {
        if (swp) {
            swp.slideTo(1);
        }
    }

    function checkRoleAlert(force) {
        if ((swp && swp.activeIndex == 1) || force) {
            const rid = StringTool.getLocalString(TWRid);
            const sid = StringTool.getLocalString(TWSid);

            if (!StringTool.isEmpty(sid)) {
                dispatch(settingActions.setServiceId(sid));
            }
            if (!StringTool.isEmpty(rid)) {
                dispatch(settingActions.setRoleId(rid));
            }

            if (StringTool.isEmpty(rid) && settingObj.opmData) {
                dispatch(settingActions.showRoleAlert(true));
            }
        }
    }

    return (
        <Stack
            sx={{
                width: 1,
                height: window.innerHeight,
            }}>
            <Swiper
                direction={'vertical'}
                slidesPerView={1}
                mousewheel={true}
                className="mySwiper"
                modules={[Mousewheel]}
                resistanceRatio={0}

                onSwiper={(sp) => {
                    setSwp(sp);

                    setTimeout(() => {
                        if (!StringTool.isEmpty(settingObj.twitchCode)) {
                            sp.slideTo(1);
                        }
                    }, 1000);
                }}

                onActiveIndexChange={(sp) => {
                    checkRoleAlert();
                }}

                style={{
                    width: '100%',
                    height: '100%',
                }}>

                <SwiperSlide style={{ overflow: 'hidden', width: '100%', height: '100%' }}><Page0 linkTap={linkTap} isMobile={isMobile}/></SwiperSlide>
                <SwiperSlide style={{ overflow: 'hidden', width: '100%', height: '100%' }}><Page1 checkRoleAlert={checkRoleAlert} isMobile={isMobile}/></SwiperSlide>
                <SwiperSlide style={{ overflow: 'hidden', width: '100%', height: '100%' }}><Page2 isCR={false} isMobile={isMobile}/></SwiperSlide>
            </Swiper>

            {/* loading view */}
            <Backdrop
                sx={{ color: '#fff', zIndex: 99999 }}
                open={settingData.showLoading}
                onClick={() => { }}>
                <CircularProgress color="inherit" />
            </Backdrop>


            {/* 提示 */}
            <Snackbar open={!StringTool.isEmpty(settingData.showToast)} autoHideDuration={1500} onClose={() => {
                dispatch(settingActions.showToast(null));
            }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}>
                <Box
                    sx={{
                        minWidth: '250px',
                        height: '45px',
                        lineHeight: '45px',
                        borderRadius: '5px',
                        bgcolor: '#ffffff',
                        color: 'black',
                        textAlign: 'center',
                        px: 3,
                    }}>
                    {settingData.showToast}
                </Box>
            </Snackbar>

            <SignDialog />
        </Stack>
    );
}

