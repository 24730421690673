import { Box, Stack, Typography } from '@mui/material';
import * as React from 'react';

export default function Welcome(props) {
    let scrolled = false;

    React.useEffect(() => {
        window.addEventListener('wheel', scrollFunc);
    }, []);

    const scrollFunc = (e) => {
        if (e.wheelDelta < 0 && !scrolled) {
            scrolled = true;
            window.removeEventListener('wheel', scrollFunc);
            props.onFinish && props.onFinish(3);
        }
    }

    return (
        <Stack sx={{
            width: 1,
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            bgcolor: 'black',
        }}>
            <Box component={'img'}
                src={window.CDNHost+'/img/video1.jpg'}
                sx={{
                    width: 1,
                    height: 1,
                    objectFit: 'cover',
                    position: 'absolute',
                }} />

            <Box
                component={'video'}
                autoPlay={true}
                loop={true}
                muted={true}
                src={window.CDNHost+'/img/video1.mp4'}
                sx={{
                    width: 1,
                    height: 1,
                    objectFit: 'cover',
                    position: 'absolute',
                }} />

            <Stack direction={'column'}
                sx={{
                    position: 'absolute',
                    width: '200px',
                    height: '180px',
                    bottom: 0,
                    alignItems: 'center',
                    animation: 'scrollerAni 1.5s ease-in-out both infinite',
                }}>

                <Typography sx={{
                    color: 'white',
                    fontSize: 12,
                }}>
                    SCROLL
                </Typography>

                <Box component={'img'} src={window.CDNHost+'/img/down_icon.png'}
                    sx={{
                        width: '45px',
                        mt: 2,
                    }} />
                <Box component={'img'} src={window.CDNHost+'/img/down_icon.png'}
                    sx={{
                        width: '45px',
                        mt: 1
                    }} />
                <Box component={'img'} src={window.CDNHost+'/img/down_icon.png'}
                    sx={{
                        width: '45px',
                        mt: 1,
                    }} />
            </Stack>

        </Stack>
    );

}