import { Box, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { settingActions, settingObj } from '../store/reducers/SettingSlice';
import * as StringTool from '../js/StringTool';
import { dark } from '@mui/material/styles/createPalette';
import * as APJRequest from '../js/APJRequest';

export default function PreRegister(props) {

    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);
    const [percent, setPercent] = React.useState(0.714);
    const [progressWidth, setProgressWidth] = React.useState(0.0);
    const [isBig, setBig] = React.useState(false);

    const imgAry = ['jiangpin500k', 'jiangpin1m', 'jiangpin1.5m', 'jiangpin2m', 'jiangpin3m', 'jiangpin6m', 'jiangpin10m'];
    const unitAry = ['zi_500k', 'zi_1m', 'zi_1.5m', 'zi_2m', 'zi_3m', 'zi_6m', 'zi_10m'];
    const txtAry = ['Energy × 50<br/>World Silver × 60', 'Energy × 100<br/>World Silver × 80<br/>Regular Draw Ticket × 1', 'Energy × 120<br/>World Silver × 120<br/>Regular Draw Ticket × 2<br/>Namecard Background × 1', 'Energy × 150<br/>World Silver × 140<br/>Regular Draw Ticket × 3<br/>Random SR Impression Arms × 1', 'Energy × 180<br/>World Silver × 200<br/>Regular Draw Ticket × 4<br/>SR Character: Saitama Job Hunting × 1', 'World Silver × 200<br/>Lv.3 EXP Card × 20<br/>Credits × 30000<br/>Level 1-3 Loot Box × 2<br/>Level 1-3 Intel Box × 4', 'World Silver × 500<br/>Namecard Background × 1<br/>Sticker × 1<br/>Avatar × 1'];

    React.useEffect(() => {
        updateUI();
        window.addEventListener(
            'resize',
            () => {
                updateUI();
            }
        );

    }, []);

    function getGif(p) {
        if (p >= 0.875) {
            return window.CDNHost + '/img/pre_register/jindu_4m5m.gif';
        } else if (p >= 0.714) {
            return window.CDNHost + '/img/pre_register/jindu_4m5m.gif';
        } else if (p >= 0.571) {
            return window.CDNHost + '/img/pre_register/jindu_4m5m.gif';
        } else if (p >= 0.428) {
            return window.CDNHost + '/img/pre_register/jindu2m.gif';
        } else if (p >= 0.286) {
            return window.CDNHost + '/img/pre_register/jindu1.5m.gif';
        } else if (p >= 0.143) {
            return window.CDNHost + '/img/pre_register/jindu500k.gif';
        } else if (p >= 0.07) {
            return window.CDNHost + '/img/pre_register/jindu1m.gif';
        } else {
            return window.CDNHost + '/img/pre_register/jindu500k.gif';
        }
    }

    function updateUI() {
        if (window.innerWidth >= 2000) {
            setBig(true);
        }

        let p = StringTool.getPercent();
        setPercent(p);

        let x = window.innerWidth * 0.87;
        if (x < 1150) {
            x = 1150;
        } else if (x > 1800) {
            x = 1800;
        }

        let w = p * x * 1.12;
        setProgressWidth(w);
    }


    function signTap() {
        // dispatch(settingActions.showSignDalog());
        APJRequest.openPcDownload();
    }

    return (
        <Stack
            sx={{
                width: 1,
                flex: 1,
                overflow: 'hidden',
                alignItems: 'center',
            }}>

            <Box
                component={'img'}
                src={window.CDNHost + '/img/pre_register/bg.png'}
                sx={{
                    position: 'absolute',
                    width: 1,
                    height: 1,
                    objectFit: 'cover',
                }} />

            <Box
                component={'video'}
                autoPlay={true}
                loop={true}
                muted={true}
                src={window.CDNHost + '/img/pre_register/bg.mp4'}
                sx={{
                    position: 'absolute',
                    width: 1,
                    height: 1,
                    objectFit: 'cover',
                }} />

            <Stack sx={{
                position: 'absolute',
                width: 0.87,
                minWidth: '1150px',
                maxWidth: '1800px',
                height: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}>

                {/* 标题 */}
                <Stack sx={{
                    position: 'relative',
                    width: window.innerWidth,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <Typography sx={{
                        position: 'absolute',
                        fontSize: isBig ? 130 : 95,
                        color: '#ffffff11',
                        fontFamily: 'us',
                        animation: 'upToBottom .7s',
                    }}>
                        {settingData.langDic['lang55']}
                    </Typography>

                    <Typography sx={{
                        fontSize: isBig ? 100 : 74,
                        color: 'white',
                        mt: '40px',
                        zIndex: 1,
                        fontFamily: 'us',
                        animation: 'upToBottom .6s',
                    }}>
                        {settingData.langDic['lang55']}
                    </Typography>
                </Stack>

                {/* 图片 */}
                <Stack sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: 1,
                    animation: 'upToBottom 1s',
                    mt: isBig ? 7 : 0,
                }}>
                    {
                        imgAry.map((value, i) => {
                            return (<ImageItem isBig={isBig} index={i} percent={percent} key={value} name={imgAry[i]} txt={txtAry[i]} unit={unitAry[i]} />);
                        })
                    }
                </Stack>

                {/* 进度条 */}
                <Stack sx={{
                    width: 1,
                    height: '70px',
                    mt: '20px',
                    justifyContent: 'center',
                    position: 'relative',
                    mt: isBig ? 5 : 2,
                    animation: 'homeFootAni 1s',
                }}>
                    <Box component={'img'} src={window.CDNHost + '/img/pre_register/jindutiao_bg_jiachang.png'}
                        sx={{
                            height: isBig ? '35px' : '30px',
                        }} />

                    <Stack sx={{
                        width: `${progressWidth}px`,
                        height: 1,
                        position: 'absolute',
                        justifyContent: 'center',
                        mt: 1.7,
                        overflow: 'hidden',
                        ml: 0.2,
                    }}>


                        <Box component={'img'} src={getGif(percent)}
                            sx={{
                                position: 'absolute',
                                right: 0,
                                height: isBig ? '220px' : '200px',
                            }} />

                    </Stack>
                </Stack>

                {/* 单位 */}
                <Stack sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: 1,
                    mb: props.isShare ? 20 : (isBig ? 10 : 6),
                    animation: 'homeFootAni 1s',
                }}>
                    {
                        unitAry.map((value, i) => {
                            return (
                                <Box
                                    key={value}
                                    component={'img'}
                                    src={window.CDNHost + `/img/pre_register/${value}.png`}
                                    sx={{
                                        mt: 2,
                                        height: isBig ? '33px' : '22px',
                                        width: 1,
                                        objectFit: 'contain'
                                    }} />
                            );
                        })
                    }
                </Stack>

                {/* 按钮 */}
                <Stack sx={{
                    position: 'absolute',
                    bottom: '0px',
                    width: 1,
                    height: isBig ? '180px' : '100px',
                    alignItems: 'center',
                    animation: 'homeFootAni 1s',
                    mb: props.isShare ? 5 : 0
                }}>
                    <Box
                        onClick={signTap}
                        component={'span'}
                        sx={{
                            cursor: 'pointer',
                            background: `url(${window.CDNHost}/img/btn.png) no-repeat`,
                            backgroundSize: '100% 100%',
                            color: 'white',
                            pl: 6,
                            pr: 7.5,
                            py: 0.2,
                            height: '70px',
                            fontSize: 30,
                            alignItems: 'center',
                            textAlign: 'center',
                            lineHeight: '60px',
                            animation: 'homeBtAni 3s ease-in-out both infinite',
                            '&:hover': {
                                color: '#FFFF84',
                            }
                        }}>
                        {settingData.langDic['lang61']}
                    </Box>
                </Stack>

            </Stack>
        </Stack>
    );
}


function ImageItem(props) {
    return (
        <Stack
            key={Math.random()}
            sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                width: 1,
            }}>

            <Box component={'img'}
                src={props.percent >= (props.index + 1) * 0.142 ? window.CDNHost + `/img/pre_register/${props.name}.png` : window.CDNHost + `/img/pre_register/${props.name}_2.png`}
                sx={{
                    width: props.isBig ? '230px' : '160px'
                }} />
            <Box
                component={'div'}
                sx={{
                    textAlign: 'center',
                    color: 'white',
                    fontSize: 14,
                }}
                dangerouslySetInnerHTML={{
                    __html:
                        props.txt
                }} />
        </Stack>
    );
}



