import { Box, Stack, Typography } from '@mui/material';
import * as React from 'react';
import VideoFooter from './VideoFooter';

import Character from './Character';
import HeroDetail from './HeroDetail';

export default function CharacterContainer() {

    const [page, setPage] = React.useState(0);

    function pageChange(page) {
        setPage(page);
    }

    return (
        <Stack
            sx={{
                zIndex: 0,
                display: 'flex',
                alignItems: 'center',
                width: 1,
                flex: 1,
                overflow: 'visible',
                position: 'relative',
                justifyContent: 'center',
            }}>

            <Box
                component={'img'}
                src={window.CDNHost+'/img/bg2.png'}
                sx={{
                    position: 'absolute',
                    width: 1,
                    height: 1,
                    objectFit: 'cover',
                }} />

            {page == 0 && <Character pageChange={pageChange} />}
            {page == 1 && <HeroDetail pageChange={pageChange} />}

            <VideoFooter />

        </Stack>

    );
}


function Item(props) {
    return (
        <Stack sx={{
            width: 1,
            height: 1,
            position: 'absolute',
            top: props.index % 2 == 0 ? '0' : '-20px',
            left: props.index % 2 == 0 ? '-13%' : '-10%',
            transitionDuration: '0.3s',
            '&:hover': {
                transform: 'scale(1.1)',
                filter: 'drop-shadow(2px 4px 15px #745ff2)',
            },
            animation: props.index % 2 == 0 ? 'heroBottomToUp 1s' : 'heroUpToBottom 1s',
        }}>

            <Box component={'img'}
                src={window.CDNHost+`/img/hero${props.value}.png`}
                sx={{
                    width: 1,
                    height: 1,
                    position: 'absolute',
                    overflow: 'visible',
                    objectFit: 'cover',
                }} />

            <Box component={'span'} sx={{
                position: 'absolute',
                bottom: 0,
                color: 'white',
                fontSize: { xs: 12, md: 12, lg: 16 },
                fontWeight: 'bold',
                py: '7px',
                width: 1,
                backgroundColor: '#000000bb',
                // left: '-19%',
                right: '36%',
                width: '83%',
                bottom: '1.9%',
                textAlign: 'center',
                transform: 'skew(-19.7deg)',
            }}>
                Tom Cat
            </Box>

        </Stack>
    );
}