import { Box, Stack, Typography, Drawer } from '@mui/material';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import MobileMenu from './MobileMenu';
import MobileLanguage from './MobileLanguage';
import { settingActions } from '../../store/reducers/SettingSlice';


export default function MobileNav(props) {

    const settingData = useSelector(state => state.settingData);
    const [openMenu, setOpenMenu] = React.useState(false);
    const [openLanguage, setOpenLanguage] = React.useState(false);

    const dispatch = useDispatch();
    const [count, setCount] = React.useState(0);
    const [myTimer, setMyTimer] = React.useState(null);


    function menuTap() {
        setOpenMenu(true);
    }

    function closeMenu() {
        setOpenMenu(false);
    }

    function languageTap() {
        setOpenLanguage(true);
    }

    function closeLanguage() {
        setOpenLanguage(false);
    }

    function musicAni() {
        setCount(prevCount => {
            if (prevCount >= 29) {
                return 0;
            }
            return prevCount + 1;
        });
    }

    function musicTap() {
        dispatch(settingActions.musicTap());
        if (myTimer == null) {
            setMyTimer(setInterval(function () { musicAni() }, 70));
        } else {
            clearInterval(myTimer);
            setMyTimer(null);
        }
    }


    return (
        <Stack sx={{
            display: 'flex',
            flexDirection: 'row',
            position: 'absolute',
            width: 1,
            height: '60px',
            zIndex: 10,
            alignItems: 'center',
            bgcolor: '#00000030'
        }}>

            <Box component={'img'}
                src={window.CDNHost + '/img/menu.png'}
                onClick={menuTap}
                sx={{
                    width: '30px',
                    height: '30px',
                    ml: 2,
                    display: settingData.mobileSwpIndex == 0 ? 'none' : 'flex',
                }} />

            <Box component={'img'}
                src={window.CDNHost + '/img/logo.png'}
                sx={{
                    position: 'absolute',
                    height: '30px',
                    ml: 2,
                    display: settingData.mobileSwpIndex != 0 ? 'none' : 'flex',
                }} />

            <Box sx={{ flex: 1, }} />

            {/* 购物车 */}
            <Box
                onClick={() => {
                    window.open('https://eu.onepunchmanworld.com/productStoreEU')
                }}
                component={'img'}
                src={window.CDNHost + '/img/payment/shop_car.png'}
                sx={{
                    height: '26px',
                    cursor: 'pointer',
                    mr: 2,
                }} />


            {/* 音乐 */}
            <Box component={'img'}
                onClick={musicTap}
                src={settingData.playingMusic ? window.CDNHost + `/img/music_gif/_000${count}.png` : window.CDNHost + '/img/music_stop.png'}
                sx={{
                    zIndex: 100,
                    cursor: 'pointer',
                    height: '40px',
                    width: '40px',
                    mr: 2,
                    animation: settingData.playingMusic ? 'musicRotate 3s linear infinite' : '',
                }} />

            <Typography
                onClick={languageTap}
                sx={{
                    color: 'white',
                    mr: 2,
                    fontSize: 18,
                    fontFamily: 'us',
                }}>
                {settingData.langDic['lang43']}
            </Typography>

            <Drawer
                open={openMenu}
                onClose={closeMenu}>
                <MobileMenu closeMenu={closeMenu} slideTo={props.slideTo} />
            </Drawer>

            <Drawer
                anchor={'right'}
                open={openLanguage}
                onClose={closeLanguage}>
                <MobileLanguage closeLanguage={closeLanguage} />
            </Drawer>

        </Stack>

    );
}