import { Box, Stack } from '@mui/material';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as APJRequest from '../../js/APJRequest';

export default function MobileHome(props) {

    const settingData = useSelector(state => state.settingData);

    return (
        <Stack sx={{
            height: 1,
            bgcolor: 'black',
        }}>
            {/* 背景 */}
            <Box component={'img'}
                src={window.CDNHost + '/img/mobile/m_bg1.jpg'}
                sx={{
                    zIndex: 0,
                    position: 'absolute',
                    width: 1,
                    height: 1,
                    objectFit: 'cover',
                }} />


            {/* 下载 */}
            <Stack sx={{
                position: 'absolute',
                bottom: '80px',
                width: 1,
                alignItems: 'center',
            }}>

                <Box component={'img'}
                    src={`${window.CDNHost}/img/share/slg_${settingData.langKey}_release.png`}
                    sx={{
                        width: 0.9,
                        mb:3
                    }} />

                <Stack sx={{
                    display: 'flex',
                    flexDirection: 'row',
                }}>
                    <Box component={'img'}
                        onClick={() => { APJRequest.openApple(props.adPlatform) }}
                        src={`${window.CDNHost}/img/btn_down1_release.png`}
                        sx={{
                            cursor: 'pointer',
                            width: '150px',
                            height: '45px',
                            border: 1,
                            borderRadius: 2,
                            borderColor: '#A1A1A0',
                            mr: 0.5,
                        }} />


                    <Box component={'img'}
                        onClick={() => { APJRequest.openGoogle(props.adPlatform) }}
                        src={`${window.CDNHost}/img/btn_down2_release.png`}
                        sx={{
                            cursor: 'pointer',
                            width: '150px',
                            height: '45px',
                            ml: 0.5,
                            border: 1,
                            borderRadius: 2,
                            borderColor: '#A1A1A0',
                        }} />


                </Stack>
            </Stack>

            {/* 底部提示 */}
            <Stack direction={'column'}
                onClick={() => { props.slideTo(1) }}
                sx={{
                    position: 'absolute',
                    width: 1,
                    height: '45px',
                    bottom: 0,
                    alignItems: 'center',
                    animation: 'scrollerAni 2s ease-in-out both infinite',
                }}>
                <Box component={'img'} src={window.CDNHost + '/img/down_icon.png'}
                    sx={{
                        width: '30px',
                    }} />
                <Box component={'img'} src={window.CDNHost + '/img/down_icon.png'}
                    sx={{
                        width: '30px',
                    }} />
            </Stack>

            <Box component={'audio'}
                src={window.CDNHost + '/img/bgm.mp3'}
                loop={true}
                id='bg_music'
                preload={'auto'} />

        </Stack>
    );
}