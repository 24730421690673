import { Box, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactPlayer from 'react-player';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCreative, EffectCoverflow } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-creative';
import MobileDownFooter from './MobileDownFooter';
import * as StringTool from '../../js/StringTool';

export default function MobileVideo() {

    const urls = [ "https://www.youtube.com/watch?v=JOnUwaVII2o", "https://www.youtube.com/watch?v=QRGumaYQwRw","https://www.youtube.com/watch?v=366RXzYWpC4", "https://www.youtube.com/watch?v=70e5Do6TBMg", "https://www.youtube.com/watch?v=a6FtktghUG4"];
    const images = [window.CDNHost+"/img/videos/img3.jpeg",window.CDNHost+"/img/videos/img4.jpeg",window.CDNHost+"/img/videos/img1.png", window.CDNHost+"/img/videos/img2.jpg", window.CDNHost+"/img/videos/img0.png"];

    const X = 0.5625;
    const [videoWidth, setVideoWidth] = React.useState(window.innerWidth * 0.95);

    const settingData = useSelector(state => state.settingData);
    const [activeIndex, setActiveIndex] = React.useState(1);
    const [videoUrl, setVideoUrl] = React.useState(urls[1]);
    const [swp, setSwp] = React.useState(null);

    function imageTap(i) {
        setActiveIndex(i);

        const x = urls[i];
        if (!StringTool.isEmpty(x)) {
            setVideoUrl(x);
        }
        swp.slideTo(i);
    }

    function indexChanged() {
        if (swp != null) {
            if (activeIndex != swp.activeIndex) {
                imageTap(swp.activeIndex);
            }
        }
    }

    return (
        <Stack sx={{
            height: 1,
            display: 'flex',
            justifyContent: 'center',
            bgcolor: '#1B202E',
        }}>
            {/* 背景 */}
            <Box component={'img'}
                src={window.CDNHost+'/img/mobile/m_bg3.jpg'}
                sx={{
                    zIndex: 0,
                    position: 'absolute',
                    width: 1,
                    height: 1,
                    objectFit: 'cover',
                }} />

            <Typography sx={{
                zIndex: 5,
                color: 'white',
                fontFamily: 'us',
                fontSize: 32,
                ml: 3,
                mb: 1,
            }}>
                {settingData.langDic['lang3']}
            </Typography>

            {/* 视频 */}
            <ReactPlayer
                width={`${videoWidth}px`}
                height={`${videoWidth * X}px`}
                url={videoUrl}
                controls={true}
                light={false}
                volume={0.6}
                style={{
                    zIndex: 3,
                    alignSelf: 'center',
                }} />

            {/* swiper */}



            {/* <Swiper
                loop={true}
                grabCursor={true}
                effect={'creative'}
                style={{
                    marginTop: '30px',
                    width: `${videoWidth - 140}px`,
                    height: `${(videoWidth - 140) * X}px`,
                    paddingLeft: '70px',
                    paddingRight: '70px',
                }}
                creativeEffect={{
                    prev: {
                        shadow: true,
                        translate: ['-50%', 50, -500],
                    },
                    next: {
                        shadow: true,
                        translate: ['50%', 50, -500],
                    },
                    current: {
                        translate: [0, -50, 0],
                    }
                }}
                modules={[EffectCreative]}
                className="mySwiper"> */}

            <Swiper
                modules={[EffectCoverflow]}
                effect={'coverflow'}
                grabCursor={true}

                onSwiper={(sp) => {
                    setSwp(sp);
                    sp.activeIndex = 1;
                }}

                onActiveIndexChange={(sp) => {
                    indexChanged();
                }}

                coverflowEffect={{
                    rotate: 0,
                    stretch: 30,
                    depth: 500,
                    modifier: 1,
                    slideShadows: true,
                }}

                style={{
                    zIndex: 3,
                    marginTop: '30px',
                    width: `${videoWidth - 180}px`,
                    height: `${(videoWidth - 180) * 0.52}px`,
                    paddingLeft: '70px',
                    paddingRight: '70px',
                    marginBottom: '30px',
                }}>

                {
                    images.map((value, i) => {
                        return <SwiperSlide key={i}
                            style={{
                                display: 'flex',
                                background: 'black',
                            }}>
                            <Item value={value} index={i} activeIndex={activeIndex} imageTap={imageTap} />
                        </SwiperSlide>
                    })
                }

            </Swiper>

            <Stack sx={{
                position: 'absolute',
                width: 1,
                bottom: 0,
                mb: 2,
            }}>
                <MobileDownFooter />
            </Stack>
        </Stack>
    );
}

function Item(props) {
    return (
        <Stack onClick={() => props.imageTap(props.index)} sx={{
            border: 0.1,
            width: 1,
            opacity: props.index == props.activeIndex ? 1 : 0.5,
            overflow: 'hidden',
        }}>
            <Box component={'img'}
                src={props.value != "" ? props.value : window.CDNHost+'/img/videos/default.png'}
                sx={{
                    objectFit: 'cover',
                    height: 1,
                    filter: props.index == props.activeIndex ? 'brightness(110%)' : 'brightness(70%)',
                }} />
        </Stack>
    );
}