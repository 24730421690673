import { Stack, Box, Typography, Dialog } from '@mui/material';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { settingActions } from '../../store/reducers/SettingSlice';

export default function BindTwitchLoginAlertCR(props) {
    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);


    function closeTap() {
        dispatch(settingActions.showTwitchLogin(false));
    }


    return (
        <Dialog
            PaperProps={{
                style: {
                    boxShadow: 'none',
                    position: 'relative',
                    borderRadius: '15px'
                },
            }}
            maxWidth={'md'}
            open={settingData.showTwitchLogin}>

            <Stack sx={{
                width: '310px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                pt:3,
                pb:5,
            }}>

                <Box
                    onClick={closeTap}
                    component={'img'}
                    src={window.CDNHost + '/img/close_black.png'}
                    sx={{
                        position: 'absolute',
                        zIndex: 100,
                        width: '15px',
                        height: '15px',
                        top: 0,
                        m: 2,
                        right: 0,
                        cursor: 'pointer'
                    }} />

                    <Typography sx={{
                        fontWeight: 'bold',
                    }}>
                        Login OPMW Account
                    </Typography>

                <LoginButton isMobile={props.isMobile} index={0} imgName='crunchyroll.png' title='Crunchyroll Login' />
                <LoginButton isMobile={props.isMobile} index={1} imgName='google_icon.png' title='Google Login' />
                <LoginButton isMobile={props.isMobile} index={2} imgName='apple.png' title='Apple Login' />



            </Stack>

        </Dialog>
    );
}




function LoginButton(props) {

    function loginTap() {
        if (props.index == 0) {
            window.crLoginTap();

        } else if (props.index == 1) {
            window.openGoogleCR();

        } else if (props.index == 2) {
            window.openAppleCR();

        }
    }

    return (
        <Stack
            onClick={loginTap}
            sx={{
                flexDirection: 'row',
                width: props.isMobile ? 0.7 : '200px',
                height: '40px',
                color: 'black',
                bgcolor: 'white',
                alignItems: 'center',
                mt: 3,
                borderRadius: '20px',
                cursor: 'pointer',
                border: 1,
                borderColor: 'lightgray'
            }}>

            <Box component={'img'}
                src={window.CDNHost + '/img/' + props.imgName}
                sx={{
                    height: '30px',
                    width: '30px',
                    ml: 3,
                    mr: 1,
                }} />

            <Typography sx={{
                fontSize: props.isMobile ? 13 : 14,
            }}>
                {props.title}
            </Typography>

        </Stack>
    );

}