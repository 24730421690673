import * as React from 'react';
import { Stack } from '@mui/material';
import LoadingComponent from '../../components/LoadingComponent';
import LoadingAnimate from '../../components/LoadingAnimate';
import { useSelector, useDispatch } from 'react-redux';
import { settingActions } from '../../store/reducers/SettingSlice';
import MobileMain from './MobileMain';

function MobileApp() {

    const settingData = useSelector(state => state.settingData);
    const dispatch = useDispatch();

    function onFinish(index) {
        dispatch(settingActions.setLoadingIndex(index));
    }

    return (
        <Stack>
            {settingData.loadingIndex == 0 && <LoadingComponent onFinish={onFinish} />}
            {settingData.loadingIndex == 1 && <LoadingAnimate onFinish={onFinish} />}
            {settingData.loadingIndex == 2 && <MobileMain />}
        </Stack>
    );
}

export default MobileApp;
