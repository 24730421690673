import { Box, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { settingActions, settingObj } from '../store/reducers/SettingSlice';
import * as StringTool from '../js/StringTool';
import * as APJRequest from '../js/APJRequest';
import HomeFooter from './HomeFooter';

export default function Home(props) {

    const settingData = useSelector(state => state.settingData);
    const [count, setCount] = React.useState(2);

    React.useEffect(() => {
        if (settingObj.mainOpened && !settingObj.forceAni) {
            setCount(3);
        } else {
            setTimeout(() => {
                setCount(3);
            }, 2000);
        }
    }, []);

    return (
        <Stack sx={{
            width: 1,
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            bgcolor: 'black',
        }}>

            <Box component={'img'} src={window.CDNHost+'/img/video3.jpg'} sx={{
                width: 1,
                height: 1,
                objectFit: 'cover',
                position: 'absolute',
            }} />

            <Box
                component={'video'}
                autoPlay={true}
                loop={false}
                muted={true}
                src={window.CDNHost+`/img/video2.mp4`}
                sx={{
                    position: 'absolute',
                    width: 1,
                    height: 1,
                    objectFit: 'cover',
                    display: settingObj.mainOpened && !settingObj.forceAni ? 'none' : 'flex',
                }} />

            <Box
                component={'video'}
                autoPlay={true}
                loop={true}
                muted={true}
                src={window.CDNHost+`/img/video3.mp4`}
                sx={{
                    position: 'absolute',
                    width: 1,
                    height: 1,
                    objectFit: 'cover',
                    animation: count == 3 ? 'homeVideoShow 0.3s' : 'homeVideoHide',
                    display: count == 3 ? 'flex' : 'none',
                }} />

            <Box component={'img'}
                onClick={props.backTap}
                src={window.CDNHost+'/img/back2.png'}
                sx={{
                    position: 'absolute',
                    top: '150px',
                    left: '50px',
                    animation: settingData.mainOpened ? '' : 'navAni 1.5s',
                    cursor: 'pointer',
                    transitionDuration: '0.3s',
                    '&:hover': {
                        transform: 'scale(1.1)',
                        filter: 'drop-shadow(1px 1px 6px #3159BE)  brightness(130%)',
                    },
                }} />

            {count == 3 && <HomeFooter />}

        </Stack>
    );

}
