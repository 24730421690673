import { Stack, Drawer, Box, Typography } from '@mui/material';
import * as React from 'react';

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Mousewheel } from 'swiper/modules';
import { useSelector, useDispatch } from 'react-redux';
import { settingActions } from '../../store/reducers/SettingSlice';
import * as APJRequest from '../../js/APJRequest';

import ShareMobileHome from './ShareMobileHome';
import MobilePreRegister from './MobilePreRegister';
import MobileHero from './MobileHero';
import MobileRegister from './MobileRegister';
import MobileLanguage from './MobileLanguage';
import { useParams, useLocation, useSearchParams } from 'react-router-dom'


export default function ShareMobilePage(props) {

    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);
    const [swp, setSwp] = React.useState(null);
    const [searchParams, setSearchParams] = useSearchParams();

    const [adPlatform, setAdPlatform] = React.useState('');


    React.useEffect(() => {
        const pt = searchParams.get('adPlatform');
        setAdPlatform(pt);
    }, []);


    function closeRegister() {
        dispatch(settingActions.closeSignDalog());
    }

    function indexChanged() {
        dispatch(settingActions.setMobileSwpIndex(swp.activeIndex));
    }

    function slideTo(i) {
        swp.slideTo(i);
    }

    return (
        <Stack
            key={adPlatform}
            sx={{
                height: window.innerHeight,

            }}>
            <Swiper
                direction={'vertical'}
                slidesPerView={1}
                mousewheel={true}
                className="mySwiper"
                modules={[Mousewheel]}
                resistanceRatio={0}

                onSwiper={(sp) => {
                    setSwp(sp);
                    sp.activeIndex = settingData.mobileSwpIndex;
                }}

                onActiveIndexChange={(sp) => {
                    indexChanged();
                }}

                style={{
                    width: '100%',
                    background: 'white',
                }}>

                <SwiperSlide style={{ overflow: 'hidden' }}><ShareMobileHome adPlatform={adPlatform} slideTo={slideTo} /></SwiperSlide>
                <SwiperSlide style={{ backgroundColor: 'yellow' }}><ShareHeroWrapper adPlatform={adPlatform}/></SwiperSlide>
            </Swiper>

            {/* 导航 */}
            <ShareMobileNav />

            <Drawer
                anchor={'bottom'}
                open={settingData.openSign}
                onClose={closeRegister}
                PaperProps={{
                    style: {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                    },
                }}>
                <MobileRegister closeRegister={closeRegister} />
            </Drawer>

        </Stack>
    );
}

function ShareHeroWrapper(props) {
    return (
        <Stack sx={{
            height: 1,
            overflow: 'auto'
        }}>
            <Stack sx={{
                height: '100vh',
                bgcolor: 'black'
            }}>
                <MobileHero />
            </Stack>

            <ShareFooter adPlatform={props.adPlatform} />
        </Stack>
    );
}

function ShareMobileNav(props) {
    const settingData = useSelector(state => state.settingData);
    const [openLanguage, setOpenLanguage] = React.useState(false);

    function closeLanguage() {
        setOpenLanguage(false);
    }

    function languageTap() {
        setOpenLanguage(true);
    }

    return (
        <Stack sx={{
            display: 'flex',
            flexDirection: 'row',
            position: 'absolute',
            width: 1,
            height: '60px',
            zIndex: 10,
            alignItems: 'center',
            bgcolor: '#00000030',
            display: settingData.mobileSwpIndex > 0 ? 'none' : 'flex',
        }}>

            <Box component={'img'}
                src={window.CDNHost + '/img/logo.png'}
                sx={{
                    position: 'absolute',
                    height: '30px',
                    ml: 2,
                }} />


            <Typography
                onClick={languageTap}
                sx={{
                    position: 'absolute',
                    zIndex: 10,
                    color: 'white',
                    fontSize: 18,
                    fontFamily: 'us',
                    right: '30px',
                    height: '60px',
                    textAlign: 'center',
                    lineHeight: '60px',
                }}>
                {settingData.langDic['lang43']}
            </Typography>

            <Drawer
                anchor={'right'}
                open={openLanguage}
                onClose={closeLanguage}>
                <MobileLanguage closeLanguage={closeLanguage} />
            </Drawer>

        </Stack>

    );
}


function ShareFooter(props) {
    function privacyTap() {
        APJRequest.openPrivacy();
    }

    function termTap() {
        APJRequest.openTerm();
    }

    function serviceTap() {//客服表单
        APJRequest.openFAQ();
    }

    return (
        <Stack sx={{
            width: 1,
            alignItems: 'center',
            zIndex: 200,
            bgcolor: 'black'
        }}>

            {/* 下载按钮 */}
            <Stack sx={{
                display: 'flex',
                flexDirection: 'row',
                mt: 2,
                alignItems: 'center',
            }}>
                <Box component={'img'}
                    onClick={() => { APJRequest.openApple(props.adPlatform) }}
                    src={`${window.CDNHost}/img/btn_down1_release.png`}
                    sx={{
                        cursor: 'pointer',
                        height: '40px',
                        border: 1,
                        borderRadius: 2,
                        borderColor: '#A1A1A0',
                        mr: 0.5,
                    }} />


                <Box component={'img'}
                    onClick={() => { APJRequest.openGoogle(props.adPlatform) }}
                    src={`${window.CDNHost}/img/btn_down2_release.png`}
                    sx={{
                        cursor: 'pointer',
                        height: '40px',
                        ml: 0.5,
                        border: 1,
                        borderRadius: 2,
                        borderColor: '#A1A1A0',
                    }} />

                <Box component={'img'}
                    src={window.CDNHost + '/img/teen12.png'}
                    sx={{
                        ml: 1,
                        height: '35px',
                    }} />

            </Stack>

            <Stack sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                my: 0.7,
            }}>

                {/* 左边icon */}
                <Box component={'img'} src={window.CDNHost + '/img/wanmei.png'}
                    sx={{
                        height: '17px',
                        ml: 2,
                    }} />
                <Box component={'img'} src={window.CDNHost + '/img/flogo1.png'}
                    sx={{
                        height: '17px',
                        ml: 1,
                    }} />

                <Box component={'img'}
                    src={window.CDNHost + '/img/flogo2.png'}
                    sx={{
                        height: '17px',
                    }} />

                <Box component={'img'} src={window.CDNHost + '/img/flogo3.png'}
                    sx={{
                        height: '17px',
                        ml: 1,
                    }} />
            </Stack>

            {/* 中间文字 */}
            <Stack sx={{
                color: '#CACFD199',
            }}>
                <Stack direction={'row'} sx={{
                    justifyContent: 'center',
                }}>
                    <Typography
                        onClick={privacyTap}
                        sx={{
                            cursor: 'pointer',
                            fontSize: 10,
                            pl: '5px',
                        }}>Privacy Policy </Typography>

                    <Typography
                        sx={{
                            fontSize: 10,
                            ml: '3px',
                            mr: '3px',
                        }}> / </Typography>

                    <Typography
                        onClick={termTap}
                        sx={{
                            cursor: 'pointer',
                            fontSize: 10,
                        }}>Terms of services </Typography>

                    <Typography
                        sx={{
                            fontSize: 10,
                            ml: '3px',
                            mr: '3px',
                        }}> / </Typography>

                    <Typography
                        onClick={serviceTap}
                        sx={{
                            cursor: 'pointer',
                            fontSize: 10,
                        }}>ClientService</Typography>
                </Stack>

                <Typography sx={{
                    fontSize: 10,
                    ml: '5px',
                    mb: 3,
                }}>©ONE, Yusuke Murata/SHUEISHA, Hero Association HQ</Typography>
            </Stack>

        </Stack>
    );
}