import * as React from 'react';
import * as StringTool from '../js/StringTool';
import {Box, Stack, Dialog, Typography, List } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { settingActions } from '../store/reducers/SettingSlice';
import * as APJRequest from '../js/APJRequest';
import { useNavigate} from "react-router-dom";


export default function SignDialog() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const settingData = useSelector(state => state.settingData);
    const [num, setNum] = React.useState(0);//0,1成功,2已提交
    const [msg, setMsg] = React.useState('');

    function changePage(i, str) {
        if (i == 1) { //成功新页面
            closeTap();
            dispatch(settingActions.closeSignDalog());
            navigate('/preRegisterSuccessPage');

        } else {
            setNum(i);
            setMsg(str);
        }
    }

    function closeTap() {
        setTimeout(() => {
            setNum(0);
        }, 1000);
    }

    return (
        <Dialog
            PaperProps={{
                style: {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                },
            }}
            maxWidth={'md'}
            open={settingData.openSign}>

            <Box component={'img'} src={window.CDNHost + '/img/alert/bg.png'} sx={{
                width: '800px',
                minWidth: '800px',
                height: '515px',
                minHeight: '515px',
            }} />
            {num == 0 && <EmailView changePage={changePage} />}
            {num > 0 && <ResultView num={num} msg={msg} closeTap={closeTap} />}

        </Dialog>
    );
}

function AddressItem(props) {
    function tap() {
        props.addressTap(props.title);
    }

    return (<Stack onClick={tap}>
        <Typography
            sx={{
                cursor: 'pointer',
                fontSize: 15,
                pl: 2,
                my: 0.5,
                color: 'black',
            }} >
            {props.title}
        </Typography>
    </Stack>
    );
}

function EmailView(props) {
    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);
    const [selected, setSelected] = React.useState(false);
    const [inputValue, setInputValue] = React.useState('');

    const [enableSubmit, setEnableSubmit] = React.useState(false);//可以提交
    const [show, setShow] = React.useState(false);//地址菜单
    const [address, setAddress] = React.useState('Albania'); //地址
    const countryList = ["Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda", "Argentina", "Armenia", "Australia", "Austria", "Azerbaijan", "Bahamas", "Bahrain", "Barbados", "Belarus", "Belgium", "Belize", "Benin", "Bolivia", "Bosnia and Herzegovina", "Brazil", "Bulgaria", "Burkina Faso", "Burundi", "Cameroon", "Canada", "Cape Verde", "Cayman Islands", "Central African Republic", "Chad", "Chile", "Colombia", "Comoros", "Costa Rica", "Côte d'Ivoire", "Croatia", "Cuba", "Cyprus", "Czech Republic", "Democratic Republic of the Congo, Republic of the Congo", "Denmark", "Djibouti", "Dominica", "Dominican Republic", "Ecuador", "Egypt", "El Salvador", "Equatorial Guinea", "Estonia", "Ethiopia", "Finland", "France", "Gabon", "Georgia", "Germany", "Greece", "Grenada", "Guatemala", "Guinea", "Guinea-Bissau", "Guyana", "Honduras", "Hungary", "Iceland", "Iran", "Iraq", "Ireland", "Israel", "Italy", "Jamaica", "Jordan", "Kazakhstan", "Kirghistan", "Kosovo", "Kuwait", "Latvia", "Lebanon", "Liechtenstein", "Lithuania", "Luxembourg", "Madagascar", "Mali", "Malta", "Mauritania", "Mexico", "Moldova", "Monaco", "Montenegro", "Morocco", "Mozambique", "Netherlands", "New Zealand", "Nicaragua", "Niger", "Norway Sweden", "Oman", "Palestine", "Panama", "Paraguay", "Peru", "Poland", "Portugal", "Qatar", "Romania", "Russia", "Saint Kitts and Nevis", "Saint Lucia", "Saint Vincent and the Grenadines", "Sao Tome and Principe", "Saudi Arabia", "Senegal", "Serbia", "Seychelles", "Slovakia", "Slovenia", "South Africa", "Spain", "Switzerland", "Syria", "Tajikistan", "Togo", "Trinidad and Tobago", "Tunisia", "Turkey", "Turkmenistan", "Turks and Caicos", "U.S. Virgin Islands", "Ukraine", "United Arab Emirates", "United Kingdom", "Uruguay", "USA", "Uzbekistan", "Venezuela", "Western Sahara", "Yemen"];

    React.useEffect(() => {
        clearData();
    }, []);

    function closeTap() {
        clearData();
        dispatch(settingActions.closeSignDalog());
    }

    function clearData() {
        setAddress('Albania');
        setInputValue('');
        setSelected(false);
        setEnableSubmit(false);
    }

    function signTap() {
        if (!selected || !StringTool.isEmail(inputValue)) {
            return;
        }

        //提交请求
        APJRequest.preRegister(inputValue, address, (data) => {
            clearData();
            window.fbq('track', 'CompleteRegistration'); //FB埋点
            window.gtag_report_conversion('mL1OCJaNhKoDEMaakr8B'); //谷歌埋点
            props.changePage(1, data.msg);

        }, (code, msg) => {
            clearData();
            if (code == 17003) { //已提交
                props.changePage(2, msg);

            } else {
                alert('submit error');
            }
        });
    }

    function checkTap() {
        setSelected((state) => {
            const sel = !state;
            if (sel && StringTool.isEmail(inputValue)) {
                setEnableSubmit(true);
            } else {
                setEnableSubmit(false);
            }
            return sel;
        });
    }

    function onChange(e) {
        setInputValue(e.target.value);

        if (selected && StringTool.isEmail(e.target.value)) {
            setEnableSubmit(true);
        } else {
            setEnableSubmit(false);
        }

    }

    function addressTap(str) {
        setAddress(str);
        setShow(false);
    }

    //下拉菜单
    function onMouseOver() {
        setShow(true);
    }

    function onMouseLeave() {
        setShow(false);
    }

    return (
        <Stack sx={{
            position: 'absolute',
            width: '800px',
            minWidth: '800px',
            height: '515px',
            minHeight: '515px',
            color: 'white',
            alignItems: 'center',
        }}>

            <Box
                onClick={closeTap}
                component={'img'}
                src={window.CDNHost + '/img/alert/close.png'}
                sx={{
                    width: '25px',
                    height: '25px',
                    position: 'absolute',
                    right: 57,
                    top: 55,
                    transitionDuration: '0.3s',
                    '&:hover': {
                        transform: 'rotate(180deg)',
                    }
                }} />

            <Typography sx={{
                fontFamily: 'us',
                fontSize: 35,
                mt: 13,
            }}>
                {settingData.langDic['lang46']}
            </Typography>



            {/* 输入框 */}
            <Box component={'input'}
                placeholder={settingData.langDic['lang53']}
                value={inputValue}
                onChange={onChange}
                sx={{
                    height: '45px',
                    minHeight: '45px',
                    width: 0.65,
                    minWidth: 0.65,
                    mt: 2,
                    fontSize: 20,
                    borderRadius: 1.5,
                    pl: 2,
                }} />

            {/* 地址 */}
            <Stack sx={{
                width: 0.65,
                minWidth: 0.65,
                display: 'flex',
                flexDirection: 'row',
                mt: 2,
                alignItems: 'center',
            }}>
                <Typography sx={{
                    textAlign: 'center',
                    fontSize: 18,
                    flex: 1,
                    textAlign: 'center',
                }}>
                    {settingData.langDic['lang52']}
                </Typography>
            </Stack>

            {/* 地名 */}
            <Stack
                onMouseOver={onMouseOver}
                onMouseLeave={onMouseLeave}
                sx={{
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: 0.67,
                    minWidth: 0.67,
                    py: 0.5,
                    bgcolor: show ? 'white' : '',
                    justifyContent: 'center',
                }}>
                <Typography sx={{
                    textAlign: 'center',
                    fontSize: 18,
                    fontWeight: 'bold',
                    color: '#01EAFF',
                    mr: 1,
                }}>
                    {address}
                </Typography>

                <Box component={'img'}
                    src={window.CDNHost + '/img/alert/green_sj.png'}
                    sx={{
                        width: 13,
                    }} />

                {/* 列表 */}
                <List sx={{
                    width: 0.67,
                    minWidth: 0.67,
                    height: '145px',
                    bgcolor: 'white',
                    position: 'absolute',
                    top: 295,
                    overflow: 'auto',
                    display: show ? 'flex' : 'none',
                    flexDirection: 'column',
                }}>
                    {countryList.map((value, i) => {
                        return <AddressItem key={i} title={value} addressTap={addressTap} />
                    })}
                </List>
            </Stack>

            {/* 提交 */}
            <Box
                onClick={signTap}
                component={'span'}
                sx={{
                    mt: 3,
                    cursor: 'pointer',
                    background: enableSubmit ? `url(${window.CDNHost}/img/alert/blue_bt.png) no-repeat` : `url(${window.CDNHost}/img/alert/gray_bt.png) no-repeat`,
                    backgroundSize: '100% 100%',
                    color: 'white',
                    alignItems: 'center',
                    textAlign: 'center',
                    pl: 8,
                    pr: 9.5,
                    height: '55px',
                    lineHeight: '50px',
                    fontSize: 20,
                }}>
                {settingData.langDic['lang6']}
            </Box>


            {/* 协议 */}
            <Stack sx={{
                width: 0.69,
                minWidth: 0.69,
                display: 'flex',
                flexDirection: 'row',
                mt: 1,
                alignItems: 'center',
            }}>

                <Box
                    onClick={checkTap}
                    component={'img'}
                    src={selected ? window.CDNHost + '/img/alert/checked.png' : window.CDNHost + '/img/alert/uncheck.png'}
                    sx={{
                        mt: 0.5,
                        cursor: 'pointer',
                        width: '27px',
                    }} />

                <Box component={'div'}
                    dangerouslySetInnerHTML={{ __html: settingData.langDic['lang51'] }}
                    sx={{
                        mt: 2,
                        ml: 0.5,
                        fontSize: 12,
                        color: 'white',
                        fontFamily: 'initial'
                    }} />
            </Stack>

        </Stack>
    );
}

function ResultView(props) {

    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);

    function closeTap() {
        dispatch(settingActions.closeSignDalog());
        props.closeTap();
    }

    function shareTap(i) {
        if (i == 0) { //disk
            APJRequest.openDiscord();

        } else if (i == 1) {//tiwtter
            APJRequest.openTwitter();

        } else { //fb
            APJRequest.openFacebook();
        }
    }

    return (
        <Stack sx={{
            position: 'absolute',
            width: '800px',
            minWidth: '800px',
            height: '515px',
            minHeight: '515px',
            color: 'white',
            alignItems: 'center',
            justifyContent: 'center',

        }}>

            <Box
                onClick={closeTap}
                component={'img'}
                src={window.CDNHost + '/img/alert/close.png'}
                sx={{
                    width: '25px',
                    height: '25px',
                    position: 'absolute',
                    right: 57,
                    top: 55,
                    transitionDuration: '0.3s',
                    '&:hover': {
                        transform: 'rotate(180deg)',
                    }
                }} />

            {props.num == 1 &&
                <Stack sx={{
                    alignItems: 'center',
                }}>
                    <Typography sx={{
                        fontFamily: 'us',
                        fontSize: 32,
                    }}>
                        {settingData.langDic['lang47']}
                    </Typography>

                    <Typography sx={{
                        mt: 6,
                    }}>
                        {settingData.langDic['lang48']}
                    </Typography>
                </Stack>
            }

            {props.num == 2 &&
                <Stack>
                    <Typography sx={{
                        mt: 2,
                        fontSize: 25,
                    }}>
                        {props.msg}
                    </Typography>
                </Stack>
            }


            <Stack sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                mt: 6,
            }}>
                <Box component={'img'}
                    onClick={(e) => shareTap(0)}
                    src={window.CDNHost + '/img/pt1.png'} sx={{
                        cursor: 'pointer',
                        height: '28px',
                    }} />

                <Box component={'img'}
                    onClick={(e) => shareTap(1)}
                    src={window.CDNHost + '/img/pt2.png'} sx={{
                        cursor: 'pointer',
                        height: '35px',
                        ml: 5,
                    }} />

                <Box component={'img'}
                    onClick={(e) => shareTap(2)}
                    src={window.CDNHost + '/img/pt3.png'} sx={{
                        cursor: 'pointer',
                        height: '35px',
                        ml: 5,
                    }} />
            </Stack>
        </Stack>
    );


}