import { Box, Stack, Typography } from '@mui/material';
import * as React from 'react';
import ReactPlayer from 'react-player';
import VideoFooter from './VideoFooter';
import * as StringTool from '../js/StringTool';

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import { useSelector, useDispatch } from 'react-redux'

import HeroJson from '../js/hero.json';

export default function Videos(props) {

    const dispatch = useDispatch();
    const heroData = useSelector(state => state.heroData);
    const settingData = useSelector(state => state.settingData);
    const [activeIndex, setActiveIndex] = React.useState(0);
    const [swp, setSwp] = React.useState(null);

    const X = 0.5625;
    const [videoWidth, setVideoWidth] = React.useState(800);
    const [videoUrl, setVideoUrl] = React.useState(heroData.videoUrls[0]);

    React.useEffect(() => {
        fitWith();
        addWindowListener();
    }, []);

    function addWindowListener() {
        window.addEventListener(
            'resize',
            () => {
                fitWith();
            }
        );
    }

    function fitWith() {
        let w = window.innerWidth * 0.5;
        if (w < 450) {
            w = 450;
        }
        setVideoWidth(w);
    }


    function preTap() {
        if (activeIndex > 0) {
            imageTap(activeIndex - 1);
        }
    }

    function nextTap() {
        if (activeIndex < heroData.videoImages.length-1) {
            imageTap(activeIndex + 1);
        }
    }

    function imageTap(i) {
        setActiveIndex(i);

        const x = heroData.videoUrls[i];
        if(!StringTool.isEmpty(x)){
            setVideoUrl(x);
        }
    }

    return (
        <Stack
            sx={{
                width: 1,
                flex: 1,
                overflow: 'hidden',
                alignItems: 'center',
            }}>

            <Box
                component={'img'}
                src={window.CDNHost+'/img/bg3.jpg'}
                sx={{
                    width: 1,
                    height: 1,
                    objectFit: 'cover',
                    animation: 'bgani 5s ease-in-out both infinite',
                }} />

            <Stack sx={{
                width: 1,
                position: 'absolute',
                justifyContent: 'center',
                alignItems: 'center',
                height: 1,
            }}>
                <Stack sx={{
                    width: 1,
                    height: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <Typography sx={{
                        color: 'white',
                        fontFamily: 'redFont',
                        fontSize: 48,
                        mb: 1,
                        width: `${videoWidth + 150}px`,
                        animation: 'upToBottom .5s',
                    }}>
                        {settingData.langDic['lang3']}
                    </Typography>

                    {/* 视频播放 */}
                    <Stack direction={'row'}
                        sx={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: 1,
                            animation: 'upToBottom .5s',
                        }}>
                        <Box component={'img'}
                            onClick={preTap}
                            src={window.CDNHost+"/img/left_bt.png"}
                            sx={{
                                cursor: 'pointer',
                                width: { sm: '40px', lg: '50px' },
                                mr: { sm: 5, lg: 7 },
                                display: { xs: 'none', sm: 'flex' },
                                transitionDuration: '0.3s',
                                '&:hover': {
                                    transform: 'scale(1.1)',
                                    filter: 'drop-shadow(1px 1px 6px #3159BE)  brightness(130%)',
                                },
                            }} />

                        <ReactPlayer
                            height={`${videoWidth * X}px`}
                            width={`${videoWidth}px`}
                            url={videoUrl}
                            controls={true} light={false} volume={0.6} style={{
                                minWidth: '450px',
                                minHeight: '255px',
                            }} />

                        <Box component={'img'}
                            onClick={nextTap}
                            src={window.CDNHost+"/img/right_bt.png"}
                            sx={{
                                cursor: 'pointer',
                                width: { sm: '40px', lg: '50px' },
                                ml: { sm: 5, lg: 7 },
                                display: { xs: 'none', sm: 'flex' },
                                transitionDuration: '0.3s',
                                '&:hover': {
                                    transform: 'scale(1.1)',
                                    filter: 'drop-shadow(1px 1px 6px #3159BE)  brightness(130%)',
                                },
                            }} />
                    </Stack>

                    {/* Banner 图片 */}
                    <Swiper
                        style={{
                            width: `${videoWidth}px`,
                            marginTop: '30px',
                            animation: 'upToBottom .5s',
                        }}

                        onSwiper={(sp) => {
                            setSwp(sp);
                        }}

                        spaceBetween={20}
                        slidesPerView={5}>
                        {
                            heroData.videoImages.map((value, i) => {
                                return <SwiperSlide key={i}
                                    style={{
                                        display: 'flex',
                                        background: 'black',
                                    }}>
                                    <Item value={value} index={i} activeIndex={activeIndex} imageTap={imageTap} />
                                </SwiperSlide>
                            })
                        }

                    </Swiper>

                </Stack>

            </Stack>

            {/* 下载按钮 */}
            <VideoFooter />

        </Stack>

    );
}

function Item(props) {
    return (
        <Stack onClick={() => props.imageTap(props.index)} sx={{
            border: 0.1,
            borderColor: 'white',
            opacity: props.index == props.activeIndex ? 1 : 0.5,
            overflow: 'hidden',
        }}>
            <Box component={'img'}
                src={props.value != "" ? window.CDNHost+props.value : window.CDNHost+'/img/videos/default.png'}
                sx={{
                    cursor: 'pointer',
                    objectFit: 'cover',
                    filter: props.index == props.activeIndex ? 'brightness(110%)' : 'brightness(70%)',
                    transitionDuration: '0.3s',
                    '&:hover': {
                        transform: 'scale(1.06)',
                    }
                }} />
        </Stack>
    );
}
