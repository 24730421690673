import * as React from 'react';
import { Box, Stack } from '@mui/material';
import LoadingComponent from './components/LoadingComponent';
import LoadingAnimate from './components/LoadingAnimate';
import Welcome from './components/Welcome';
import Main from './components/Main';
import RightIcon from './components/RightIcons';

import { useSelector, useDispatch } from 'react-redux';
import { settingActions } from '../src/store/reducers/SettingSlice';


function App() {

  const settingData = useSelector(state => state.settingData);
  const dispatch = useDispatch();

  function onFinish(index) {
    dispatch(settingActions.setLoadingIndex(index));
  }

  function homeBackTap() {
    dispatch(settingActions.setLoadingIndex(2));
  }

  return (
    <Stack>
      {settingData.loadingIndex == 0 && <LoadingComponent onFinish={onFinish} />}
      {settingData.loadingIndex == 1 && <LoadingAnimate onFinish={onFinish} />}
      {settingData.loadingIndex == 2 && <Welcome onFinish={onFinish} />}
      {settingData.loadingIndex == 3 && <Main onFinish={onFinish} backTap={homeBackTap} />}
      {settingData.loadingIndex >= 2 && <RightIcon />}
    </Stack>
  );
}

export default App;
