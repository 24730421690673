import { Stack, Box, Typography, } from '@mui/material';
import * as React from 'react';

export default function TextView(props) {
    const [isBig, setBig] = React.useState(false);

    return (
        <Stack sx={{
            position: 'relative',
            width: window.innerWidth,
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <Typography sx={{
                position: 'absolute',
                fontSize: props.isMobile ? 40: (isBig ? 130 : 95),
                color: '#ffffff11',
                fontFamily: 'us',
            }}>
                {props.title}
            </Typography>

            <Typography sx={{
                fontSize: props.isMobile ? 35: (isBig ? 100 : 75),
                color: 'white',
                mt: props.isMobile ? '30px': '40px',
                zIndex: 1,
                fontFamily: 'us',
            }}>
                {props.title}
            </Typography>
        </Stack>
    );
}
