import { Box, Stack, Typography } from '@mui/material';
import * as React from 'react';
import * as rdd from 'react-device-detect';
import country from '../js/country.json';
import * as APJRequest from '../js/APJRequest';

export default function LoadingComponent(props) {
    let countTmp = 0;
    let isFinish = false;
    const [count, setCount] = React.useState(0);
    const [index, setIndex] = React.useState(0); //-1禁用，1进入

    React.useEffect(() => {
        APJRequest.getCountryCode((countryCode) => {
            processCountry(countryCode);
        }, (code, msg) => {
            enterGame();
        });
    }, []);

    function enterGame() {
        setIndex(1);
        startAni();
        preload();
    }

    function processCountry(countryCode) {
        if ('TH' == countryCode) { //泰国
            window.location.href = "https://opmw.perfectworld.com/th/index.html";

        } else if (isIn('sea', countryCode)) { //东南亚
            window.location.href = "https://opmw.perfectworld.com/en/index.html";

        } else if (isIn('www', countryCode)) { //美国等
            window.location.href = "https://www.onepunchmanworld.com";

        } else if (isIn('eu', countryCode)) { //欧洲等进入游戏
            enterGame();

        } else { //JP等禁用
            setIndex(-1);
        }
    }

    function isIn(region, countryCode) {
        return country[region].indexOf(countryCode) >= 0;
    }

    function isMobile() {
        return rdd.isMobile || window.innerWidth < 700;
    }

    function preload() {
        //pc预加载
        let mainfest = [
            { src: window.CDNHost + "/img/video1.mp4" },
            { src: window.CDNHost + "/img/video2.mp4" },
            { src: window.CDNHost + "/img/video3.mp4" },
        ];
        for (let i = 0; i <= 15; i++) {
            mainfest.push(window.CDNHost + `/img/load/${i}.png`);
        }
        for (let i = 1; i <= 6; i++) {
            mainfest.push(window.CDNHost + `/img/hero_poster/pic${i}.jpg`);
        }
        for (let i = 0; i <= 29; i++) {
            mainfest.push(window.CDNHost + `/img/music_gif/_000${i}.png`);
        }

        mainfest.push(window.CDNHost + `/img/video1.jpg`);
        mainfest.push(window.CDNHost + `/img/video3.jpg`);

        mainfest.push(window.CDNHost + `/img/bg1.jpg`);
        mainfest.push(window.CDNHost + `/img/bg2.png`);
        mainfest.push(window.CDNHost + `/img/bg3.jpg`);

        mainfest.push(window.CDNHost + `/img/btn.png`);
        mainfest.push(window.CDNHost + `/img/logo2.png`);

        mainfest.push(window.CDNHost + `/img/left_bt.png`);
        mainfest.push(window.CDNHost + `/img/right_bt.png`);

        mainfest.push(window.CDNHost + `/img/news/news_icon_bg.png`);
        mainfest.push(window.CDNHost + `/img/news/news_bg.jpg`);
        mainfest.push(window.CDNHost + `/img/news/news0_icon0.png`);
        mainfest.push(window.CDNHost + `/img/news/news1_icon0.png`);
        mainfest.push(window.CDNHost + `/img/news/news2_icon0.png`);
        mainfest.push(window.CDNHost + `/img/news/news4_icon0.png`);

        mainfest.push(window.CDNHost + `/img/flogo1.png`);
        mainfest.push(window.CDNHost + `/img/flogo2.png`);
        mainfest.push(window.CDNHost + `/img/flogo3.png`);
        mainfest.push(window.CDNHost + `/img/wanmei.png`);

        //进度
        mainfest.push(window.CDNHost + `/img/pre_register/bg.png`);
        mainfest.push(window.CDNHost + `/img/pre_register/jiangpin1.5m_2.png`);
        mainfest.push(window.CDNHost + `/img/pre_register/jiangpin1.5m.png`);
        mainfest.push(window.CDNHost + `/img/pre_register/jiangpin1m_2.png`);
        mainfest.push(window.CDNHost + `/img/pre_register/jiangpin1m.png`);
        mainfest.push(window.CDNHost + `/img/pre_register/jiangpin2m_2.png`);
        mainfest.push(window.CDNHost + `/img/pre_register/jiangpin2m.png`);
        mainfest.push(window.CDNHost + `/img/pre_register/jiangpin3m_2.png`);
        mainfest.push(window.CDNHost + `/img/pre_register/jiangpin3m.png`);
        mainfest.push(window.CDNHost + `/img/pre_register/jiangpin500k_2.png`);
        mainfest.push(window.CDNHost + `/img/pre_register/jiangpin500k.png`);
        mainfest.push(window.CDNHost + `/img/pre_register/jindutiao_bg.png`);
        mainfest.push(window.CDNHost + `/img/pre_register/zi_1.5m.png`);
        mainfest.push(window.CDNHost + `/img/pre_register/zi_1m.png`);
        mainfest.push(window.CDNHost + `/img/pre_register/zi_2m.png`);
        mainfest.push(window.CDNHost + `/img/pre_register/zi_3m.png`);
        mainfest.push(window.CDNHost + `/img/pre_register/zi_500k.png`);
        mainfest.push(window.CDNHost + `/img/pre_register/jindu1.5m.gif`);
        mainfest.push(window.CDNHost + `/img/pre_register/jindu1m.gif`);
        mainfest.push(window.CDNHost + `/img/pre_register/jindu2m.gif`);
        mainfest.push(window.CDNHost + `/img/pre_register/jindu3m.gif`);
        mainfest.push(window.CDNHost + `/img/pre_register/jindu500k.gif`);

        //英雄
        let heroName = ["Saitama_dream", "Genos1", "Speed_o_Sound_Sonic", "Mumen_Rider",
            "Puri_puri_Prisoner", "Atomic_Samurai", "Golden_Ball", "Lightning_Max"
        ];
        for (let i = 0; i < heroName.length; i++) {
            mainfest.push(window.CDNHost + `/img/hero_full/${heroName[i]}_full.png`);
            mainfest.push(window.CDNHost + `/img/hero_mp/${heroName[i]}_icon.png`);
            mainfest.push(window.CDNHost + `/img/hero_bg/${heroName[i]}_bg.png`);
            mainfest.push(window.CDNHost + `/img/hero_big/${heroName[i]}_big.png`);
            mainfest.push(window.CDNHost + `/img/hero_small/${heroName[i]}_small.png`);
        }

        //移动预加载
        if (isMobile()) {
            mainfest = [
                { src: window.CDNHost + "/img/mobile/m_bg1.jpg" },
                { src: window.CDNHost + "/img/mobile/m_bg2.jpg" },
                { src: window.CDNHost + "/img/mobile/m_bg4.jpg" },
            ];

            mainfest.push(window.CDNHost + '/img/mobile/m_bg1.jpg');
            mainfest.push(window.CDNHost + '/img/mobile/m_bg2.jpg');
            mainfest.push(window.CDNHost + '/img/mobile/m_bg3.jpg');
            mainfest.push(window.CDNHost + '/img/mobile/m_bg4.jpg');
            mainfest.push(window.CDNHost + '/img/mobile/register_bg2.png');
            mainfest.push(window.CDNHost + '/img/logo.png');
            mainfest.push(window.CDNHost + `/img/blue_bg1.png`);

            //进度
            mainfest.push(window.CDNHost + `/img/pre_register/bg.png`);
            mainfest.push(window.CDNHost + `/img/pre_register/jiangpin1.5m_2.png`);
            mainfest.push(window.CDNHost + `/img/pre_register/jiangpin1.5m.png`);
            mainfest.push(window.CDNHost + `/img/pre_register/jiangpin1m_2.png`);
            mainfest.push(window.CDNHost + `/img/pre_register/jiangpin1m.png`);
            mainfest.push(window.CDNHost + `/img/pre_register/jiangpin2m_2.png`);
            mainfest.push(window.CDNHost + `/img/pre_register/jiangpin2m.png`);
            mainfest.push(window.CDNHost + `/img/pre_register/jiangpin3m_2.png`);
            mainfest.push(window.CDNHost + `/img/pre_register/jiangpin3m.png`);
            mainfest.push(window.CDNHost + `/img/pre_register/jiangpin500k_2.png`);
            mainfest.push(window.CDNHost + `/img/pre_register/jiangpin500k.png`);
            mainfest.push(window.CDNHost + `/img/pre_register/jindutiao_bg.png`);
            mainfest.push(window.CDNHost + `/img/pre_register/zi_1.5m.png`);
            mainfest.push(window.CDNHost + `/img/pre_register/zi_1m.png`);
            mainfest.push(window.CDNHost + `/img/pre_register/zi_2m.png`);
            mainfest.push(window.CDNHost + `/img/pre_register/zi_3m.png`);
            mainfest.push(window.CDNHost + `/img/pre_register/zi_500k.png`);

            //Poster
            for (let i = 0; i <= 15; i++) {
                mainfest.push(window.CDNHost + `/img/load/${i}.png`);
            }
            for (let i = 1; i <= 6; i++) {
                mainfest.push(window.CDNHost + `/img/hero_poster/pic${i}.jpg`);
            }

            //news
            mainfest.push(window.CDNHost + `/img/news/news_icon_bg2.png`);
            mainfest.push(window.CDNHost + `/img/news/news0_icon0.png`);
            mainfest.push(window.CDNHost + `/img/news/news1_icon0.png`);
            mainfest.push(window.CDNHost + `/img/news/news2_icon0.png`);
            mainfest.push(window.CDNHost + `/img/news/news4_icon0.png`);

            //英雄
            let heroName = ["Saitama_dream", "Genos1", "Speed_o_Sound_Sonic", "Mumen_Rider",
                "Puri_puri_Prisoner", "Atomic_Samurai", "Golden_Ball", "Lightning_Max"
            ];
            for (let i = 0; i < heroName.length; i++) {
                mainfest.push(window.CDNHost + `/img/mobile/full/${heroName[i]}_full.png`);
                mainfest.push(window.CDNHost + `/img/mobile/mp/${heroName[i]}_bg.png`);
                mainfest.push(window.CDNHost + `/img/mobile/small/${heroName[i]}_small.png`);
            }
        }

        var preload = {
            startPreload: function () {
                var preload = new window.createjs.LoadQueue(true);
                preload.installPlugin(window.createjs.SOUND);
                preload.installPlugin(window.createjs.VIDEO);

                preload.addEventListener("progress", this.handleFileProgress);
                preload.addEventListener("complete", this.loadComplete);

                preload.setMaxConnections(10);
                preload.loadManifest(mainfest);
            },

            handleFileProgress: function (event) {
                const num = Math.ceil(event.loaded * 100);
                if (num > countTmp) {
                    countTmp = num;
                    setCount(num);
                }
            },

            loadComplete: function () {
                if (!isFinish) {
                    countTmp = 100;
                    isFinish = true;
                    props.onFinish && props.onFinish(1);
                }
            }
        }
        preload.startPreload();
    }

    function startAni() {
        let timer = setInterval(function () {
            if (countTmp >= 100) {
                clearInterval(timer);
                if (!isFinish) {
                    isFinish = true;
                    props.onFinish && props.onFinish(1);
                }
            } else {
                let randNum = Math.floor(Math.random() * 10);
                countTmp += randNum;
                setCount(prevCount => {
                    let x = prevCount + randNum;
                    if (x > 100) {
                        x = 100;
                    }
                    return x;
                });
            }
        }, 200);
    }

    return (
        <Stack sx={{
            width: 1,
            height: '100vh',
            bgcolor: 'black',
        }}>
            {index == -1 && <ForbiddenView />}
            {index == 1 &&
                <Stack sx={{
                    width: 1,
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    bgcolor: 'black',
                    justifyContent: 'center',
                }}>
                    <Box
                        component={'img'}
                        src={window.CDNHost + '/img/load_rw.png'} sx={{
                            width: isMobile() ? ' 250px' : 'auto',
                        }} />

                    <Typography sx={{
                        top: '-30px',
                        position: 'relative',
                        color: '#32A8FC',
                        fontSize: 25,
                        fontFamily: 'us',
                    }}>
                        {count}%
                    </Typography>

                    <Stack sx={{
                        width: 0.6,
                        position: 'relative',

                    }}>
                        <Box
                            component={'img'}
                            src={window.CDNHost + '/img/jd_bg3.png'}
                            sx={{
                                height: '8px',
                            }} />

                        <Stack sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            height: 1,
                            width: 1,
                            position: 'absolute',
                        }}>

                            <Box
                                component={'img'}
                                src={window.CDNHost + '/img/mask_left.png'} sx={{
                                    height: 1,
                                    width: 0.5,
                                    animation: 'progressLeft 3s ease-in-out both infinite',
                                }} />

                            <Box
                                component={'img'}
                                src={window.CDNHost + '/img/mask_right.png'} sx={{
                                    height: 1,
                                    ml: '50%',
                                    width: '50%',
                                    position: 'absolute',
                                    animation: 'progressRight 3s ease-in-out both infinite',
                                }} />
                        </Stack>

                    </Stack>

                    <Box
                        component={'img'}
                        src={window.CDNHost + '/img/jd_icon.png'} sx={{
                            mt: 2,
                            animation: 'progressAni 1.5s ease-in-out both infinite',
                        }} />

                </Stack>
            }


        </Stack>
    );
}

function ForbiddenView() {

    function isMobile() {
        return rdd.isMobile || window.innerWidth < 700;
    }

    return (
        <Box
            component={'img'}
            src={isMobile() ? window.CDNHost + '/img/forbidden_m.png' : window.CDNHost + '/img/forbidden.png'}
            sx={{
                width: 1,
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                bgcolor: 'black',
                justifyContent: 'center',
                objectFit: 'cover',
            }} />
    );
}