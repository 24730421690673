import { Stack, Box, Typography, Dialog, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { settingActions, settingObj } from '../../store/reducers/SettingSlice';
import * as StringTool from '../../js/StringTool';
import { TWSid, TWRid, StoreSid, StoreRid } from '../../js/StringTool';

export default function RoleAlert(props) {
    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);

    const [roleList, setRoleList] = React.useState([]);
    const [sid, setSid] = React.useState('');
    const [rid, setRid] = React.useState('');

    React.useEffect(() => {
        changeService('');
        changeRole('');

        setTimeout(() => {
            startShow();
        }, 300);
    }, [settingData.roleAlertKey]);

    function startShow() {
        if (settingObj.opmData) {
            let u = settingObj.opmData;
            window.requestRoleList(true, u.websdk.uid, u.websdk.token, (sData) => {
                if (sData.success) {
                    dispatch(settingActions.setServiceData(sData));
                    loadPrevious();
                } else {
                    console.log('service list is empty')
                }
            });
        }
    }

    function loadPrevious() {
        let preSid = StringTool.getLocalString(TWSid); //twitch
        let preRid = StringTool.getLocalString(TWRid);

        if (props.storeConfirmTap) { //商城
            preSid = StringTool.getLocalString(StoreSid);
            preRid = StringTool.getLocalString(StoreRid);
        }

        if (!StringTool.isEmpty(preSid)) {
            changeService(preSid);

            if (!StringTool.isEmpty(preRid)) {
                changeRole(preRid);
            }
        }
    }

    function changeService(sid) {
        setSid(sid);
        let ary = [];
        if (settingObj.serviceData) {
            for (const md of settingObj.serviceData.roleList) {
                if (md.serverId == sid) {
                    ary.push(md);
                }
            }
        }
        setRoleList(ary);
    }

    function changeRole(rid) {
        setRid(rid);
    }

    function confirmTap() {
        if (!StringTool.isEmpty(sid) && !StringTool.isEmpty(rid)) {
            if (props.storeConfirmTap) { //商城
                StringTool.saveLocalString(StoreSid, sid);
                StringTool.saveLocalString(StoreRid, rid);
                props.storeConfirmTap(sid, rid);

            } else { //twitch绑定
                //删除结果，重新绑定
                if (rid != settingObj.roleId) {
                    dispatch(settingActions.setTwitchData(null));
                }

                dispatch(settingActions.setServiceId(sid));
                dispatch(settingActions.setRoleId(rid));
                StringTool.saveLocalString(TWSid, sid);
                StringTool.saveLocalString(TWRid, rid);
            }
            closeTap();
        }
    }

    function closeTap() {
        dispatch(settingActions.showRoleAlert(false));
    }

    return (
        <Dialog
            PaperProps={{
                style: {
                    boxShadow: 'none',
                    position: 'relative',
                    borderRadius: '15px'
                },
            }}
            maxWidth={'md'}
            open={settingData.showRoleAlert}>

            <Stack
                sx={{
                    width: props.isMobile ? '310px' : '380px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                <Box
                    onClick={closeTap}
                    component={'img'}
                    src={window.CDNHost + '/img/close_black.png'}
                    sx={{
                        position: 'absolute',
                        zIndex: 100,
                        width: '15px',
                        height: '15px',
                        top: 0,
                        m: 2,
                        right: 0,
                        cursor: 'pointer'
                    }} />

                <Typography sx={{
                    width: 1,
                    textAlign: 'center',
                    fontWeight: 'bold',
                    my: props.isMobile ? 2.5 : 3,
                    fontSize: '16px'
                }}>
                    Select Character
                </Typography>


                <Box sx={{
                    width: 0.8,
                }}>
                    {/* 服务 */}
                    <FormControl fullWidth sx={{
                        display: settingData.serviceData ? 'flex' : 'none',
                    }}>
                        <InputLabel>Select Service</InputLabel>
                        <Select
                            value={sid}
                            label="Select Service"
                            onChange={(e) => {
                                changeService(e.target.value)
                            }}>

                            {settingData.serviceData && settingData.serviceData.serverList.map((data, index) => {
                                return <MenuItem key={data.serverId} value={data.serverId}>{data.serverName}</MenuItem>
                            })}

                        </Select>
                    </FormControl>

                    {/* 角色 */}
                    <FormControl fullWidth sx={{
                        display: roleList.length ? 'flex' : 'none',
                        mt: 3,
                    }}>
                        <InputLabel>Select Character</InputLabel>
                        <Select
                            value={rid}
                            label="Select Character"
                            onChange={(e) => { changeRole(e.target.value) }}>

                            {roleList.length > 0 && roleList.map((data, index) => {
                                return <MenuItem key={data.roleId} value={data.roleId} >{data.roleName}</MenuItem>
                            })}

                        </Select>
                    </FormControl>

                    <Typography sx={{
                        display: roleList.length ? 'none' : 'flex',
                        color: 'gray',
                        textAlign: 'center',
                        height: '55px',
                        lineHeight: '55px',
                        mt: 3,
                        justifyContent: 'center',
                    }}>
                        No character found
                    </Typography>


                    {/* 确定 */}
                    <Typography
                        onClick={confirmTap}
                        sx={{
                            height: '47px',
                            lineHeight: '47px',
                            bgcolor: !StringTool.isEmpty(sid) && !StringTool.isEmpty(rid) ? '#F76E40' : '#EFF1F4',
                            textAlign: 'center',
                            mb: 5,
                            mt: props.isMobile ? 3 : 7,
                            borderRadius: '8px',
                            color: !StringTool.isEmpty(sid) && !StringTool.isEmpty(rid) ? 'white' : '#C0C5C8',
                            cursor: 'pointer',
                            fontWeight: 'bold',
                        }}>
                        Confirm
                    </Typography>
                </Box>
            </Stack>
        </Dialog>
    );
}