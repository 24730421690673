import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import settingSlice from './reducers/SettingSlice';
import heroSlice from './reducers/HeroSlice';

const store = configureStore({
    reducer: {
        settingData: settingSlice,
        heroData: heroSlice,
    },
    middleware: [thunk]
});

export default store;