import { Box, Stack, Typography } from '@mui/material';
import * as React from 'react';
import * as StringTool from '../js/StringTool';

export default function CookiesView() {

    const [show, setShow] = React.useState(false);

    React.useEffect(() => {
        let k = StringTool.getLocalString(StringTool.CookieKey);
        if (StringTool.isEmpty(k)) {
            setShow(true);
        }
    }, []);

    function rejectTap() {
        setShow(false);
        StringTool.saveLocalString(StringTool.CookieKey, 'rejected');
    }

    function agreeTap() {
        setShow(false);
        StringTool.saveLocalString(StringTool.CookieKey, 'accecpted');
    }


    return (
        <Stack sx={{
            display: show ? 'flex' : 'none',
            width: 1,
            bgcolor: '#000000dd',
            zIndex: 100,
            position: 'absolute',
            bottom: 0,
            color: 'white',
            fontFamily: '-moz-initial',
            py: 1.8,
            flexDirection: 'row'
        }}>
            <Stack>
                <Typography sx={{
                    ml: 3,
                    fontSize: 18,
                    fontWeight: 'bold'
                }}>
                    This website uses cookies
                </Typography>

                <Box component={'div'}
                    dangerouslySetInnerHTML={{
                        __html: `
                    We use cookies to make our websites and services operate correctly, to understand how visitors engage with us and to
                    improve our product and marketing efforts. See our cookie policy for more information. <a href='https://eu.onepunchmanworld.com/privacy' target='_blank'>Cookie Policy<a/>
                    ` }}
                    sx={{
                        mt: 1,
                        ml: 3,
                        fontSize: 14,
                        color: 'white',
                        fontFamily: 'initial'
                    }} />
            </Stack>

            <Stack sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                flex: 1,
                px: 15,
            }}>

                <Box
                    onClick={rejectTap}
                    sx={{
                        border: 1,
                        bgcolor: 'white',
                        color: 'black',
                        px: 2,
                        py: 1,
                        fontWeight: 'bold',
                        minWidth: '100px',
                        textAlign: 'center',
                        cursor: 'pointer'
                    }}>
                    Regect All
                </Box>

                <Box
                    onClick={agreeTap}
                    sx={{
                        border: 1,
                        bgcolor: 'white',
                        color: 'black',
                        px: 2,
                        py: 1,
                        ml: 2,
                        fontWeight: 'bold',
                        minWidth: '150px',
                        textAlign: 'center',
                        cursor: 'pointer'
                    }}>
                    Accept All Cookies
                </Box>

            </Stack>

        </Stack>
    );


}