import { Stack, Box, Typography, Grid, Snackbar, Backdrop, CircularProgress, Dialog, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation, useSearchParams } from 'react-router-dom';
import * as StringTool from '../../../js/StringTool';
import { StoreSid_CR, StoreRid_CR, TWSid_CR, TWRid_CR, TWData_CR } from '../../../js/StringTool';
import { settingActions, settingObj } from '../../../store/reducers/SettingSlice';
import * as rdd from 'react-device-detect';
// import VConsole from 'vconsole';


export default function ProductStoreCR(props) {
    const productList = [
        {
            'name': '33 World Gold',
            'name2': null,
            'productId': 'com.crunchyroll.opmweb.goldcoin_2',
            'money': '4.49',
            'icon': '30 World Gold.png'
        },
        {
            'name': '105 World Gold',
            'name2': '120 World Silver',
            'productId': 'com.crunchyroll.opmweb.goldcoin_3',
            'money': '14.49',
            'icon': '108w.png'
        },
        {
            'name': '215 World Gold',
            'name2': '300 World Silver',
            'productId': 'com.crunchyroll.opmweb.goldcoin_4',
            'money': '29.49',
            'icon': '218w.png'
        },
        {
            'name': '355 World Gold',
            'name2': '700 World Silver',
            'productId': 'com.crunchyroll.opmweb.goldcoin_5',
            'money': '49.49',
            'icon': '356w.png'
        },
        {
            'name': '718 World Gold',
            'name2': '1700 World Silver',
            'productId': 'com.crunchyroll.opmweb.goldcoin_6',
            'money': '99.49',
            'icon': '718w.png'
        }
    ];

    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);
    const [sid, setSid] = React.useState(null);
    const [rid, setRid] = React.useState(null);
    const [isMobile, setIsMobile] = React.useState(false);
    const [isPortrait, setIsPortrait] = React.useState(false);

    React.useEffect(() => {
        if (rdd.isMobile || window.innerWidth < 700) {
            setIsMobile(true);
            if (window.innerWidth < window.innerHeight) {
                setIsPortrait(true);
            }
        }
    }, []);

    function updateRoleId(sid, rid) {
        setSid(sid);
        setRid(rid);
    }

    function productItemTap(data) {
        //先登录游戏
        if (!settingData.opmData || !settingData.opmData.websdk) {
            dispatch(settingActions.showToast('Please login OPMW Account'));
            return;
        }

        //再选角色
        if (StringTool.isEmpty(StringTool.getLocalString(StoreRid_CR))) {
            dispatch(settingActions.showToast('Please Select Character'));
            return;
        }
        let url = `https://static.perfectworldgames.com/OPMpay/index.html?appid=1000137&uid=${settingData.opmData.websdk.uid}&roleid=${StringTool.getLocalString(StoreRid_CR)}&serverid=${StringTool.getLocalString(StoreSid_CR)}&productid=${data.productId}&appOrderId=${StringTool.uuid()}&local=en&token=${settingData.opmData.websdk.token}&extraInfo=&headerData=`;
        window.open(url);
        console.log(url);
    }


    return (
        <Stack sx={{
            width: 1,
            height: '100vh',
            alignItems: 'center',
            overflowY: 'auto',
        }}>

            <Box
                component={'img'}
                src={window.CDNHost + '/img/payment/BG.png'}
                sx={{
                    width: 1,
                    height: 1,
                    objectFit: 'fill',
                    position: 'absolute',
                    zIndex: -1,
                }} />

            <Stack sx={{
                width: 1,
                alignItems: 'center',
            }}>

                <Stack sx={{
                    width: 1,
                    position: 'relative',
                }}>
                    <Box
                        component={'img'}
                        src={window.CDNHost + '/img/payment/pay_banner.jpg'}
                        sx={{
                            width: 1,
                            minHeight: isMobile ? '100px' : '190px',
                            maxHeight: isMobile ? '140px' : '250px',
                            objectFit: 'cover'
                        }} />

                    <Box component={'img'}
                        src={window.CDNHost + '/img/logo.png'}
                        sx={{
                            position: 'absolute',
                            mt: isMobile ? 2 : 3,
                            ml: isMobile ? 2 : 5,
                            height: isMobile ? '30px' : '45px',
                        }} />

                    <Box component={'img'}
                        src={window.CDNHost + '/img/payment/copy_right.png'}
                        sx={{
                            position: 'absolute',
                            right: 0,
                            bottom: 0,
                            height: isMobile ? '30px' : '40px',
                        }} />
                </Stack>

                <Stack sx={{
                    width: isMobile ? 0.94 : '1200px',
                    mt: isMobile ? 0 : 1,
                    flexDirection: 'row',
                }}>
                    {/* 登录 */}
                    <Stack sx={{
                        width: isMobile ? '330px' : '370px',
                        mt: 2,
                        mr: 2,
                        display: isPortrait ? 'none' : 'flex',
                    }}>
                        <LoginItem isMobile={isMobile} sid={sid} rid={rid} updateRoleId={updateRoleId} />
                    </Stack>

                    {/* 列表 */}
                    <Grid container
                        spacing={2}
                        sx={{
                            mt: '1px',
                            mb: 10,
                        }} >

                        {
                            isPortrait &&
                            <Grid item xs={12}>
                                <LoginItem isMobile={isMobile} sid={sid} rid={rid} updateRoleId={updateRoleId} />
                            </Grid>
                        }

                        {
                            productList.map((item, i) =>
                            <Grid item xs={(isPortrait ? 6 : (isMobile ? 4 : 3))} key={i}>
                                    <ProductItem isMobile={isMobile} data={item} productItemTap={productItemTap} />
                                </Grid>
                            )
                        }
                    </Grid>

                </Stack>

                <RoleAlertCR isMobile={isMobile} key={settingData.roleAlertKey} storeConfirmTap={updateRoleId} />

                {/* loading view */}
                <Backdrop
                    sx={{ color: '#fff', zIndex: 99999 }}
                    open={settingData.showLoading}
                    onClick={() => { }}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                {/* 提示 */}
                <Snackbar open={!StringTool.isEmpty(settingData.showToast)} autoHideDuration={1500} onClose={() => {
                    dispatch(settingActions.showToast(null));
                }}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}>
                    <Box
                        sx={{
                            minWidth: '250px',
                            height: '45px',
                            lineHeight: '45px',
                            borderRadius: '5px',
                            bgcolor: '#eeeeee',
                            color: 'black',
                            textAlign: 'center',
                            px: 3,
                        }}>
                        {settingData.showToast}
                    </Box>
                </Snackbar>

            </Stack>
        </Stack>
    );
}

function LoginItem(props) {

    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);
    const [inGame, setInGame] = React.useState(false);
    const [sName, setSName] = React.useState(null);
    const [rName, setRName] = React.useState(null);
    // const vConsole = new VConsole();

    React.useEffect(() => {
        checkGameLogin();
        // return () => {
        //     vConsole.destroy();
        // }
    }, []);

    function checkGameLogin() {
        /*判断客户端*/
        let userAgent = window.navigator.userAgent.toLowerCase();
        //判断端内
        let _judgeClient = userAgent.indexOf('activitysdk') > -1 || userAgent.indexOf('activtysdk') > -1 || userAgent.indexOf('pcactivtysdk') > -1;
        console.log('_judgeClient 打印：' + _judgeClient);

        //sdk获取信息
        if (_judgeClient) {
            window.webveiwFuns.ready(function (data) {
                function getMsg(data) {
                    if (typeof (data) == 'string') {
                        try {
                            var jsonData = JSON.parse(data);
                        } catch (err) {
                            var jsonData = false;
                        }
                    } else if (typeof (data) == 'object') {
                        var jsonData = data;
                    }
                    return jsonData;
                }

                window.webveiwFuns.getDeviceInfo(function (data) {
                    console.log(data);

                    let gameData = getMsg(data).result;
                    if (gameData == null || gameData == undefined) { //空
                        initLogin();
                    } else {
                        logoutTap(false); //清一下
                        setInGame(true);

                        let user = {
                            username: gameData.roleName,
                            uid: gameData.uid,
                            token: gameData.token,
                        }
                        //保存本地再init sdk 
                        setCookie('_websdk_' + '1000137', JSON.stringify(user));
                        StringTool.saveLocalString(StoreSid_CR, gameData.serviceId);
                        StringTool.saveLocalString(StoreRid_CR, gameData.roleId);

                        setSName(gameData.serviceName);
                        setRName(gameData.roleName);

                        if (StringTool.isEmpty(gameData.serviceName)) {
                            setSName(gameData.serviceId);
                        }
                        if (StringTool.isEmpty(gameData.roleName)) {
                            setRName(gameData.roleId);
                        }

                        dispatch(settingActions.setOpmData({
                            websdk: user,
                            avatar: null,
                            state: true
                        }));

                    }
                });
            })
        } else {
            initLogin();
        }
    }

    function setCookie(key, value) {
        var d = new Date();
        d.setTime(d.getTime() + (7 * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = key + "=" + value + "; " + expires + "; path=/";
    }

    function initLogin() {
        setTimeout(() => {
            startInit();
        }, 500);
    }

    function startInit() {
        window.initCrunchyrollGame((data) => {
            showLoading(false);
            if (data.state) {
                dispatch(settingActions.setOpmData(data));
                dispatch(settingActions.showTwitchLogin(false)); //关闭登录框

                //服务列表
                window.requestRoleList(false, data.websdk.uid, data.websdk.token, (sData) => {
                    if (sData.success) {
                        dispatch(settingActions.setServiceData(sData));

                        //检查商城Role
                        checkProductStoreRole();
                    } else {
                        console.log('service list is empty')
                    }
                });
            }

        }, (errorMsg) => {
            showLoading(false);
            if (errorMsg) {
                showToast(errorMsg);
            }
        });
    }


    function showLoading(show) {
        dispatch(settingActions.showLoading(show));
    }

    function showToast(msg) {
        dispatch(settingActions.showToast(msg));
    }

    function checkProductStoreRole() {
        const sid = StringTool.getLocalString(StoreSid_CR);
        const rid = StringTool.getLocalString(StoreRid_CR);
        if (StringTool.isEmpty(rid) && settingObj.opmData) {
            dispatch(settingActions.showRoleAlert(true));
        } else {
            props.updateRoleId(sid, rid);
        }
    }

    function logoutTap(clearParam) {
        window.logoutGameCR();
        StringTool.removeLocalString(TWSid_CR);
        StringTool.removeLocalString(TWRid_CR);
        dispatch(settingActions.setServiceData(null));
        dispatch(settingActions.setOpmData(null)); //game user
        dispatch(settingActions.setServiceId(null));
        dispatch(settingActions.setRoleId(null));
        dispatch(settingActions.setTwitchDataCR(null));
        dispatch(settingActions.setTwitchCode(null));

        StringTool.removeLocalString(StoreSid_CR);
        StringTool.removeLocalString(StoreRid_CR);
        StringTool.removeLocalString(TWData_CR);

        if (clearParam && window.location.href.indexOf('thirdAuthToken') != -1) {
            window.location.href = window.location.origin + window.location.pathname;
        }
    }

    function selectRoleTap() {
        if (!inGame) {
            dispatch(settingActions.showRoleAlert(true));
        }
    }

    function getRoleName(rid) {
        if (!StringTool.isEmpty(rid) && settingObj.serviceData) {
            for (const md of settingObj.serviceData.roleList) {
                if (md.roleId == rid) {
                    if (!StringTool.isEmpty(md.roleName)) {
                        return md.roleName;
                    } else {
                        return md.roleId;
                    }
                }
            }
        }
        return ''
    }

    function getServiceName(sid) {
        if (!StringTool.isEmpty(sid) && settingObj.serviceData) {
            for (const md of settingObj.serviceData.serverList) {
                if (md.serverId == sid) {
                    if (!StringTool.isEmpty(md.serverName)) {
                        return md.serverName;
                    } else {
                        return md.serverId;
                    }
                }
            }
        }
        return ''
    }

    return (
        <Stack sx={{
            borderRadius: '6px',
            boxShadow: 3,
            alignItems: 'center',
            background: `url(${window.CDNHost}/img/payment/account_bg.png) no-repeat`,
            backgroundSize: '100% 100%',
            color: 'white',
            border: 3,
            borderColor: '#4162AE',
            pb: props.isMobile ? 2 : 3,
        }}>
            <Typography
                sx={{
                    mt: props.isMobile ? 2 : 3,
                    fontWeight: 'bold',
                    fontSize: props.isMobile ? 16 : 20,
                }}>
                OPMW Account
            </Typography>

            <Stack sx={{
                display: settingData.opmData ? 'none' : 'flex',
                mt: 2,
                width: 0.8,
                mb: 1.5,
                alignItems: 'center',
            }}>
                <Typography sx={{
                    fontSize: 13,
                    mb: 1.5,
                }}>
                    Please log in and create a character in the game before you can recharge on the page.
                </Typography>

                <LoginButton isMobile={props.isMobile} index={0} imgName='crunchyroll.png' title='Crunchyroll Login' />
                <LoginButton isMobile={props.isMobile} index={1} imgName='google_icon.png' title='Google Login' />
                <LoginButton isMobile={props.isMobile} index={2} imgName='apple.png' title='Apple Login' />
            </Stack>


            <Stack sx={{
                width: 0.8,
                display: settingData.opmData ? 'flex' : 'none',
            }}>
                <Stack sx={{
                    fontSize: props.isMobile ? 14 : 16,
                    mt: 3,
                    flexDirection: 'row',
                }}>
                    Account：{settingData.opmData && settingData.opmData.websdk.uid}
                </Stack>

                <Stack sx={{ flexDirection: 'row' }}>
                    <Typography
                        onClick={selectRoleTap}
                        sx={{
                            fontSize: props.isMobile ? 14 : 16,
                            mt: 2,
                            cursor: inGame ? '' : 'pointer',
                            borderBottom: inGame ? 0 : 2,
                            borderColor: '#A1B3D6',
                        }}>
                        Service：{props.sid && !StringTool.isEmpty(StringTool.getLocalString(StoreSid_CR)) ? getServiceName(props.sid) : (sName ? sName : 'Select Service')}
                    </Typography>

                </Stack>

                <Stack sx={{ flexDirection: 'row' }}>
                    <Typography
                        onClick={selectRoleTap}
                        sx={{
                            fontSize: props.isMobile ? 14 : 16,
                            mt: 2,
                            cursor: inGame ? '' : 'pointer',
                            borderBottom: inGame ? 0 : 2,
                            borderColor: '#A1B3D6',
                        }}>
                        Character：{props.rid && !StringTool.isEmpty(StringTool.getLocalString(StoreRid_CR)) ? getRoleName(props.rid) : (rName ? rName : 'Select Character')}

                    </Typography>
                </Stack>

                <Typography sx={{
                    fontSize: 13,
                    mt: 3,
                }}>
                    Please log in and create a character in the game before you can recharge on the page.
                </Typography>

                <Typography
                    onClick={() => { logoutTap(true) }}
                    sx={{
                        display: inGame ? 'none' : 'flex',
                        alignSelf: 'flex-end',
                        width: '52px',
                        fontSize: 14,
                        mt: 1.5,
                        borderBottom: 2,
                        borderColor: '#A1B3D6',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                    }}>
                    Logout
                </Typography>
            </Stack>

        </Stack>
    );
}


function LoginButton(props) {

    function loginTap() {
        if (props.index == 0) {
            window.crLoginTap();

        } else if (props.index == 1) {
            window.openGoogleCR();

        } else if (props.index == 2) {
            window.openAppleCR();

        }
    }

    return (
        <Stack
            onClick={loginTap}
            sx={{
                flexDirection: 'row',
                width: props.isMobile ? 1 : '200px',
                maxWidth: '200px',
                height: '40px',
                color: 'black',
                bgcolor: 'white',
                alignItems: 'center',
                mt: 1.5,
                borderRadius: '20px',
                cursor: 'pointer',
            }}>

            <Box component={'img'}
                src={window.CDNHost + '/img/' + props.imgName}
                sx={{
                    height: '30px',
                    width: '30px',
                    ml: props.isMobile ? 1 : 3,
                    mr: 1,
                }} />

            <Typography sx={{
                fontSize: props.isMobile ? 13 : 14,
            }}>
                {props.title}
            </Typography>

        </Stack>
    );

}


function ProductItem(props) {

    return (
        <Stack
            onClick={() => {
                props.productItemTap(props.data);
            }}
            sx={{
                position: 'relative',
                width: 1,
                cursor: 'pointer',
                alignItems: 'center',
                borderRadius: '10px',
                overflow: 'hidden',
                transitionDuration: '0.3s',
                '&:hover': {
                    transform: 'scale(1.03)',
                }
            }}>

            <Stack sx={{
                position: 'relative',
                alignItems: 'center',
            }}>
                <Box component={'img'}
                    src={window.CDNHost + '/img/payment/bg_1.png'}
                    sx={{
                        width: 1,
                        opacity: 0.8,
                    }} />

                <Box component={'img'}
                    src={window.CDNHost + '/img/payment/icon/' + props.data.icon}
                    sx={{
                        position: 'absolute',
                        width: props.isMobile ? 0.7 : 0.8,
                        mt: props.isMobile ? 0.5 : 1,
                    }} />

                <Stack sx={{
                    position: 'absolute',
                    bottom: '5px',
                    alignItems: 'center',
                    color: 'white',
                }}>
                    <Typography sx={{
                        textAlign: 'center',
                        fontSize: props.isMobile ? 13 : 14,
                        color: 'white',
                        fontWeight: 'bold',
                        lineHeight: '18px',
                        mb: props.data.name2 ? 0 : 1,
                    }}>
                        {props.data.name}
                    </Typography>
                    <Typography sx={{
                        display: props.data.name2 ? 'flex' : 'none',
                        lineHeight: '10px',
                    }}>
                        +
                    </Typography>

                    <Typography sx={{
                        textAlign: 'center',
                        fontSize: props.isMobile ? 13 : 14,
                        color: 'white',
                        fontWeight: 'bold',
                        lineHeight: '18px',
                    }}>
                        {props.data.name2}
                    </Typography>
                </Stack>

            </Stack>


            <Stack sx={{
                borderTop: 3,
                borderColor: '#253C80',
                position: 'relative',
                width: 1,
            }}>
                <Typography sx={{
                    background: `url(${window.CDNHost}/img/payment/bg_2.png) no-repeat`,
                    backgroundSize: '100% 100%',
                    fontWeight: 'bold',
                    color: '#FDD706',
                    textAlign: 'center',
                    fontSize: props.isMobile ? 14 : 17,
                    py: 1.4,
                }}>
                    USD {props.data.money}
                </Typography>


            </Stack>

        </Stack>
    );
}

function ProductItem2(props) {

    return (
        <Stack
            onClick={() => {
                props.productItemTap(props.data);
            }}
            sx={{
                borderRadius: '10px',
                overflow: 'hidden',
                position: 'relative',
                background: `url(${window.CDNHost}/img/payment/bg_2.png) no-repeat`,
                backgroundSize: '100% 100%',
                cursor: 'pointer',
                transitionDuration: '0.3s',
                '&:hover': {
                    transform: 'scale(1.03)',
                }
            }}>

            <Typography sx={{
                color: 'white',
                ml: 3,
                mt: 2.5,
                fontWeight: 'bold',
                fontSize: props.isMobile ? 14 : 17,
            }}>
                {props.data.name}
            </Typography>

            <Typography sx={{
                fontWeight: 'bold',
                bottom: '10px',
                color: '#FDD706',
                textAlign: 'end',
                my: 2,
                pr: 3,
                fontSize: props.isMobile ? 14 : 17,
            }}>
                USD {props.data.money}
            </Typography>

        </Stack>
    );
}

function RoleAlertCR(props) {
    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);

    const [roleList, setRoleList] = React.useState([]);
    const [sid, setSid] = React.useState('');
    const [rid, setRid] = React.useState('');

    React.useEffect(() => {
        changeService('');
        changeRole('');

        setTimeout(() => {
            startShow();
        }, 300);
    }, [settingData.roleAlertKey]);

    function startShow() {
        if (settingObj.opmData) {
            let u = settingObj.opmData;
            window.requestRoleList(false, u.websdk.uid, u.websdk.token, (sData) => {
                if (sData.success) {
                    dispatch(settingActions.setServiceData(sData));
                    loadPrevious();
                } else {
                    console.log('service list is empty')
                }
            });
        }
    }

    function loadPrevious() {
        let preSid = StringTool.getLocalString(StoreSid_CR);
        let preRid = StringTool.getLocalString(StoreRid_CR);

        if (!StringTool.isEmpty(preSid)) {
            changeService(preSid);

            if (!StringTool.isEmpty(preRid)) {
                changeRole(preRid);
            }
        }
    }

    function changeService(sid) {
        setSid(sid);
        let ary = [];
        if (settingObj.serviceData) {
            for (const md of settingObj.serviceData.roleList) {
                if (md.serverId == sid) {
                    ary.push(md);
                }
            }
        }
        setRoleList(ary);
    }

    function changeRole(rid) {
        setRid(rid);
    }

    function confirmTap() {
        if (!StringTool.isEmpty(sid) && !StringTool.isEmpty(rid)) {
            StringTool.saveLocalString(StoreSid_CR, sid);
            StringTool.saveLocalString(StoreRid_CR, rid);
            props.storeConfirmTap(sid, rid);
            closeTap();
        }
    }

    function closeTap() {
        dispatch(settingActions.showRoleAlert(false));
    }

    return (
        <Dialog
            PaperProps={{
                style: {
                    boxShadow: 'none',
                    position: 'relative',
                    borderRadius: '15px'
                },
            }}
            maxWidth={'md'}
            open={settingData.showRoleAlert}>

            <Stack
                sx={{
                    width: '380px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                <Box
                    onClick={closeTap}
                    component={'img'}
                    src={window.CDNHost + '/img/close_black.png'}
                    sx={{
                        position: 'absolute',
                        zIndex: 100,
                        width: '15px',
                        height: '15px',
                        top: 0,
                        m: 2,
                        right: 0,
                        cursor: 'pointer'
                    }} />

                <Typography sx={{
                    width: 1,
                    textAlign: 'center',
                    fontWeight: 'bold',
                    my: 3,
                    fontSize: '16px'
                }}>
                    Select Character
                </Typography>


                <Box sx={{
                    width: 0.8,
                }}>
                    {/* 服务 */}
                    <FormControl fullWidth sx={{
                        display: settingData.serviceData ? 'flex' : 'none',
                    }}>
                        <InputLabel>Select Service</InputLabel>
                        <Select
                            value={sid}
                            label="Select Service"
                            onChange={(e) => {
                                changeService(e.target.value)
                            }}>

                            {settingData.serviceData && settingData.serviceData.serverList.map((data, index) => {
                                return <MenuItem key={data.serverId} value={data.serverId}>{data.serverName}</MenuItem>
                            })}

                        </Select>
                    </FormControl>

                    {/* 角色 */}
                    <FormControl fullWidth sx={{
                        display: roleList.length ? 'flex' : 'none',
                        my: props.isMobile ? 2.5 : 3,
                    }}>
                        <InputLabel>Select Character</InputLabel>
                        <Select
                            value={rid}
                            label="Select Character"
                            onChange={(e) => { changeRole(e.target.value) }}>

                            {roleList.length > 0 && roleList.map((data, index) => {
                                return <MenuItem key={data.roleId} value={data.roleId} >{data.roleName}</MenuItem>
                            })}

                        </Select>
                    </FormControl>

                    <Typography sx={{
                        display: roleList.length ? 'none' : 'flex',
                        color: 'gray',
                        textAlign: 'center',
                        height: '55px',
                        lineHeight: '55px',
                        mt: 3,
                        justifyContent: 'center',
                    }}>
                        No character found
                    </Typography>


                    {/* 确定 */}
                    <Typography
                        onClick={confirmTap}
                        sx={{
                            height: '47px',
                            lineHeight: '47px',
                            bgcolor: !StringTool.isEmpty(sid) && !StringTool.isEmpty(rid) ? '#F76E40' : '#EFF1F4',
                            textAlign: 'center',
                            mb: 5,
                            mt: props.isMobile ? 3 : 7,
                            borderRadius: '8px',
                            color: !StringTool.isEmpty(sid) && !StringTool.isEmpty(rid) ? 'white' : '#C0C5C8',
                            cursor: 'pointer',
                            fontWeight: 'bold',
                        }}>
                        Confirm
                    </Typography>
                </Box>
            </Stack>
        </Dialog>
    );
}