import { Stack, Box, Typography, Dialog } from '@mui/material';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import InputItem from '../InputItem';
import { settingActions } from '../../store/reducers/SettingSlice';
import * as StringTool from '../..//js/StringTool';

export default function BindTwitchLoginAlert(props) {
    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);
    const [email, setEmail] = React.useState(null);
    const [password, setPassword] = React.useState(null);

    function onEmailChanged(value) {
        setEmail(value);
    }

    function onPasswordChanged(value) {
        setPassword(value);
    }

    function loginTap() {
        if (!StringTool.isEmail(email)) {
            return;
        }
        if (StringTool.isEmpty(password)) {
            return;
        }

        dispatch(settingActions.showLoading(true));
        window.apjLogin(email, password);
    }

    function closeTap() {
        dispatch(settingActions.showTwitchLogin(false));
    }


    function appleTap() {
        window.openApple();
    }

    function googleTap() {
        window.openGoogle();
    }

    return (
        <Dialog
            PaperProps={{
                style: {
                    // backgroundColor: 'transparent',
                    boxShadow: 'none',
                    position: 'relative',
                    borderRadius: '15px'
                },
            }}
            maxWidth={'md'}
            open={settingData.showTwitchLogin}>

            <Stack sx={{
                width: props.isMobile ? '310px' : '380px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}>

                <Box
                    onClick={closeTap}
                    component={'img'}
                    src={window.CDNHost + '/img/close_black.png'}
                    sx={{
                        position: 'absolute',
                        zIndex: 100,
                        width: '15px',
                        height: '15px',
                        top: 0,
                        m: 2,
                        right: 0,
                        cursor: 'pointer'
                    }} />

                <Box
                    component={'img'}
                    src={window.CDNHost + '/img/twitch/black_logo.png'}
                    sx={{
                        height: '38px',
                        objectFit: 'container',
                        mt: props.isMobile ? 2 : 3.5,
                        mb: props.isMobile ? 1 : 2,
                    }} />

                <InputItem type='email' placeholder={settingData.langDic['lang57']} onChange={onEmailChanged} />
                <InputItem type='password' placeholder={settingData.langDic['lang58']} onChange={onPasswordChanged} />

                <Box
                    onClick={loginTap}
                    sx={{
                        width: '85%',
                        height: '45px',
                        bgcolor: '#F76E40',
                        textAlign: 'center',
                        lineHeight: '45px',
                        mt: props.isMobile ? 1 : 3,
                        borderRadius: '8px',
                        color: 'white',
                        cursor: 'pointer',
                    }}>
                    Log In
                </Box>

                {/* 横线 */}
                <Stack sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: 0.85,
                    alignItems: 'center',
                    mt: props.isMobile ? 1:2,
                }}>

                    <Box sx={{
                        height: '1px',
                        flex: 1,
                        bgcolor: '#F1F1F5',
                    }} />

                    <Typography sx={{
                        fontSize: 13,
                        cursor: 'pointer',
                        mx: 2,
                        color: '#9EA2AE'
                    }}>
                        Other
                    </Typography>

                    <Box sx={{
                        height: '1px',
                        flex: 1,
                        bgcolor: '#F1F1F5',
                    }} />
                </Stack>

                {/* 苹果谷歌 */}
                <Stack sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    mt: 1,
                    mb: 3,
                }}>

                    <Box
                        onClick={googleTap}
                        component={'img'}
                        src={window.CDNHost + '/img/google_icon.png'}
                        sx={{
                            width: '34px',
                            height: '34px',
                            cursor: 'pointer',
                        }} />

                    <Box
                        onClick={appleTap}
                        component={'img'}
                        src={window.CDNHost + '/img/apple.png'}
                        sx={{
                            width: '35px',
                            height: '35px',
                            ml: 3,
                            cursor: 'pointer',
                        }} />
                </Stack>


            </Stack>

        </Dialog>
    );
}


