import { createSlice } from '@reduxjs/toolkit';
import HeroJson from '../../js/hero.json';


export const heroObj = {
    index: 0,
    posterIndex: 0,
}

export const heroSlice = createSlice({
    name: 'hero',
    initialState: {
        name: 'Saitama_dream',
        index: 0,
        skills: HeroJson['Saitama_dream']['skills'],
        videoUrls: HeroJson['videoUrls'],
        videoImages: HeroJson['videoImages'],

        heros: HeroJson['heros'],
        posters: HeroJson['posters'],
        posterIndex: 0,
    },
    reducers: {
        selectHeroName: (state, action) => {
            state.name = action.payload;
            state.index = state.heros.indexOf(state.name);
            heroObj.index = state.index;
            state.skills =  HeroJson[state.name]['skills']
        },
        selectHeroIndex: (state, action) => {
            state.index = action.payload;
            heroObj.index = state.index;
            state.name = state.heros[state.index];
            state.skills =  HeroJson[state.name]['skills'];
        },
        selectPosterIndex: (state, action) => {
            state.posterIndex = action.payload;
            heroObj.posterIndex = state.posterIndex;
        },
    }
});

export const heroActions = heroSlice.actions;
export default heroSlice.reducer;
