import { Stack, Typography, Dialog, Box } from '@mui/material';
import * as React from 'react';
import * as rdd from 'react-device-detect';


export default function RuleAlert(props) {

    function isMobile() {
        if (rdd.isMobile || window.innerWidth < 700) {
            return true;
        }
        return false;
    }

    return (
        <Dialog
            PaperProps={{
                style: {
                    boxShadow: 'none',
                    position: 'relative',
                    borderRadius: '15px'
                },
            }}
            maxWidth={'md'}
            open={props.showRule}>

            <Stack
                sx={{
                    width: isMobile() ? 1 : '700px',
                    display: 'flex',
                    flexDirection: 'column',
                    bgcolor: '#E1E1E1',
                    position: 'relative',
                }}>

                <Box
                    onClick={props.closeTap}
                    component={'img'}
                    src={window.CDNHost + '/img/close_black.png'}
                    sx={{
                        height: '18px',
                        width: '18px',
                        position: 'absolute',
                        top: '20px',
                        right: '20px',
                        cursor: 'pointer',
                    }} />

                <Typography sx={{
                    fontSize: isMobile() ? 20 : 25,
                    fontWeight: 'bold',
                    mt: 2,
                    textAlign: 'center',
                    mb: 2,
                }}>
                    Rules
                </Typography>

                <Stack sx={{
                    width: 1,
                    height: '355px',
                    overflow: 'auto'
                }}>
                    <Typography sx={{
                        mx: 5,
                    }}>
                        〓Eligibility〓
                    </Typography>

                    <Typography sx={{
                        mx: 5,
                    }}>
                        Any Hero with a Twitch account and a One Punch Man: World account can participate in the event.
                    </Typography>

                    <Typography sx={{
                        mx: 5,
                        mt: 2,
                    }}>
                        〓Event Details〓
                    </Typography>
                    <Typography sx={{
                        mx: 5,
                    }}>
                        Heroes can link their Twitch account to their One Punch Man: World account to activate Twitch Drops on this page. After linking your accounts, you can receive Drop rewards if you watch any live stream in the One Punch Man: World category and meet the watch-time requirements.
                    </Typography>

                    <Typography sx={{
                        mx: 5,
                        mt: 2,
                    }}>
                        〓FAQ〓
                    </Typography>

                    <Typography sx={{
                        mx: 5,
                    }}>
                        1. Twitch Drops is an event where you can obtain in-game rewards just by watching Twitch live streams. To participate in the Twitch Drops event, all you need to do is link your Twitch account to your One Punch Man: World account.
                    </Typography>

                    <Typography sx={{
                        mx: 5,
                        mt: 1,
                    }}>
                        2. After activating Twitch Drops, watch any stream in the One Punch Man: World category to participate in the event.
                    </Typography>

                    <Typography sx={{
                        mx: 5,
                        mt: 1,
                    }}>
                        3. You can view the Twitch Drops events you have participated in on the Twitch Drops page. You can also confirm all ongoing events and the channels you have visited in the Twitch Inventory.
                    </Typography>

                    <Typography sx={{
                        mx: 5,
                        mt: 1,
                    }}>
                        4. Game rewards will be distributed via in-game mailbox within 48 hours. The mail will expire after 30 days, so don't forget to claim your rewards in time.
                    </Typography>

                    <Typography sx={{
                        mx: 5,
                        mt: 1,
                        mb: 2,
                    }}>
                        *If you need to unlink your accounts after collecting the rewards, please wait for the rewards to be delivered via the corresponding account's in-game mail before proceeding.
                    </Typography>
                </Stack>

            </Stack>
        </Dialog>
    )
}