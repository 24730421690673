import { createSlice } from '@reduxjs/toolkit';
import * as StringTool from '../../js/StringTool';
import LangJsonFile from '../../js/lang.json';
import $ from 'jquery';

export const settingObj = {
    loadingIndex: 0,
    navIndex: 0,
    openAlert: false,
    mainOpened: false, //只一次
    enableScroll: false,
    mobileSwpIndex: 0,  //移动端
    forceAni: true,

    //twitch
    twitchData: null,
    twitchCode: null,
    opmData: null, //game user
    serviceData: null, //服务器列表
    serviceId: null,
    roleId: null,
}

export const settingSlice = createSlice({
    name: 'setting',
    initialState: {
        langKey: langKey(),
        langDic: langDic(langKey()),
        openSign: false,
        navIndex: 0,
        playingMusic: false,
        loadingIndex: 0,
        mobileSwpIndex: 0,
        showLoading: false,
        showToast: null,

        //twitch
        opmData: null,
        twitchData: null,
        serviceData: null,
        showTwitchLogin: false,
        showRoleAlert: false,
        serviceId: null,
        roleId: null,
        twitchCode: null,
        roleAlertKey: null,
    },
    reducers: {
        //twitch --start
        setTwitchData: (state, action) => {
            state.twitchData = action.payload;
            settingObj.twitchData = action.payload;

            if (settingObj.twitchData && settingObj.twitchData.code == 0) {
                const s = JSON.stringify(settingObj.twitchData);
                StringTool.saveLocalString(StringTool.TWData, s);
            } else {
                state.twitchData = null;
                settingObj.twitchData = null;
                StringTool.removeLocalString(StringTool.TWData);
            }
        },

        setTwitchDataCR: (state, action) => {
            state.twitchData = action.payload;
            settingObj.twitchData = action.payload;

            if (settingObj.twitchData && settingObj.twitchData.code == 0) {
                const s = JSON.stringify(settingObj.twitchData);
                StringTool.saveLocalString(StringTool.TWData_CR, s);
            } else {
                state.twitchData = null;
                settingObj.twitchData = null;
                StringTool.removeLocalString(StringTool.TWData_CR);
            }
        },

        setOpmData: (state, action) => {
            state.opmData = action.payload;
            settingObj.opmData = action.payload;
        },

        setTwitchCode: (state, action) => {
            state.twitchCode = action.payload;
            settingObj.twitchCode = action.payload;
        },

        setServiceData: (state, action) => {
            state.serviceData = action.payload;
            settingObj.serviceData = action.payload;
        },

        setServiceId: (state, action) => {
            state.serviceId = action.payload;
            settingObj.serviceId = action.payload;
        },

        setRoleId: (state, action) => {
            state.roleId = action.payload;
            settingObj.roleId = action.payload;
        },

        showRoleAlert: (state, action) => {
            state.showRoleAlert = action.payload;

            if (action.payload) {
                state.roleAlertKey = Math.random();
            }
        },

        showTwitchLogin: (state, action) => {
            state.showTwitchLogin = action.payload;
        },
        //twitch --end


        showLoading: (state, action) => {
            state.showLoading = action.payload;
        },

        showToast: (state, action) => {
            state.showToast = action.payload;
        },

        setMobileSwpIndex: (state, action) => {
            state.mobileSwpIndex = action.payload;
            settingObj.mobileSwpIndex = state.mobileSwpIndex;
        },

        setLoadingIndex: (state, action) => {
            state.loadingIndex = action.payload;
            settingObj.loadingIndex = state.loadingIndex;

            //主页
            if (action.payload == 3) {
                setTimeout(() => {
                    settingObj.mainOpened = true;
                }, 3000);
            }

            //主页滚动
            if (state.loadingIndex == 3) {
                setTimeout(() => {
                    settingObj.forceAni = false;
                    settingObj.enableScroll = true;
                }, 1000);
            } else {
                settingObj.forceAni = true;
                settingObj.enableScroll = false;
            }
        },

        setWindowWheelEnable: (state, action) => {
            settingObj.enableScroll = action.payload;
        },

        setLangKey: (state, action) => {
            state.langKey = action.payload;
            state.langDic = langDic(action.payload);
            StringTool.saveLocalString(StringTool.LangKey, action.payload);
        },

        showSignDalog: (state) => {
            window.fbq('track', 'opmw_register_button_tap'); //FB埋点
            window.gtag_report_conversion('HVR7CLaxo_sYEMaakr8B'); //谷歌埋点

            state.openSign = true;
            settingObj.openAlert = true;
        },

        closeSignDalog: (state) => {
            state.openSign = false;
            settingObj.openAlert = false;
        },

        setNavIndex: (state, action) => {
            state.navIndex = action.payload;
            moveView(state.navIndex);
        },

        setPreNavIndex: (state, action) => {
            if (state.navIndex > 0) {
                state.navIndex = state.navIndex - 1;
                moveView(state.navIndex);
            } else {
                state.navIndex = 5;
                moveView(5);
            }
        },

        setNextNavIndex: (state, action) => {
            if (state.navIndex < 5) { //注意个数
                state.navIndex = state.navIndex + 1;
                moveView(state.navIndex);
            } else {
                state.navIndex = 0;
                moveView(0);
            }
        },

        musicTap: (state, action) => {
            state.playingMusic = !state.playingMusic;
            const ad = $('#bg_music')[0];
            if (state.playingMusic) {
                ad.play();
            } else {
                ad.pause();
            }
        },
    }
});

function moveView(index) {
    settingObj.navIndex = index;
    $("#nav_move_view").animate({ left: getMoveLeft(index) }, "fast");
}

function getMoveLeft(index) {
    const left = getMoveWidth() * index;
    return left;
}

function getMoveWidth() {
    return $('#nav_item_0').outerWidth();
}

function langKey() {
    let k = StringTool.getLocalString(StringTool.LangKey);
    if (!StringTool.isEmpty(k)) {
        return k;
    }
    return StringTool.getBrowserLanguage();
}

function langDic(languageValue) {
    for (const key in LangJsonFile) {
        if (key == languageValue) {
            return LangJsonFile[languageValue];
        }
    }
    return null;
}

export const settingActions = settingSlice.actions

export default settingSlice.reducer
