import { Box, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { settingActions } from '../../store/reducers/SettingSlice';
import * as StringTool from '../../js/StringTool';
import * as APJRequest from '../../js/APJRequest';
import MobileDownFooter from './MobileDownFooter';


export default function MobilePreRegister() {

    const [percent, setPercent] = React.useState(0.0);
    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);

    const imgAry = ['jiangpin500k', 'jiangpin1m', 'jiangpin1.5m', 'jiangpin2m', 'jiangpin3m', 'jiangpin6m', 'jiangpin10m'];
    const unitAry = ['zi_500k', 'zi_1m', 'zi_1.5m', 'zi_2m', 'zi_3m', 'zi_6m', 'zi_10m'];
    const txtAry = ['Energy × 50<br/>World Silver × 60', 'Energy × 100<br/>World Silver × 80<br/>Regular Draw Ticket × 1', 'Energy × 120<br/>World Silver × 120<br/>Regular Draw Ticket × 2<br/>Namecard Background × 1', 'Energy × 150<br/>World Silver × 140<br/>Regular Draw Ticket × 3<br/>Random SR Impression Arms × 1', 'Energy × 180<br/>World Silver × 200<br/>Regular Draw Ticket × 4<br/>SR Character: Saitama Job Hunting × 1', 'World Silver × 200<br/>Lv.3 EXP Card × 20<br/>Credits × 30000<br/>Level 1-3 Loot Box × 2<br/>Level 1-3 Intel Box × 4', 'World Silver × 500<br/>Namecard Background × 1<br/>Sticker × 1<br/>Avatar × 1'];


    React.useEffect(() => {
        setPercent(StringTool.getPercent());
    }, []);

    function signTap() {
        dispatch(settingActions.showSignDalog());
    }

    return (
        <Stack sx={{
            height: window.innerHeight,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}>

            <Box
                component={'img'}
                src={window.CDNHost + '/img/pre_register/bg.png'}
                sx={{
                    bgcolor: '#181D2C',
                    position: 'absolute',
                    width: 1,
                    height: 1,
                    objectFit: 'cover',
                }} />


            <Box
                component={'div'}
                sx={{
                    textAlign: 'center',
                    color: 'white',
                    fontSize: 28,
                    lineHeight: '35px',
                    zIndex: 1,
                    mb: 2,
                    display: window.innerHeight < 700 ? 'none' : 'flex'
                }}
                dangerouslySetInnerHTML={{
                    __html:
                        `${settingData.langDic['lang55']}`
                }} />

            {/* 图片 */}
            <Stack sx={{
                display: 'flex',
                flexDirection: 'row',
                width: 1,
                zIndex: 1,
                mb: 1.5,
            }}>
                {
                    [0, 1, 2].map((i, x) => {
                        return (<ImageItem percent={percent} index={i} key={imgAry[i]} name={imgAry[i]} txt={txtAry[i]} unit={unitAry[i]} />);
                    })
                }
            </Stack>

            <Stack sx={{
                display: 'flex',
                flexDirection: 'row',
                width: 1,
                zIndex: 1,
            }}>
                {
                    [3, 4, 5].map((i, x) => {
                        return (<ImageItem percent={percent} index={i} key={imgAry[i]} name={imgAry[i]} txt={txtAry[i]} unit={unitAry[i]} />);
                    })
                }
            </Stack>

            <Stack sx={{
                display: 'flex',
                flexDirection: 'row',
                width: 1,
                zIndex: 1,
            }}>
                {
                    [6].map((i, x) => {
                        return (<ImageItem percent={percent} index={i} key={imgAry[i]} name={imgAry[i]} txt={txtAry[i]} unit={unitAry[i]} />);
                    })
                }
            </Stack>


            <Box
                onClick={signTap}
                component={'span'}
                sx={{
                    background: `url(${window.CDNHost}/img/blue_bg1.png) no-repeat`,
                    backgroundSize: '100% 100%',
                    color: 'white',
                    height: '47px',
                    lineHeight: '47px',
                    fontSize: 18,
                    alignItems: 'center',
                    textAlign: 'center',
                    minWidth: '138px',
                    borderRadius: 1.5,
                    px: 3,
                    zIndex: 1,
                    mt: 2,
                    display: 'none',
                }}>
                {settingData.langDic['lang6']}
            </Box>

            <Stack sx={{
                position: 'absolute',
                width: 1,
                bottom: 0,
                mb: 2,
            }}>
                <MobileDownFooter />
            </Stack>

        </Stack>
    );
}


function ImageItem(props) {
    return (
        <Stack
            key={Math.random()}
            sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                width: 1,
            }}>

            <Box component={'img'}
                src={props.percent >= (props.index + 1) * 0.14 ? `${window.CDNHost}/img/pre_register/${props.name}.png` : `${window.CDNHost}/img/pre_register/${props.name}_2.png`}
                sx={{
                    width: `${window.innerWidth * 0.2}px`,
                    mb: 0.5,
                }} />

            <Box
                component={'div'}
                sx={{
                    textAlign: 'center',
                    color: 'white',
                    fontSize: 12,
                    lineHeight: '16px',
                }}
                dangerouslySetInnerHTML={{
                    __html:
                        props.txt
                }} />

            <Box sx={{ flex: 1 }} />

            <Box
                component={'img'}
                src={`${window.CDNHost}/img/pre_register/${props.unit}.png`}
                sx={{
                    mt: 1,
                    height: '18px',
                    width: 1,
                    objectFit: 'contain',
                }} />

        </Stack>
    );
}