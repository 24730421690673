import { Box, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { settingActions, settingObj } from '../store/reducers/SettingSlice';
import * as StringTool from '../js/StringTool';
import * as APJRequest from '../js/APJRequest';

export default function HomeFooter(props) {
    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);

    function signTap() {
        // dispatch(settingActions.showSignDalog());
        windowTap();
    }

    function appleTap() {
        APJRequest.openApple(props.adPlatform);
    }

    function googleTap() {
        APJRequest.openGoogle(props.adPlatform);
    }

    function windowTap() {
        APJRequest.openPcDownload(props.adPlatform);
    }

    return (
        <Stack
            sx={{
                position: 'absolute',
                bottom: 0,
                alignItems: 'center',
                mb: 8,
                width:1,
                animation: 'homeFootAni 1s',
                zIndex: 100,
            }}>

            <Box
                onClick={signTap}
                component={'span'}
                sx={{
                    cursor: 'pointer',
                    background: `url(${window.CDNHost}/img/btn.png) no-repeat`,
                    backgroundSize: '100% 100%',
                    color: 'white',
                    pl: 6,
                    pr: 7.5,
                    py: 0.2,
                    height: '70px',
                    fontSize: 30,
                    alignItems: 'center',
                    textAlign: 'center',
                    lineHeight: '60px',
                    animation: 'homeBtAni 1.5s ease-in-out both infinite',
                    '&:hover': {
                        color: '#FFFF84',
                    }
                }}>
                {settingData.langDic['lang61']}
            </Box>

            <Stack mt={3} sx={{
                display: 'flex',
                flexDirection: 'row',
                height: { xs: '40px', sm: '45px', md: '45px' },
            }}>
                <Box
                    onClick={appleTap}
                    component={'img'} src={`${window.CDNHost}/img/btn_down1_release.png`}
                    sx={{
                        height: 1,
                        transitionDuration: '0.3s',
                        cursor: 'pointer',
                        '&:hover': {
                            transform: 'scale(1.15)',
                        }
                    }} />
                <Box
                    onClick={googleTap}
                    component={'img'} src={`${window.CDNHost}/img/btn_down2_release.png`}
                    sx={{
                        height: 1,
                        ml: 3,
                        transitionDuration: '0.3s',
                        cursor: 'pointer',
                        '&:hover': {
                            transform: 'scale(1.15)',
                        }
                    }} />
                <Box
                    onClick={windowTap}
                    component={'img'} src={`${window.CDNHost}/img/btn_down3_release.png`}
                    sx={{
                        height: 1,
                        ml: 3,
                        transitionDuration: '0.3s',
                        cursor: 'pointer',
                        '&:hover': {
                            transform:'scale(1.15)',
                        }
                    }} />

            </Stack>

        </Stack>
    );
}