import { Box, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { settingActions, settingObj } from '../../store/reducers/SettingSlice';
import $ from 'jquery';

export default function MobileLanguage(props) {

    const dispatch = useDispatch();

    function itemTap(langKey) {
        dispatch(settingActions.setLangKey(langKey));
        props.closeLanguage();
    }

    return (
        <Stack sx={{
            width: window.innerWidth * 0.7,
            height: window.innerHeight,
            bgcolor: 'black',
        }}>

            <Box component={'img'}
                onClick={props.closeLanguage}
                src={window.CDNHost+'/img/close.png'}
                sx={{
                    width: '20px',
                    mt: 4,
                    mb: 5,
                    mr: 3,
                    alignSelf: 'flex-end',
                }} />

            <Item LKey="EN" title='ENGLISH' itemTap={itemTap} />
            <Item LKey="FR" title='FRANÇAIS' itemTap={itemTap} />
            <Item LKey="PT" title='PORTUGUÊS' itemTap={itemTap} />
            <Item LKey="DE" title='DEUTSCH' itemTap={itemTap} />
            <Item LKey="ES" title='ESPAÑOL' itemTap={itemTap} />

        </Stack>
    );
}

function Item(props) {
    return (
        <Stack onClick={() => props.itemTap(props.LKey)}>
            <Typography sx={{
                color: 'white',
                fontFamily: 'us',
                fontSize: 18,
                height: '70px',
                lineHeight: '70px',
                textAlign: 'start',
                ml: 3,
            }}>
                {props.title}
            </Typography>
            <Box sx={{
                width: 1,
                height: '1px',
                bgcolor: '#C9CDD140',
            }} />
        </Stack>
    );

}