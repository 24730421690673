import { Box, Stack } from '@mui/material';
import * as React from 'react';
import $ from 'jquery';
import * as rdd from 'react-device-detect';
import '../../src/TimeCss.css';

export default function TimeCountPage(props) {

    const [finish, setFinish] = React.useState(false);

    function homeTap() {
        window.open('https://eu.onepunchmanworld.com');
    }

    return (
        <Stack sx={{
            width: 1,
            height: window.innerHeight,
        }}>
            <Box
                component={'img'}
                src={window.CDNHost + '/img/timeCount/bg.jpg'}
                sx={{
                    position: 'absolute',
                    width: 1,
                    height: 1,
                    objectFit: 'cover',
                }} />

            <Box
                component={'video'}
                autoPlay={true}
                loop={true}
                muted={true}
                src={window.CDNHost + '/img/timeCount/video2.mp4'}
                sx={{
                    width: 1,
                    height: 1,
                    objectFit: 'cover',
                    position: 'absolute',
                }} />

            <Stack sx={{
                width: 1,
                height: 1,
                alignItems: 'center',
                position: 'absolute'
            }}>


                <Box component={'img'}
                    src={window.CDNHost + '/img/timeCount/release.png'}
                    sx={{
                        display: finish ? 'none' : 'flex',
                        height: '80px',
                        mt: '90px',
                    }} />

                <Box component={'img'}
                    src={window.CDNHost + '/img/timeCount/now.png'}
                    sx={{
                        display: finish ? 'flex' : 'none',
                        height: '130px',
                        mt: '120px'
                    }} />

                <TimeItem finish={finish} setFinish={setFinish} />

            </Stack>

            <Box component={'img'}
                src={window.CDNHost + '/img/logo.png'}
                sx={{
                    height: '55px',
                    position: 'absolute',
                    m: 4,
                }} />

            <Box component={'img'}
                src={`${window.CDNHost}/img/payment/copy_right.png`}
                sx={{
                    height: '40px',
                    position: 'absolute',
                    bottom: '20px',
                    right: '-20px',
                    zIndex: 9999,
                }} />

            <Box
                onClick={homeTap}
                component={'span'}
                sx={{
                    background: `url(${window.CDNHost}/img/timeCount/button.png) no-repeat`,
                    backgroundSize: '100% 100%',
                    color: 'white',
                    width: '290px',
                    height: '63px',
                    lineHeight: '63px',
                    fontSize: 32,
                    alignItems: 'center',
                    textAlign: 'center',
                    position: 'absolute',
                    bottom: '70px',
                    right: '45px',
                    cursor: 'pointer',
                    animation: 'homeBtAni 2.5s ease-in-out both infinite',
                    zIndex: 9999,
                }}>
                VISIT HOMEPAGE
            </Box>

        </Stack>
    );
}

function TimeItem(props) {
    let isStart = false;
    let isFinish = false;
    let deadLine = '2024-02-01 09:00:00'; //截止时间

    React.useEffect(() => {
        start();
    }, []);

    function start() {
        if (isStart) {
            return;
        }

        isStart = true;
        //格式化时间
        const formatTime = (time) => {
            if (time < 10) time = '0' + time
            return time
        }
        //上半部分动画
        const rotateUp = (ele, time, n) => {
            let rotateDeg = 0;
            ele.style.zIndex = 50;
            //这个是所有下面的盒子
            const allDownBox = document.querySelector(`li:nth-child(${n})`).querySelectorAll('.downBox');
            //所有前面的盒子
            const beforeTime = document.querySelector(`li:nth-child(${n})`).querySelectorAll('.beforeTime');
            // 让下面后面的盒子先不可见，然后设置为 -270°
            allDownBox[1].style.display = 'none';
            allDownBox[1].transform = `rotateX(-270deg)`;
            const animation = () => {
                rotateDeg -= 3;
                ele.style.transform = `perspective(500px) rotateX(${rotateDeg}deg)`;
                if (rotateDeg == -90) {
                    //让它更新为最近时间后隐藏
                    ele.innerHTML = time
                    ele.style.zIndex = -1;
                    //让刚刚上面隐藏的盒子重新显示出来并且完成-270°到-360°的旋转
                    allDownBox[1].style.display = 'block';
                    allDownBox[0].style.zIndex = 1;
                    rotateDown(allDownBox[1])
                    allDownBox[1].style.zIndex = 1;
                }
                if (rotateDeg == -150) {
                    beforeTime[1].innerHTML = time;
                }
                if (rotateDeg > -180) {
                    requestAnimationFrame(animation);
                }
            }
            animation()
        }

        //下半部分动画
        const rotateDown = (ele) => {
            let rotateDeg = -270;
            const animation = () => {
                rotateDeg -= 3;
                ele.style.transform = `perspective(500px) rotateX(${rotateDeg}deg)`;
                if (rotateDeg > -360) {
                    requestAnimationFrame(animation);
                }
            }
            animation()
        }

        function getTotalSeconds() {
            const big = getBjDate(new Date(deadLine));
            const small = getSmallDate(new Date());
            const totalSeconds = Math.ceil((big - small) / (1000));
            if (totalSeconds <= 0) {
                isFinish = true;
                props.setFinish(true);
            }
            return totalSeconds;
        }

        function getSmallDate(date) {
            // 本地时间距离（GMT时间）毫秒数
            let nowDate = !date ? new Date().getTime() : new Date(date).getTime()
            // 本地时间和格林威治时间差，单位分钟
            let offset_GMT = new Date().getTimezoneOffset() //东八区是 -(8*60)
            //  反推到格林尼治时间
            let GMT = nowDate + offset_GMT * 60 * 1000
            //  获取指定时区时间
            let targetDate = new Date(GMT + (8 * 60 * 60 * 1000) + (480 + offset_GMT) * 60 * 1000)  //先推算到北京时间，再加上北京时间和它的差值
            return targetDate
        }

        function getBjDate(date) {
            // 本地时间距离（GMT时间）毫秒数
            let nowDate = !date ? new Date().getTime() : new Date(date).getTime()
            // 本地时间和格林威治时间差，单位分钟
            let offset_GMT = new Date().getTimezoneOffset()
            //  反推到格林尼治时间
            let GMT = nowDate + offset_GMT * 60 * 1000
            //  获取指定时区时间
            let targetDate = new Date(GMT + 8 * 60 * 60 * 1000)
            return targetDate
        }

        let totalSeconds = getTotalSeconds();
        let oldHour = Math.floor(totalSeconds / 3600);
        var oldMinute = Math.floor(totalSeconds / 60 % 60);
        var oldSecond = totalSeconds % 60;
        document.querySelector('li:nth-child(1)').querySelectorAll('.beforeTime').forEach(ele => ele.innerHTML = formatTime(oldHour));
        document.querySelector(' li:nth-child(3)').querySelectorAll('.beforeTime').forEach(ele => ele.innerHTML = formatTime(oldMinute));
        document.querySelector(' li:nth-child(5)').querySelectorAll('.beforeTime').forEach(ele => ele.innerHTML = formatTime(oldSecond));

        const changeTime = () => {
            if (isFinish) {
                return;
            }

            let totalSeconds = getTotalSeconds(); //new Date();
            let hour = Math.floor(totalSeconds / 3600);
            let minute = Math.floor(totalSeconds / 60 % 60);
            let second = totalSeconds % 60;
            const setHourBox = document.querySelector('li:nth-child(1)').querySelectorAll('.afterTime');
            if (!setHourBox[0].innerHTML || setHourBox[0].innerHTML != formatTime(hour)) {
                if (setHourBox[0].innerHTML) rotateUp(document.querySelector('li:nth-child(1)').querySelectorAll('.beforeTime')[0], formatTime(hour), 1);
                setHourBox.forEach(ele => ele.innerHTML = formatTime(hour));
            }
            const setMinuteBox = document.querySelector('li:nth-child(3)').querySelectorAll('.afterTime');
            if (!setMinuteBox[0].innerHTML || setMinuteBox[0].innerHTML != formatTime(minute)) {
                if (setMinuteBox[0].innerHTML) rotateUp(document.querySelector('li:nth-child(3)').querySelectorAll('.beforeTime')[0], formatTime(minute), 3);
                setMinuteBox.forEach(ele => ele.innerHTML = formatTime(minute));
            }
            const setSecondBox = document.querySelector('li:nth-child(5)').querySelectorAll('.afterTime')
            setSecondBox.forEach(ele => ele.innerHTML = formatTime(second));
            rotateUp(document.querySelector('li:nth-child(5)').querySelectorAll('.beforeTime')[0], formatTime(second), 5)

            setTimeout(changeTime, 1000);
        }
        changeTime();
    }

    return (
        <Stack sx={{
            display: props.finish ? 'none' : 'flex'
        }}>
            <div className="show">
                <ul className="time">
                    <li>
                        <div className="upBox beforeTime"></div>
                        <div className="downBox beforeTime"></div>
                        <div className="upBox afterTime"></div>
                        <div className="downBox afterTime"></div>
                    </li>
                    <li className='mh'>:</li>
                    <li>
                        <div className="upBox beforeTime"></div>
                        <div className="downBox beforeTime"></div>
                        <div className="upBox afterTime"></div>
                        <div className="downBox afterTime"></div>
                    </li>
                    <li className='mh'>:</li>
                    <li>
                        <div className="upBox beforeTime"></div>
                        <div className="downBox beforeTime"></div>
                        <div className="upBox afterTime"></div>
                        <div className="downBox afterTime"></div>
                    </li>
                </ul>
            </div>
        </Stack>
    );
}