import { Stack, Box, Typography } from '@mui/material';
import * as React from 'react';


export default function Page0(props) {

    return (
        <Stack sx={{
            width: 1,
            height: 1,
            overflow: 'hidden',
        }}>
            <Box
                component={'img'}
                src={window.CDNHost + '/img/twitch/big_bg.jpg'}
                sx={{
                    position: 'absolute',
                    width: 1,
                    height: 1,
                    objectFit: 'cover',
                }} />

            <Box
                component={'img'}
                src={window.CDNHost + '/img/logo.png'}
                sx={{
                    zIndex: 1,
                    position: 'absolute',
                    height: props.isMobile ? '35px':'60px',
                    ml: 4,
                    mt: 3,
                }} />

            <Stack sx={{
                width: 1,
                height: 1,
                position: 'absolute',
                zIndex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}>

                <Stack sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'end',
                    width: 1,
                    mt: 3,
                    flex: 1,
                }}>
                </Stack>

                <Box
                    component={'img'}
                    src={window.CDNHost + '/img/twitch/slogan.png'}
                    sx={{
                        height: props.isMobile ? 'auto': '200px',
                        width: props.isMobile ? 1: 'auto',
                        mb: 5,
                    }} />

                <Box
                    onClick={props.linkTap}
                    component={'span'}
                    sx={{
                        cursor: 'pointer',
                        background: `url(${window.CDNHost}/img/btn.png) no-repeat`,
                        backgroundSize: '100% 100%',
                        color: 'white',
                        pl: 10,
                        pr: 11.5,
                        py: 0.2,
                        mb: 10,
                        height: props.isMobile ? '60px': '70px',
                        fontSize: props.isMobile ? 23: 30,
                        alignItems: 'center',
                        textAlign: 'center',
                        lineHeight: props.isMobile ? '50px': '60px',
                        fontFamily: 'us',
                        '&:hover': {
                            color: '#FFFF84',
                        }
                    }}>
                    Link Now
                </Box>

                {/* 底部提示 */}
                <Stack direction={'column'}
                    sx={{
                        position: 'absolute',
                        width: 1,
                        height: '45px',
                        bottom: 0,
                        alignItems: 'center',
                        animation: 'scrollerAni 2s ease-in-out both infinite',
                    }}>
                    <Box component={'img'} src={window.CDNHost + '/img/down_icon.png'}
                        sx={{
                            width: '30px',
                        }} />
                    <Box component={'img'} src={window.CDNHost + '/img/down_icon.png'}
                        sx={{
                            width: '30px',
                        }} />
                </Stack>

            </Stack>

        </Stack>
    );

}
