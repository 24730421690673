import axios from 'axios';
import * as StringTool from './StringTool';
import CryptoJS from 'crypto-js';
import JSEncrypt from 'jsencrypt';

// 测试环境
// const AppId = '131620939455565824';
// const Host = 'https://sdk-staging.aplusjapan-game.com';

//正式环境
const AppId = '150808163195559936';
const Host = 'https://sdk-production.aplusjapan-game.com';

const ChannelId = '33';
const PreRegister = '/api/gameowbackend/v1/email_pre_register';
const CountryAddress = '/api/gameowbackend/v1/device_ip_geo';


//提交预注册
export function preRegister(email, country, onSuccess, onFail) {
    const p = {
        "email": email,
        "country": country
    }
    post(PreRegister, p, onSuccess, onFail);
}

//获取国家Code
export function getCountryCode(onSuccess, onFail) {
    post(CountryAddress, null, (data) => {
        onSuccess(data['countryCode']);
    }, onFail);
}

export function openGameWeb() {
    window.open('https://eu.onepunchmanworld.com');
}

export function openDiscord() {
    window.open('https://discord.gg/one-punch-man-world');
}

export function openTwitter() {
    window.open('https://twitter.com/onepunchmanw_eu');
}

export function openFacebook() {
    window.open('https://www.facebook.com/onepunchmanworldEUMENA');
}

export function openQuestion() {
    window.open('https://got.cr/cbt-signup');
}

export function openPrivacy() {
    window.open('https://eu.onepunchmanworld.com/privacy');
}

export function openTerm() {
    window.open('https://eu.onepunchmanworld.com/terms');
}

export function openService() {//客服表单不用了，用帮助中心FAQ
    window.open('https://aihelp.net/form/#?unionId=1c4a7321cfec4d138c53b467afc1ffa2&ep=6962780c8a259bf2f0f00c5c26db1b8d0952f1a744ad20f17438b746b9f35c37a45d2283285f33af');
}

function enableAF() {
    let obj = {};
    let str = window.location.href.split("?")[1];
    if (str) {
        var items = str.split("&");
        var arr, name, value;
        for (var i = 0; i < items.length; i++) {
            arr = items[i].split("=");
            name = arr[0];
            value = arr[1];
            obj[name] = value;
        }
    }

    let utm_source = obj.utm_source;
    let pid = obj.pid;

    if (utm_source || pid) {
        return true;
    }
    return false;
}

export function openApple(adPlatform) {
    window.gtag("event", "ob_pre_download_apple");
    window.fbq('track', 'ob_pre_download_apple');

    window.fbq('track', 'opmw_apple_button_tap'); //FB埋点
    window.gtag_report_conversion('-BSACMGMovsYEMaakr8B'); //谷歌埋点

    window.twq('event', 'tw-oi00p-oi02w', {
        conversion_id: null, // use this to pass a unique ID for the conversion event for deduplication (e.g. order id '1a2b3c')
        email_address: null, // use this to pass a user’s email address
        phone_number: null // phone number in E164 standard
    });

    let url = 'https://itunes.apple.com/fr/app/id6446193358';
    if ('fbpre' == adPlatform) {
        url = 'https://apps.apple.com/fr/app/one-punch-man-world/id6446193358?ppid=f342f073-7f1a-4856-a401-e56fda1c3f2b';
    } else if ('ggpre' == adPlatform) {
        url = 'https://apps.apple.com/fr/app/one-punch-man-world/id6446193358?ppid=79ab3dee-4820-4f09-abd0-ab977a30e729';
    } else if ('ttpre' == adPlatform) {
        url = 'https://apps.apple.com/fr/app/one-punch-man-world/id6446193358?ppid=e4d66313-d2d8-46b5-898f-da0b733a5540';
    } else if ('twpre' == adPlatform) {
        url = 'https://apps.apple.com/fr/app/one-punch-man-world/id6446193358?ppid=487e33e5-7ec2-4a2a-8996-abd0292b1fb1';
    } else if ('fbpre01' == adPlatform) {
        url = 'https://apps.apple.com/fr/app/one-punch-man-world/id6446193358?ppid=8e13994e-5faa-4612-a354-1ac8d6d75a3a';
    }

    // 正式环境
    else if ('ttopen' == adPlatform) {
        url = 'https://opmwapj.onelink.me/DwBR/ynifv8pe';
    }
    else if ('ggob' == adPlatform) {
        url = 'https://apps.apple.com/fr/app/one-punch-man-world/id6446193358?ppid=d73ac4bd-91a7-447f-b2d4-2eec1bc589a0';
    }

    let iOSClickUrl = url;
    if (enableAF()) {
        iOSClickUrl = window.getDirectURL('ios', 'id6446193358', url);
    }
    window.open(iOSClickUrl);
}

export function openGoogle(adPlatform) {
    window.gtag("event", "ob_pre_download_google");
    window.fbq('track', 'ob_pre_download_google');

    window.fbq('track', 'opmw_google_button_tap'); //FB埋点
    window.gtag_report_conversion('NjZ9CMSMovsYEMaakr8B'); //谷歌埋点

    window.twq('event', 'tw-oi008-oi039', {
        conversion_id: null, // use this to pass a unique ID for the conversion event for deduplication (e.g. order id '1a2b3c')
        email_address: null, // use this to pass a user’s email address
        phone_number: null // phone number in E164 standard
    });

    let url = 'https://play.google.com/store/apps/details?id=com.opmw.eu';
    if ('fbpre' == adPlatform) {
        url = 'https://play.google.com/store/apps/details?id=com.opmw.eu&listing=opmw_preg_fb_20231212';
    } else if ('ggpre' == adPlatform) {
        url = 'https://play.google.com/store/apps/details?id=com.opmw.eu&listing=opmw_preg_gg_20231212';
    } else if ('ttpre' == adPlatform) {
        url = 'https://play.google.com/store/apps/details?id=com.opmw.eu&listing=opmw_preg_tt_20231212';
    } else if ('twpre' == adPlatform) {
        url = 'https://play.google.com/store/apps/details?id=com.opmw.eu&listing=opmw_preg_tw_20231212';
    }

    // 正式环境
    else if ('ttopen' == adPlatform) {
        url = 'https://opmwapj.onelink.me/DwBR/ynifv8pe';
    }

    let androidClickUrl = url;
    if (enableAF()) {
        androidClickUrl = window.getDirectURL('android', 'com.opmw.eu', url);
    }
    window.open(androidClickUrl);
}

export function openPcDownload(adPlatform) {
    window.gtag("event", "ob_pre_download_pc");
    window.fbq('track', 'ob_pre_download_pc');
    
    if ('ttopen' == adPlatform) {
        window.open('https://opmwapj.onelink.me/DwBR/ynifv8pe');

    } else {
        window.fbq('track', 'opmw_windows_button_tap'); //FB埋点
        window.gtag_report_conversion('exWtCOGvo_sYEMaakr8B'); //谷歌埋点

        let url = 'https://game-cdn.aplusjapan-game.com/opmw/download/OPM_EURO_OB.exe';
        let pcClickUrl = url;
        
        if (enableAF()) {
            pcClickUrl = window.getDirectURL('nativepc', 'OPWM6446193358', url);
        }
        window.open(pcClickUrl);
    }
}

export function openFAQ() {
    let appKey = "APLUSJAPAN_app_763c7903628f4dbfbf8a6b84c304c1e6";
    let domain = "aplusjapan.aihelp.net";
    let appId = "aplusjapan_platform_1781a70bb2613c8d00ecc58dc8913c32";
    let language = "en";
    let entranceId = "Help001";

    let url = `https://${domain}/webchatv4/#/appKey/${appKey}/domain/${domain}/appId/${appId}?entranceId=${entranceId}&language=${language}`;
    window.open(url);
}

//--------base--------
export function post(url, param, onSuccess, onFail) {
    axios.post(Host + url, param, {
        headers: {
            'X-Sign': getSign(param),
            'X-AppId': AppId,
            'X-ChannelId': ChannelId,
        },
    }).then(({ data }) => {
        console.log(data);
        if (data.code === 0) { //成功
            onSuccess(data.data);
        } else {
            onFail(data.code, data.msg);
        }
    }).catch(function (error) {
        console.log(error);
        onFail(-1000, 'Request fail, try again later.');
    });
}

function getSign(param) {
    let x = AppId + ChannelId;
    if (param != null) {
        const str = JSON.stringify(param);
        if (!StringTool.isEmpty(str)) {
            x = x + str;
        }
    }

    let sign = new JSEncrypt();
    sign.setPrivateKey(window.apj_x());
    return sign.sign(x, CryptoJS.SHA256, "sha256");
}