import { Box, Stack, Typography } from '@mui/material';
import * as React from 'react';

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";

import { useSelector, useDispatch } from 'react-redux'
import { heroActions } from '../store/reducers/HeroSlice';


export default function Character(props) {

    const dispatch = useDispatch();
    const heroData = useSelector(state => state.heroData);

    const [spWidth, setSpWidth] = React.useState(1000);
    const [swp, setSwp] = React.useState(null);
    const [cellAni, setCellAni] = React.useState(null);
    const [cellKey, setCellKey] = React.useState('cellKey');

    React.useEffect(() => {
        fitWith();
        addWindowListener();
    }, []);

    function addWindowListener() {
        window.addEventListener(
            'resize',
            () => {
                fitWith();
            }
        );
    }

    function leftTap() {
        if (swp.activeIndex <= 2) {
            return;
        }
        swp.slidePrev();
    }

    function rightTap() {
        if (swp.activeIndex >= heroData.heros.length - 3) {
            return;
        }
        swp.slideNext();
    }

    function slideTap() { //item 点击
        setCellAni('');
        setCellKey(Math.random()); //为了点击动画
        dispatch(heroActions.selectHeroIndex(swp.activeIndex));

        setTimeout(() => {
            props.pageChange(1);
        }, 499);
    }

    function fitWith() {
        let w = window.innerWidth * 0.82;
        if (w < 600) {
            w = 600;
        }
        setSpWidth(w);
    }

    return (
        <Stack
            sx={{
                zIndex: 0,
                display: 'flex',
                alignItems: 'center',
                width: 1,
                flex: 1,
                overflow: 'visible',
                position: 'relative',
                justifyContent: 'center',
            }}>

            <Box
                component={'img'}
                src={window.CDNHost+'/img/bg2.png'}
                sx={{
                    position: 'absolute',
                    width: 1,
                    height: 1,
                    objectFit: 'cover',
                }} />

            <Stack sx={{
                position: 'relative',
                width: spWidth,
                height: spWidth * 0.45,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                overflow: 'visible',
                mt: '40px',
                mr: 10,
            }} >
                <Box component={'img'}
                    src={window.CDNHost+"/img/left_bt.png"}
                    onClick={leftTap}
                    sx={{
                        cursor: 'pointer',
                        zIndex: 10,
                        width: '50px',
                        pr: 3,
                        transitionDuration: '0.3s',
                        mb: 5,
                        '&:hover': {
                            transform: 'scale(1.1)',
                            filter: 'drop-shadow(1px 1px 6px #3159BE)  brightness(130%)',
                        },
                    }} />

                {/* Swiper Banner */}
                <Swiper
                    centeredSlides={true}
                    slideToClickedSlide={true}

                    onClick={(sp, e) => {
                        slideTap();
                    }}

                    onSwiper={(swiper) => {
                        setSwp(swiper);
                        
                        if (heroData.index <= 2) {
                            swiper.activeIndex = 2;
                        } else if (heroData.index >= heroData.heros.length - 3) {
                            swiper.activeIndex = heroData.heros.length - 3;
                        } else {
                            swiper.activeIndex = heroData.index;
                        }
                    }}

                    slidesPerView={5}

                    style={{
                        height: '100%',
                        width: '100%',
                        transform: 'skew(-19.7deg)',
                        overflowY: 'visible',
                    }}>

                    {heroData.heros.map((value, i) => {
                        return <SwiperSlide key={i} style={{
                        }}>
                            <Item key={cellKey} value={value} index={i} ani={cellAni == null ? (i % 2 == 0 ? 'heroBottomToUp' : 'heroUpToBottom') : (i < swp.activeIndex ? 'fadeLeft' : (i > swp.activeIndex ? 'fadeRight' : 'fadeCenter'))} />
                        </SwiperSlide>;
                    })}

                </Swiper>


                <Box component={'img'}
                    onClick={rightTap}
                    src={window.CDNHost+"/img/right_bt.png"}
                    sx={{
                        cursor: 'pointer',
                        zIndex: 10,
                        width: '50px',
                        pl: 3,
                        mb: 5,
                        ml: 1,
                        transitionDuration: '0.3s',
                        '&:hover': {
                            transform: 'scale(1.1)',
                            filter: 'drop-shadow(1px 1px 6px #3159BE) brightness(130%)',
                        },
                    }} />

            </Stack>

        </Stack>

    );
}

function Item(props) {
    return (
        <Stack sx={{
            cursor: 'pointer',
            height: 1,
            width: 1,
            transitionDuration: '0.3s',
            '&:hover': {
                transform: 'scale(1.1)',
                filter: 'drop-shadow(2px 4px 15px #745ff2)',
            },
            animation: `${props.ani} 0.5s`,
            animationDelay: (props.ani == 'heroBottomToUp' || props.ani == 'heroUpToBottom' ? '0s' : '0.3s')
        }}>
            <Box
                component={'img'}
                src={window.CDNHost+`/img/hero_big/${props.value}_big.png`}
                disabled
                sx={{
                    pointerEvents: 'none',
                    position: 'absolute',
                    height: 1,
                    transform: 'skew(19.7deg)',
                    top: props.index % 2 == 0 ? '0' : '-50px',
                    left: '-65%',
                }} />
        </Stack>
    );
}