import { Stack, Drawer, Box } from '@mui/material';
import * as React from 'react';

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Mousewheel } from 'swiper/modules';
import { useSelector, useDispatch } from 'react-redux';
import { settingActions } from '../../store/reducers/SettingSlice';
import MobileNav from './MobileNav';
import * as APJRequest from '../../js/APJRequest';

import MobileHome from './MobileHome';
import MobileHero from './MobileHero';
import MobileVideo from './MobileVideo';
import MobilePoster from './MobilePoster';
import MobileNews from './MobileNews';
import MobileRegister from './MobileRegister';
import MobilePreRegister from './MobilePreRegister';


export default function MobileMain() {

    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);
    const [swp, setSwp] = React.useState(null);

    function closeRegister() {
        dispatch(settingActions.closeSignDalog());
    }

    function indexChanged() {
        dispatch(settingActions.setMobileSwpIndex(swp.activeIndex));
    }

    function slideTo(i) {
        swp.slideTo(i);
    }

    function gifTap() {
        APJRequest.openQuestion();
    }

    return (
        <Stack sx={{
            height: window.innerHeight,
            overflow: 'hidden',
        }}>
            <Swiper
                direction={'vertical'}
                slidesPerView={1}
                mousewheel={true}
                className="mySwiper"
                modules={[Mousewheel]}
                resistanceRatio={0}

                onSwiper={(sp) => {
                    setSwp(sp);
                    sp.activeIndex = settingData.mobileSwpIndex;
                }}

                onActiveIndexChange={(sp) => {
                    indexChanged();
                }}

                style={{
                    width: '100%',
                    background: 'white',
                }}>

                <SwiperSlide style={{ overflow: 'hidden' }}><MobileHome slideTo={slideTo} /></SwiperSlide>
                <SwiperSlide style={{ overflow: 'hidden' }}><MobilePreRegister /></SwiperSlide>
                <SwiperSlide style={{ overflow: 'hidden' }}><MobileNews /></SwiperSlide>
                <SwiperSlide style={{ overflow: 'hidden' }}><MobileHero /></SwiperSlide>
                <SwiperSlide style={{ overflow: 'hidden' }}><MobileVideo /></SwiperSlide>
                <SwiperSlide style={{ overflow: 'hidden' }}><MobilePoster /></SwiperSlide>
            </Swiper>

            <MobileNav slideTo={slideTo} />

            <Box component={'img'} src={window.CDNHost+'/img/float_icon.gif'}
                onClick={gifTap}
                sx={{
                    display: 'none',
                    height: '75px',
                    width: '75px',
                    position: 'absolute',
                    zIndex: 100,
                    top: '41%',
                    right: '2px',
                }} />

            <Drawer
                anchor={'bottom'}
                open={settingData.openSign}
                onClose={closeRegister}
                PaperProps={{
                    style: {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                    },
                }}>
                <MobileRegister closeRegister={closeRegister} />
            </Drawer>

        </Stack>
    );
}