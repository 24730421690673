import { Box, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCreative, EffectCoverflow } from 'swiper/modules';
import { heroActions, heroObj } from '../../store/reducers/HeroSlice';
import { settingActions } from '../../store/reducers/SettingSlice';
import 'swiper/css';
import 'swiper/css/effect-creative';
import MobileDownFooter from './MobileDownFooter';
import * as APJRequest from '../../js/APJRequest';
import MobileFooter from './MobileFooter';


export default function MobilePoster() {

    const settingData = useSelector(state => state.settingData);
    const X = 0.5625;
    const [imgWidth, setImgWidth] = React.useState(window.innerWidth * 0.95);

    const dispatch = useDispatch();
    const heroData = useSelector(state => state.heroData);
    const [backIndex, setBackIndex] = React.useState(heroData.posters.length - 1);
    const [swp, setSwp] = React.useState(null);


    React.useEffect(() => {
        dispatch(heroActions.selectPosterIndex(0));
        setTimeout(() => {
            setBackIndex(0);
        }, 610);
    }, []);

    function indexChanged() {
        if (swp == null) {
            return;
        }

        if (heroData.posterIndex != swp.realIndex) {
            dispatch(heroActions.selectPosterIndex(swp.realIndex));
            setTimeout(() => {
                setBackIndex(swp.realIndex);
            }, 650);
        }
    }

    function imageTap(i) {

    }

    function privacyTap() {
        APJRequest.openPrivacy();
    }

    function termTap() {
        APJRequest.openTerm();
    }

    function serviceTap(){//客服表单
       APJRequest.openFAQ();
    }

    return (
        <Stack sx={{
            height: 1,
            display: 'flex',
            justifyContent: 'center',
            bgcolor: '#181D2C',
        }}>
            {/* 背景 */}
            <Box component={'img'}
                src={window.CDNHost+'/img/mobile/m_bg3.jpg'}
                sx={{
                    zIndex: 0,
                    position: 'absolute',
                    width: 1,
                    height: 1,
                    objectFit: 'cover',
                }} />

            <Typography sx={{
                zIndex: 5,
                color: 'white',
                fontFamily: 'us',
                fontSize: 30,
                ml: 3,
                mb: 1,
            }}>
                {settingData.langDic['lang4']}
            </Typography>

            {/* 海报 */}
            <Stack sx={{
                zIndex: 3,
                position: 'relative',
                width: imgWidth,
                height: imgWidth * X,
                alignSelf: 'center',
                border: 1,
                borderColor: '#4B4D68',
            }}>

                <Box
                    component={'img'}
                    src={window.CDNHost+`/img/hero_poster/${heroData.posters[backIndex]}`}
                    sx={{
                        bgcolor: 'black',
                        zIndex: 1,
                        position: 'absolute',
                        width: 1,
                        height: 1,
                        objectFit: 'cover',
                    }} />

                <Box
                    key={'front_img' + heroData.posterIndex} //为了每次刷新
                    component={'img'}
                    src={window.CDNHost+`/img/hero_poster/${heroData.posters[heroData.posterIndex]}`}
                    sx={{
                        display: 'block',
                        zIndex: 10,
                        position: 'absolute',
                        width: 1,
                        height: 1,
                        objectFit: 'cover',

                        //mask 动画
                        mask: `url(${window.CDNHost}/img/masky.png)`,
                        maskImage: `url(${window.CDNHost}/img/masky.png)`,
                        WebkitMaskImage: `url(${window.CDNHost}/img/masky.png)`,
                        maskPosition: '0 0',
                        maskSize: '4000% 100%',
                        animation: 'maskMove .6s steps(39) forwards',
                    }} />

            </Stack>

            {/* swiper */}
            <Swiper
                effect={'coverflow'}
                pagination={true}
                modules={[EffectCoverflow]}
                loop={true}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={'auto'}
                slideToClickedSlide={true}

                coverflowEffect={{
                    rotate: 0,
                    stretch: 30,
                    depth: 500,
                    modifier: 1,
                    slideShadows: true,
                }}
                onSwiper={(sp) => {
                    setSwp(sp);
                }}

                onActiveIndexChange={(sp) => {
                    indexChanged();
                }}

                style={{
                    zIndex: 3,
                    marginTop: '30px',
                    width: `${imgWidth - 180}px`,
                    height: `${(imgWidth - 180) * X}px`,
                    paddingLeft: '70px',
                    paddingRight: '70px',
                    marginBottom: '70px',
                }}>

                {
                    heroData.posters.map((value, i) =>
                        <SwiperSlide
                            key={i}
                            style={{
                                display: 'flex',
                                background: 'black',
                            }}>
                            <Item value={value} index={i} activeIndex={heroData.posterIndex} imageTap={imageTap} />
                        </SwiperSlide>
                    )
                }

            </Swiper>

            {/* footer */}
            <MobileFooter/>

        </Stack>
    );
}

function Item(props) {
    return (
        <Box
            component={'img'}
            onClick={() => { props.imageTap(props.index) }}
            src={window.CDNHost+`/img/hero_poster/${props.value}`}
            sx={{
                cursor: 'pointer',
                objectFit: 'cover',
                width: 1,
                height: 1,
                opacity: props.index == props.activeIndex ? 1 : 0.5,
            }} />
    );
}