import { Box, Stack, Typography } from '@mui/material';
import * as React from 'react';

export default function LoadingAnimate(props) {
    let countTmp = 0;
    let isFinish = false;
    const [count, setCount] = React.useState(0);

    React.useEffect(() => {
        startAni();
    }, []);

    function startAni() {
        let timer = setInterval(function () {
            if (countTmp == 15) {
                if(!isFinish){
                    clearInterval(timer);
                    isFinish = true;
                    props.onFinish && props.onFinish(2);
                }
            } else {
                countTmp++;
                setCount(prevCount => prevCount + 1);
            }
        }, 100);
    }

    return (
        <Stack sx={{
            width: 1,
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            bgcolor: 'black',
        }}>

            <Box
                component={'img'}
                sx={{
                    width: 1,
                    height: 1,
                }}
                src={window.CDNHost+`/img/load/${count}.png`} />

        </Stack>
    );

}