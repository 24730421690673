import { Box, Stack, Typography, colors } from '@mui/material';
import * as React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { settingActions, settingObj } from '../store/reducers/SettingSlice';
import $ from 'jquery';

export default function NavView(props) {

    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);

    const [navHeight, setNavHeight] = React.useState(window.innerWidth * 0.043);
    const [moveWidth, setMoveWidth] = React.useState(0);
    const [navKey, setNavKey] = React.useState(Math.random());


    React.useEffect(() => {
        window.addEventListener('resize', sizeChanged);

        setNavKey(Math.random());
        sizeChanged();
        setTimeout(() => {
            itemTaped(settingData.navIndex);
        }, 100);

        return () => {
            window.removeEventListener('resize', sizeChanged);
        }

    }, []);

    function sizeChanged() {
        let x = window.innerWidth * 0.043;
        if (x < 60) {
            x = 60;
        } else if (x > 85) {
            x = 85;
        }
        setNavHeight(x);

        setMoveWidth(getMoveWidth());
        $("#nav_move_view").animate({ left: getMoveLeft(settingObj.navIndex) }, 0);
    }

    function itemTaped(index) {
        dispatch(settingActions.setNavIndex(index));
    }

    function getMoveLeft(index) {
        const left = getMoveWidth() * index;
        return left;
    }

    function getMoveWidth() {
        return $('#nav_item_0').outerWidth();
    }

    return (
        <Stack
            key={navKey}
            sx={{
                display: 'flex',
                flexDirection: 'row',
                width: 1,
                top: 0,
                position: 'absolute',
                zIndex: 29,
                animation: (!settingObj.mainOpened || settingObj.forceAni) ? 'navAni 2s' : '',
            }}>

            <Box
                component={'img'}
                src={window.CDNHost + '/img/logo2.png'}
                sx={{
                    height: navHeight * 1.25,
                }} />

            <Stack
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    bgcolor: '#000000cc',
                    width: 1,
                    height: navHeight,
                    position: 'relative',
                }}>

                <Box id='nav_move_view'
                    component={'img'} src={window.CDNHost + '/img/cur2.png'}
                    sx={{
                        width: moveWidth,
                        objectFit: 'fill',
                        position: 'absolute',
                        height: navHeight,

                    }} />

                <NavItem title={settingData.langDic['lang0']} index={0} selected={props.index} onClick={itemTaped} />
                <NavItem title={settingData.langDic['lang54']} index={1} selected={props.index} onClick={itemTaped} />
                <NavItem title={settingData.langDic['lang1']} index={2} selected={props.index} onClick={itemTaped} />
                <NavItem title={settingData.langDic['lang2']} index={3} selected={props.index} onClick={itemTaped} />
                <NavItem title={settingData.langDic['lang3']} index={4} selected={props.index} onClick={itemTaped} />
                <NavItem title={settingData.langDic['lang4']} index={5} selected={props.index} onClick={itemTaped} />

                <Box sx={{ flex: 1 }} />

                <Box 
                onClick={()=>{
                    window.open('https://eu.onepunchmanworld.com/productStoreEU')
                }}
                component={'img'}
                    src={window.CDNHost + '/img/payment/shop_car.png'}
                    sx={{
                        height: '33px',
                        mr: 5,
                        cursor: 'pointer',
                    }} />
                <LanguageView navHeight={navHeight} />

            </Stack>
        </Stack>
    );
}

function NavItem(props) {
    function selfTap() {
        props.onClick && props.onClick(props.index);
    }
    return (
        <Stack id={`nav_item_${props.index}`}
            component={'span'}
            onClick={selfTap} sx={{
                width: '160px',
                minWidth: '160px',
                height: 1,
                overflow: 'hidden',
            }}>

            <Stack
                sx={{
                    zIndex: 10,
                    height: 1,
                    width: 1,
                    backgroundSize: '100% 100%',
                    justifyContent: 'center',
                    color: 'white',
                    fontSize: { xs: 13, sm: 23, md: 24 },
                    textAlign: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                    transitionDuration: '0.3s',
                    '&:hover': {
                        transform: props.index == props.selected ? 'scale(1)' : 'scale(1.1)',
                        background: `url(${window.CDNHost}/img/cur2.png)`,
                        color: '#4196ff',
                    }
                }}>
                {props.title}
            </Stack>
        </Stack>

    );
}

function LanguageView(props) {
    const [show, setShow] = React.useState(false);
    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);

    function onMouseOver() {
        setShow(true);
    }

    function onMouseLeave() {
        setShow(false);
    }

    function itemTap(k) {
        dispatch(settingActions.setLangKey(k));
        setShow(false);
    }

    return (
        <Stack
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
            sx={{
                position: 'relative',
            }}>

            <Stack
                direction={'row'}
                sx={{
                    alignItems: 'center',
                    pr: { xs: 2, sm: 8 },
                    lineHeight: `${props.navHeight}px`,
                }}>

                <Box
                    component={'div'}
                    sx={{
                        cursor: 'pointer',
                        width: '85px',
                        textAlign: 'left',
                        color: 'white',
                        fontSize: { xs: 12, sm: 18 },
                        transitionDuration: '0.3s',
                        '&:hover': {
                            transform: 'scale(1.15)',
                        }
                    }}>
                    {settingData.langDic['lang43']}
                </Box>

                <Box component={'img'}
                    src={window.CDNHost + '/img/nav_sj.png'}
                    sx={{
                        width: '25px',
                        height: '25px',
                    }} />
            </Stack>

            {/* 下拉菜单 */}
            <Stack sx={{
                position: 'absolute',
                width: { xs: '125px', sm: '140px', md: '140px' },
                bgcolor: '#000000cc',
                top: props.navHeight,
                right: { xs: '0px', sm: '50px' },
                display: show ? 'flex' : 'none',
                zIndex: 100
            }}>

                <LanguageItem LKey="EN" title='ENGLISH' itemTap={itemTap} />
                <LanguageItem LKey="FR" title='FRANÇAIS' itemTap={itemTap} />
                <LanguageItem LKey="PT" title='PORTUGUÊS' itemTap={itemTap} />
                <LanguageItem LKey="DE" title='DEUTSCH' itemTap={itemTap} />
                <LanguageItem LKey="ES" title='ESPAÑOL' itemTap={itemTap} />

            </Stack>
        </Stack>
    );
}


function LanguageItem(props) {

    function onClick() {
        props.itemTap(props.LKey);
    }

    return (
        <Stack onClick={onClick}>
            <Typography sx={{
                color: 'white',
                textAlign: 'left',
                height: '40px',
                lineHeight: '40px',
                pl: 2,
                fontFamily: 'us',
                fontSize: { xs: 12, sm: 15, md: 15 },
                transitionDuration: '0.3s',
                cursor: 'pointer',
                '&:hover': {
                    transform: 'scale(1.15)',
                }
            }}>
                {props.title}
            </Typography>
            <Box component={'div'} sx={{
                height: '1px',
                bgcolor: '#353135',
            }} />

        </Stack>
    );
}
